export default {
  groups: ["all"],
  data: {},
  persist: [],
  routes: {
    // Create chapter : post
    create: (request, data) => request.post("chapters", data),
    // Individual Chapter
    find: (request, query) => request.get("chapters/getByParams" + query),
    // Edit Data
    editData: (request, data) => request.post("chapters/edit", data),
  },
  actions: {
    // Create a chapter
    create: ({ routes }, data) => {
      return routes
        .create(data)
        .then((res) => {
          return res;
        })
        .catch((e) => e);
    },

    // Find a Chapter
    find: ({ routes, base }, data) => {
      let queryParams = base.makeQueryParams({ ...data, includeAll: true });
      return routes
        .find(queryParams)
        .then((res) => {
          return { status: res.status, result: res.chapter };
        })
        .catch((e) => e);
    },

    // edit fields
    edit: ({ routes }, data) => {
      return routes
        .editData(data)
        .then((res) => {
          return res;
        })
        .catch((e) => e);
    },
  },
};
