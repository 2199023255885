import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Spinner from "react-bootstrap/Spinner";
import Toast from "react-bootstrap/Toast";

import Routes from "Routes";
import Core from "Core";
import Pulse from "pulse-framework";
import { Link } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";

const DashboardPage = withRouter((props) => {
  let { user, layoutFor, toasts } = props;

  const [loadingLogout, setLoadingLogout] = useState(false);
  const [loadingUser, setLoadingUser] = useState(true);
  const [subMenu, setSubMenu] = useState(false);

  let subMenus = {
    home: [],
    examBoards: [
      { name: "List", href: Routes.examBoards },
      { name: "Create", href: Routes.examBoardsCreate },
    ],
    qualifications: [
      { name: "List", href: Routes.qualifications },
      { name: "Create", href: Routes.qualificationsCreate },
    ],
    subjects: [
      { name: "List", href: Routes.subjects },
      { name: "Create", href: Routes.subjectsCreate },
    ],
    chapters: [],
    examSeasons: [
      { name: "List", href: Routes.examSeasons },
      { name: "Create", href: Routes.examSeasonsCreate },
    ],
    examSeries: [
      { name: "List", href: Routes.examSeries },
      { name: "Create", href: Routes.examSeriesCreate },
    ],
    documentTypes: [
      { name: "List", href: Routes.documentTypes },
      { name: "Create", href: Routes.documentTypesCreate },
    ],
    difficulties: [
      { name: "List", href: Routes.difficulties },
      { name: "Create", href: Routes.difficultiesCreate },
    ],
    unitIndividual: [
      {
        name: "View",
        href: formatRoute(Routes.unitsIndividual, { unitId: props.match.params.unitId }),
      },
      {
        name: "Create Chapter",
        href: formatRoute(Routes.chapterCreate, { unitId: props.match.params.unitId }),
      },
      {
        name: "Create Exercise",
        href: formatRoute(Routes.exerciseCreate, { unitId: props.match.params.unitId }),
      },
      {
        name: "Upload Unit Document",
        href: formatRoute(Routes.unitDocumentUpload, { unitId: props.match.params.unitId }),
      },
    ],
  };

  useEffect(() => {
    let currentSubMenu = subMenus[layoutFor];

    if (currentSubMenu && currentSubMenu.length > 1) {
      let a = currentSubMenu.map((m, index) => (
        <Link key={index} to={m.href} className="text-secondary nav-link">
          {m.name}
        </Link>
      ));
      setSubMenu(a);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutFor]);

  let token = Core?.auth?.token;

  const logout = () => {
    setLoadingLogout(true);
    Core.auth.logout().then((res) => {
      setLoadingLogout(false);
      props.history.push(Routes.login);
    });
  };

  useEffect(() => {
    if (!Core.auth.hasReceivedUser) {
      Core.auth.getUser().then((res) => {
        if (res?.status?.type === "error") {
          props.history.push(Routes.login);
        } else {
          Core.toasts.create({
            type: "success",
            title: "MyStudyBro Auth",
            body: `Welcome back, ${res.user?.firstName}!`,
          });
          setLoadingUser(false);
        }
      });
    } else {
      setLoadingUser(false);
    }

    return Core.toasts.deleteGroup("allToasts");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, Core.auth.hasReceivedUser]);

  return (
    <div>
      {loadingUser ? (
        <div style={{ width: "100vw", height: "100vh", display: "table" }}>
          <span
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              width: "100%",
              textAlign: "center",
            }}
          >
            <Spinner
              style={{ width: "5rem", height: "5rem" }}
              animation="border"
              role="status"
              variant="primary"
            >
              <span className="sr-only">Loading...</span>
            </Spinner>
            {/* Loading... */}
          </span>
        </div>
      ) : (
        <>
          <Navbar fixed="top" bg="dark" expand="lg" variant="dark">
            <Navbar.Brand href="#home">MyStudyBro (AP)</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <li className="nav-item">
                  <Link to={Routes.index} className="nav-link">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={Routes.examBoards} className="nav-link">
                    Exam Boards
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={Routes.qualifications} className="nav-link">
                    Qualifications
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={Routes.subjects} className="nav-link">
                    Subjects
                  </Link>
                </li>

                <NavDropdown title="Misc" id="basic-nav-dropdown">
                  <Link to={Routes.examSeasons} className="dropdown-item">
                    Exam Seasons
                  </Link>
                  <Link to={Routes.examSeries} className="dropdown-item">
                    Exam Series
                  </Link>
                  <Link to={Routes.documentTypes} className="dropdown-item">
                    Document Types
                  </Link>
                  <Link to={Routes.difficulties} className="dropdown-item">
                    Difficulties
                  </Link>
                  <Link to={Routes.loggerScreen} className="dropdown-item">
                    Activity Logger
                  </Link>
                </NavDropdown>
              </Nav>

              <Nav>
                <Link to={Routes.accountScreen} className="nav-link">
                  {`${user?.firstName} ${user?.lastName}`}
                </Link>
                <Nav.Link disabled={loadingLogout} onClick={logout.bind(this)}>
                  {loadingLogout ? "Loading..." : "Sign Out"}
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>

          {subMenu ? (
            <div
              className="nav-scroller bg-white shadow-sm"
              style={{
                marginTop: "56px",
                overflowY: "hidden",
                position: "relative",
                minHeight: "2.75rem",
              }}
            >
              <Nav color="grey" style={{ display: "flex", flexWrap: "nowrap" }}>
                {/* <Nav.Link className="text-dark">
                <strong>List</strong>
              </Nav.Link>
              <Nav.Link className="text-secondary">Create</Nav.Link> */}
                {subMenu}
              </Nav>
            </div>
          ) : (
            <div style={{ marginTop: "76px" }}></div>
          )}

          <div
            style={{
              position: "fixed",
              bottom: "1rem",
              left: "1rem",
              width: "350px",
              zIndex: 1000,
            }}
          >
            {toasts.map((toast, index) => (
              <Toast
                key={index}
                onClose={(e) => Core.toasts.removeFromGroup("allToasts", toast.id)}
                animation={true}
                delay={5000}
                autohide
              >
                <Toast.Header>
                  <div
                    className={`mr-2 bg-${toast.type} rounded`}
                    style={{ height: "20px", width: "20px" }}
                    alt=""
                  />
                  <strong className="mr-auto">{toast.title}</strong>
                  <small>just now</small>
                </Toast.Header>
                <Toast.Body>{toast.body}</Toast.Body>
              </Toast>
            ))}
          </div>

          <Container className="mt-3" style={{ minHeight: "100vh" }}>
            {props.children}
          </Container>
        </>
      )}
    </div>
  );
});

export default Pulse.React(DashboardPage, (core) => {
  return {
    user: core.auth.authUser[0],
    toasts: core.toasts.allToasts,
  };
});
