export default {
  groups: ["all"],
  data: {},
  persist: [],
  routes: {
    // get logs
    retrieve: (request, limit) => request.get(`logger?limit=${limit}`),
  },
  actions: {
    // get logs
    getLogs: ({ routes, base, logger }) => {
      return routes
        .retrieve(25)
        .then((res) => {
          logger.deleteGroup("all");
          logger.collect(res.logs, "all");
          return res;
        })
        .catch((e) => base.handleError(e));
    },
  },
};
