import React, { useState } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import Logo from "Assets/img/logo2.svg";
import Routes from "Routes";

import Pulse from "pulse-framework";
import Core from "Core";

const LoginScreen = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authLoading, setAuthLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const authenticate = () => {
    setAuthLoading(true);
    setErrorMessage(false);

    Core.auth.authenticate({ email, password }).then((res) => {
      setAuthLoading(false);

      if (res?.error?.message) {
        setErrorMessage(res.error.message);
      } else {
        props.history.push(Routes.index);
      }
    });
  };

  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col md={5} className="ml-2 mr-2 shadow-lg p-5">
          <div style={{ width: "100%" }} className="text-center">
            <img src={Logo} alt="MSB Logo" height={100} width={100} />
          </div>

          <h3 className="text-center mb-3">Please sign in</h3>

          {errorMessage ? <Alert variant="danger">{errorMessage}</Alert> : ""}

          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                disabled={authLoading}
                type="email"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Text className="text-muted">Enter your MSB-ADMIN Panel email address</Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Password</Form.Label>
              <Form.Control
                disabled={authLoading}
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <Form.Text className="text-muted">Enter your MSB-ADMIN Panel password</Form.Text>
            </Form.Group>
          </Form>

          <Button
            onClick={authenticate.bind(this)}
            variant="primary"
            size="lg"
            block
            className="mt-4"
            disabled={authLoading}
          >
            {authLoading ? "Loading..." : "Sign In"}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Pulse.React(LoginScreen, (core) => {
  return {
    auth: core.auth.isAuthenticated,
  };
});
