import React from "react";
import DashboardPage from "Components/Templates/DashboardPage";
import AdvancedForm from "Components/Form/AdvancedForm";
import Core from "Core";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ExamSeasonsCreate = (props) => {
  return (
    <DashboardPage layoutFor="examSeasons">
      <h1>Exam Season Create</h1>
      <p className="text-secondary">
        Create an <strong className="bg-info text-light">Exam Season</strong>
      </p>

      <hr />

      <Row>
        <Col md={7}>
          <AdvancedForm
            formName="Exam Season"
            fields={{
              examSeasonName: {
                placeholder: "Enter Name",
                underText: "Enter an Exam Season name that doesn't exist",
                label: "Exam Season Name",
                icon: <i className="fas fa-cloud"></i>,
                colBp: "md",
                colSize: "12",
              },
              examSeasonDetails: {
                placeholder: "Exam Season Details",
                label: ["Details ", <small>(Optional)</small>],
                icon: <i className="fas fa-info-circle"></i>,
                colBp: "md",
                colSize: "12",
                as: "textarea",
              },
            }}
            button={{
              action: Core.examSeasons.create,
            }}
          />
        </Col>
      </Row>
    </DashboardPage>
  );
};

export default ExamSeasonsCreate;
