import React from "react";
import Table from "react-bootstrap/Table";

const ModelTable = (props) => {
  let { model } = props;

  if (typeof model !== "object") return <div></div>;

  // return (
  //   <>
  //     {Object.keys(model).map((dType, index) => {
  //       if (typeof model[dType] === "object" || typeof model[dType].length > 0) {
  //       } else {
  //         return (
  //           <Form.Group>
  //             <Form.Label>{dType}</Form.Label>
  //             <InputGroup>
  //               <InputGroup.Prepend>
  //                 <InputGroup.Text>
  //                   {dType === "id" || dType === "createdAt" || dType === "updatedAt" ? (
  //                     <i class="fas fa-robot"></i>
  //                   ) : (
  //                     <i class="fas fa-keyboard"></i>
  //                   )}
  //                 </InputGroup.Text>
  //               </InputGroup.Prepend>

  //               <Form.Control
  //                 disabled={
  //                   dType === "id" || dType === "createdAt" || dType === "updatedAt" || true
  //                 }
  //                 as={dType === "details" ? "textarea" : "input"}
  //                 value={
  //                   dType === "createdAt" || dType === "updatedAt"
  //                     ? [
  //                         new Date(model[dType]).toDateString() +
  //                           " - " +
  //                           new Date(model[dType]).toLocaleTimeString(),
  //                       ]
  //                     : model[dType].toString()
  //                 }
  //               />

  //               {dType === "id" || dType === "createdAt" || dType === "updatedAt" ? (
  //                 ""
  //               ) : (
  //                 <InputGroup.Append>
  //                   {/* <Button variant="outline-secondary">Edit</Button> */}
  //                   {/* <Button variant="success">
  //                     <i class="fas fa-save"></i>
  //                   </Button> */}
  //                   <Button variant="secondary">
  //                     <i class="fas fa-edit"></i>
  //                   </Button>
  //                 </InputGroup.Append>
  //               )}
  //             </InputGroup>

  //             {dType === "id" || dType === "createdAt" || dType === "updatedAt" ? (
  //               <Form.Text className="text-muted">This field is automatically generated</Form.Text>
  //             ) : (
  //               ""
  //             )}
  //           </Form.Group>
  //         );
  //       }
  //     })}
  //   </>
  // );

  return (
    <Table className={props.className} striped borderless>
      <thead>
        <tr>
          <th>Key</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(model).map((dType, index) => {
          if (typeof model[dType] === "object" || typeof model[dType].length > 0) {
            return "";
          } else {
            return (
              <tr key={index}>
                <td>{dType}</td>
                <td>
                  {dType === "createdAt" || dType === "updatedAt"
                    ? [
                        new Date(model[dType]).toDateString(),
                        " - ",
                        new Date(model[dType]).toLocaleTimeString(),
                      ]
                    : model[dType].toString()}
                </td>
              </tr>
            );
          }
        })}
      </tbody>
    </Table>
  );
};

export default ModelTable;
