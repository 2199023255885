import React from "react";
import DashboardPage from "Components/Templates/DashboardPage";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Core from "Core";

import AdvancedForm from "Components/Form/AdvancedForm";

const QualificationsCreate = (props) => {
  return (
    <DashboardPage layoutFor="qualifications">
      <Row>
        <Col md={7}>
          <h1>Create Qualification</h1>
          <p className="text-secondary">
            Use this page to create a <strong className="bg-info text-light">Qualification</strong>
          </p>

          <hr />

          <AdvancedForm
            formName="Qualification"
            fields={{
              qualificationName: {
                placeholder: "Enter Name",
                underText: "Enter an Qualification name that doesn't exist",
                label: "Qualification Name",
                icon: <i className="fas fa-signature"></i>,
                colBp: "md",
                colSize: "12",
              },
              qualificationPrefix: {
                placeholder: "Enter prefix",
                label: "Qualification Prefix",
                icon: <i className="fab fa-autoprefixer"></i>,
                colBp: "md",
                colSize: "12",
              },
              qualificationDetails: {
                placeholder: "Qualification Details",
                label: ["Details ", <small>(Optional)</small>],
                icon: <i className="fas fa-info-circle"></i>,
                colBp: "md",
                colSize: "12",
                as: "textarea",
              },
            }}
            button={{
              action: Core.qualifications.create,
            }}
          />
        </Col>
      </Row>
    </DashboardPage>
  );
};

export default QualificationsCreate;
