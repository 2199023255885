export default {
  groups: ["all", "selectedExamBoard"],
  data: {},
  persist: [],
  routes: {
    // Receive all qualifications
    qualifications: (request) => request.get("qualifications"),
    // Create a qualification
    create: (request, body) => request.post("qualifications", body),
    // Get individual Qualification
    find: (request, query) => request.get("qualifications/get" + query),
    // Edit Data
    editData: (request, data) => request.post("qualifications/edit", data),
  },
  actions: {
    // Receive all qualifications
    getQualifications: ({ routes, qualifications, base }) => {
      return routes
        .qualifications()
        .then((res) => {
          qualifications.collect(res.qualifications, "all");
          return res;
        })
        .catch((e) => base.handleError(e));
    },

    // Create a Qualification
    create: ({ routes }, data) => {
      return routes
        .create(data)
        .then((res) => res)
        .catch((e) => e);
    },

    // Find an Qualification
    find: ({ routes, base }, data) => {
      let queryParams = base.makeQueryParams(data);
      return routes
        .find(queryParams)
        .then((res) => {
          return { status: res.status, result: res.qualification };
        })
        .catch((e) => e);
    },

    // edit fields
    edit: ({ routes }, data) => {
      return routes
        .editData(data)
        .then((res) => {
          return res;
        })
        .catch((e) => e);
    },
  },
};
