export default {
  groups: ["all"],
  data: {},
  persist: [],
  routes: {
    // Receive all examSeries
    examSeries: (request) => request.get("examseries"),
    // Create ExamSeries
    create: (request, data) => request.post("examseries", data),
    // Get individual Exam Series
    find: (request, query) => request.get("examseries/get" + query),
    // Edit Data
    editData: (request, data) => request.post("examseries/edit", data),
  },
  actions: {
    // Receive all examSeries
    getExamSeries: ({ routes, examSeries, base }) => {
      return routes
        .examSeries()
        .then((res) => {
          examSeries.deleteGroup("all");
          examSeries.collect(res.examSeries, "all");
          return res;
        })
        .catch((e) => base.handleError(e));
    },

    // Create an examseries
    create: ({ routes }, data) => {
      return routes
        .create(data)
        .then((res) => res)
        .catch((e) => e);
    },

    // Find an Exam Series
    find: ({ routes, base }, data) => {
      let queryParams = base.makeQueryParams(data);
      return routes
        .find(queryParams)
        .then((res) => {
          return { status: res.status, result: res.examSeries };
        })
        .catch((e) => e);
    },

    // edit fields
    edit: ({ routes }, data) => {
      return routes
        .editData(data)
        .then((res) => {
          return res;
        })
        .catch((e) => e);
    },
  },
};
