export default {
  groups: ["authUser", "authUserDevices"],
  data: {
    isAuthenticated: false,
    token: "",
    hasReceivedUser: false,
    currentDeviceId: 0,
  },
  persist: ["token", "isAuthenticated"],
  watch: {
    token({ request, auth }) {
      if (auth.token) {
        request.headers["Authorization"] = `Bearer ${auth.token}`;
      } else {
        request.headers["Authorization"] = "";
      }
    },
  },
  routes: {
    // Login the user
    auth: (request, data) => request.post("users/login", { ...data }),
    // Logout the user
    logout: (request) => request.post("users/logout"),
    // Get user
    getUser: (request) => request.get("users/user"),
    // Revoke Access
    revokeAccess: (request, data) => request.post("users/revoke", data),
    // Edit Data
    editData: (request, data) => request.post("users/edit", data),
  },
  actions: {
    // Authenticate
    authenticate: ({ routes, auth }, credentials) => {
      auth.isAuthenticated = false;
      return routes
        .auth(credentials)
        .then((res) => {
          if (res?.token) {
            auth.token = res.token;
            auth.isAuthenticated = true;
          }
        })
        .catch((e) => e);
    },

    // Logout
    logout: ({ routes, auth, toasts }) => {
      toasts.create({ type: "warning", title: "MyStudyBro Auth", body: "Attempting to logout..." });
      return routes
        .logout()
        .then((res) => {
          auth.token = "";
          auth.isAuthenticated = false;
          return res;
        })
        .catch((e) => e);
    },

    // Get user
    getUser: ({ routes, auth, request }) => {
      request.headers["Authorization"] = `Bearer ${auth.token}`;
      if (!auth.hasReceivedUser) {
        return routes
          .getUser()
          .then((res) => {
            auth.deleteGroup("authUser");
            auth.deleteGroup("authUserDevices");
            auth.collect(res.user, "authUser");
            auth.collect(res.devices, "authUserDevices");
            auth.hasReceivedUser = true;
            auth.currentDeviceId = res.currentDevice;
            return res;
          })
          .catch((e) => e);
      }
    },

    // revoke access
    revoke: ({ routes, auth }, data) => {
      return routes
        .revokeAccess(data)
        .then((res) => {
          auth.remove(data.deviceId, "authUserDevices");
          return res;
        })
        .catch((e) => e);
    },

    // edit fields
    edit: ({ routes, auth }, data) => {
      return routes
        .editData(data)
        .then((res) => {
          auth.deleteGroup("authUser");
          auth.collect(res.newModel, "authUser");
          return res;
        })
        .catch((e) => e);
    },

    // ClearData
    clearData: ({ auth }) => {
      auth.purge();
    },
  },
};
