import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

import "bootstrap/dist/css/bootstrap.min.css";

import { Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import routes from "Routes";

import { GuestRoute, AuthRoute } from "Components/RouteTypes";

import DashboardHome from "Screens/DashboardHome";
import LoginScreen from "Screens/Authentication/LoginScreen";

import ExamBoardsScreen from "Screens/ExamBoards";
import ExamBoardsCreateScreen from "Screens/ExamBoards/create";
import ExamBoardsIndividual from "Screens/ExamBoards/individual";

import QualificationsScreen from "Screens/Qualifications";
import QualificationsCreateScreen from "Screens/Qualifications/create";
import QualificationsIndividualScreen from "Screens/Qualifications/individual";

import SubjectsScreen from "Screens/Subjects";
import SubjectsCreateScreen from "Screens/Subjects/create";
import SubjectsIndividualScreen from "Screens/Subjects/individual";

import ExamSeasons from "Screens/ExamSeasons";
import ExamSeasonsCreate from "Screens/ExamSeasons/create";
import ExamSeasonsIndividual from "Screens/ExamSeasons/individual";

import ExamSeries from "Screens/ExamSeries";
import ExamSeriesCreate from "Screens/ExamSeries/create";
import ExamSeriesIndividual from "Screens/ExamSeries/individual";

import DocumentTypes from "Screens/DocumentTypes";
import DocumentTypesCreate from "Screens/DocumentTypes/create";
import DocumentTypeIndividual from "Screens/DocumentTypes/individual";

import Difficulties from "Screens/Difficulties";
import DifficultiesCreate from "Screens/Difficulties/create";
import DifficultiesIndividual from "Screens/Difficulties/individual";

import UnitsIndividual from "Screens/Units/individual";
import CreateChapter from "Screens/Units/CreateChapter";
import CreateExercise from "Screens/Units/CreateExercise";
import UploadUnitDocument from "Screens/Units/UploadUnitDocument";

import IndividualChapterScreen from "Screens/Chapters/individual";

import DocumentIndividual from "Screens/Documents/individual";

import ExerciseIndividual from "Screens/Exercises/individual";

import AccountScreen from "Screens/Account/AccountScreen";

import LoggerScreen from "Screens/Logger/index";

const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <Switch>
        <AuthRoute path={routes.index} component={DashboardHome} exact />

        <AuthRoute path={routes.examBoards} component={ExamBoardsScreen} exact />
        <AuthRoute path={routes.examBoardsCreate} component={ExamBoardsCreateScreen} exact />
        <AuthRoute path={routes.examBoardsIndividual} component={ExamBoardsIndividual} exact />

        <AuthRoute path={routes.qualifications} component={QualificationsScreen} exact />
        <AuthRoute
          path={routes.qualificationsCreate}
          component={QualificationsCreateScreen}
          exact
        />
        <AuthRoute
          path={routes.qualificationsIndividual}
          component={QualificationsIndividualScreen}
          exact
        />

        <AuthRoute path={routes.subjects} component={SubjectsScreen} exact />
        <AuthRoute path={routes.subjectsCreate} component={SubjectsCreateScreen} exact />
        <AuthRoute path={routes.subjectsIndividual} component={SubjectsIndividualScreen} exact />

        <AuthRoute path={routes.examSeasons} component={ExamSeasons} exact />
        <AuthRoute path={routes.examSeasonsCreate} component={ExamSeasonsCreate} exact />
        <AuthRoute path={routes.examSeasonsIndividual} component={ExamSeasonsIndividual} exact />

        <AuthRoute path={routes.examSeries} component={ExamSeries} exact />
        <AuthRoute path={routes.examSeriesCreate} component={ExamSeriesCreate} exact />
        <AuthRoute path={routes.examSeriesIndividual} component={ExamSeriesIndividual} exact />

        <AuthRoute path={routes.documentTypes} component={DocumentTypes} exact />
        <AuthRoute path={routes.documentTypesCreate} component={DocumentTypesCreate} exact />
        <AuthRoute path={routes.documentTypesIndividual} component={DocumentTypeIndividual} exact />

        <AuthRoute path={routes.difficulties} component={Difficulties} exact />
        <AuthRoute path={routes.difficultiesCreate} component={DifficultiesCreate} exact />
        <AuthRoute path={routes.difficultiesIndividual} component={DifficultiesIndividual} exact />

        <AuthRoute path={routes.unitsIndividual} component={UnitsIndividual} exact />
        <AuthRoute path={routes.chapterCreate} component={CreateChapter} exact />
        <AuthRoute path={routes.exerciseCreate} component={CreateExercise} exact />
        <AuthRoute path={routes.unitDocumentUpload} component={UploadUnitDocument} exact />

        <AuthRoute path={routes.chaptersIndividual} component={IndividualChapterScreen} exact />

        <AuthRoute path={routes.documentIndividual} component={DocumentIndividual} exact />

        <AuthRoute path={routes.exerciseIndividual} component={ExerciseIndividual} exact />

        <AuthRoute path={routes.accountScreen} component={AccountScreen} exact />

        <AuthRoute path={routes.loggerScreen} component={LoggerScreen} exact />

        <GuestRoute path={routes.login} component={LoginScreen} exact />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
