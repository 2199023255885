export default {
  groups: ["all"],
  data: {},
  persist: [],
  routes: {
    // Receive all documentTypes
    documentTypes: (request) => request.get("documentTypes"),
    // Create a Document Type
    create: (request, data) => request.post("documentTypes", data),
    // Get individual Document Type
    find: (request, query) => request.get("documentTypes/get" + query),
    // Edit Data
    editData: (request, data) => request.post("documenttypes/edit", data),
  },
  actions: {
    // Receive all documentTypes
    getDocumentTypes: ({ routes, documentTypes, base }) => {
      return routes
        .documentTypes()
        .then((res) => {
          documentTypes.deleteGroup("all");
          documentTypes.collect(res.documentTypes, "all");
          return res;
        })
        .catch((e) => base.handleError(e));
    },

    // Create a Document Type
    create: ({ routes }, data) => {
      return routes
        .create(data)
        .then((res) => res)
        .catch((e) => e);
    },

    // Find a Document Type
    find: ({ routes, base }, data) => {
      let queryParams = base.makeQueryParams(data);
      return routes
        .find(queryParams)
        .then((res) => {
          return { status: res.status, result: res.documentType };
        })
        .catch((e) => e);
    },

    // edit fields
    edit: ({ routes }, data) => {
      return routes
        .editData(data)
        .then((res) => {
          return res;
        })
        .catch((e) => e);
    },
  },
};
