import React, { useState } from "react";
import DashboardPage from "Components/Templates/DashboardPage";
import Core from "Core";
import { useIsAvailable } from "Components/Hooks/ApiHooks";
import FindLoadingComponent from "Components/FindLoadingComponent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ModelTable from "Components/ModelTable";
import Button from "react-bootstrap/Button";
import SquarePanel from "Components/Panels/SquarePanel";
import EditFieldModal from "Components/Modals/EditFieldModal";

const ExamSeriesIndividual = (props) => {
  let { examSeriesId } = props.match.params;

  const [examSeriesReload, setExamSeriesReload] = useState(0);
  const fetchData = (_) => setExamSeriesReload(examSeriesReload + 1);
  const examSeries = useIsAvailable({
    find: Core.examSeries.find,
    findQueryKey: "id",
    findQueryValue: examSeriesId,
    refreshCounter: examSeriesReload,
  });

  const edit = (data) => {
    return new Promise((resolve, reject) => {
      Core.examSeries.edit(data).then((res) => {
        if (res?.status.type === "success") fetchData();
        return resolve(res);
      });
    });
  };

  return (
    <DashboardPage layoutFor="examSeries">
      <FindLoadingComponent modelName="Exam Series" model={examSeries}>
        {typeof examSeries === "object" ? (
          <Row>
            <Col md={7}>
              <h1>{examSeries.year}</h1>
              <hr />
              <Button className="mb-3" size="sm" variant="primary" onClick={fetchData.bind(this)}>
                <>
                  <i className="fas fa-sync pr-1"></i> Reload
                </>
              </Button>
            </Col>

            <Col md={5}></Col>

            <Col md={7}>
              <SquarePanel
                header={
                  <strong>
                    <i className="fas fa-info-circle mr-2"></i> General Information
                  </strong>
                }
              >
                <p>Basic Information regaring the registration of {examSeries.year}.</p>
                <hr />
                <p>
                  <strong>ExamSeries ID: </strong>
                  {examSeries.id}
                </p>
                <p>
                  <strong>ExamSeries Year: </strong>
                  <EditFieldModal
                    fieldKey="year"
                    fieldDisplayName="ExamSeries Year"
                    currentValue={examSeries.year}
                    modelId={examSeries.id}
                    action={edit}
                  />
                  <br />
                  {examSeries.year}
                </p>
                <p>
                  <strong>ExamSeries Details: </strong>
                  <EditFieldModal
                    fieldKey="details"
                    fieldDisplayName="ExamSeries Details"
                    currentValue={examSeries.details}
                    modelId={examSeries.id}
                    action={edit}
                    as="textarea"
                  />
                  <br />
                  {examSeries.details}
                </p>
                <p>
                  <strong>Created At: </strong>
                  {[
                    new Date(examSeries["createdAt"]).toDateString(),
                    " - ",
                    new Date(examSeries["createdAt"]).toLocaleTimeString(),
                  ]}
                </p>
                <p>
                  <strong>Updated At: </strong>
                  {[
                    new Date(examSeries["updatedAt"]).toDateString(),
                    " - ",
                    new Date(examSeries["updatedAt"]).toLocaleTimeString(),
                  ]}
                </p>
              </SquarePanel>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </FindLoadingComponent>
    </DashboardPage>
  );
};

export default ExamSeriesIndividual;
