import React, { useEffect, useState } from "react";
import DashboardPage from "Components/Templates/DashboardPage";
import { useIsAvailable } from "Components/Hooks/ApiHooks";
import Core from "Core";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import Routes from "Routes";
import FindLoadingComponent from "Components/FindLoadingComponent";
import AdvancedForm from "Components/Form/AdvancedForm";

const UploadUnitDocument = (props) => {
  let { unitId } = props.match.params;

  const [indexedExamSeries, setIndexedExamSeries] = useState({});
  const [indexedExamSeasons, setIndexedExamSeasons] = useState({});
  const [indexedDocumentTypes, setIndexedDocumentTypes] = useState({});

  const [inputs, setInputs] = useState({});

  const unit = useIsAvailable({
    find: Core.units.find,
    findQueryKey: "id",
    findQueryValue: unitId,
    secondaryFindQueryKey: "for",
    secondaryFindQueryValue: "uploadDocument",
  });

  useEffect(() => {
    if (
      unit.otherData?.documentTypes?.length > 0 &&
      unit.otherData?.examSeasons?.length > 0 &&
      unit.otherData?.examSeries?.length > 0
    ) {
      let newIndexedDocumentTypes = {};
      let newIndexedExamSeasons = {};
      let newIndexedExamSeries = {};

      unit.otherData.documentTypes.forEach((dt) => (newIndexedDocumentTypes[dt.name] = dt.id));
      unit.otherData.examSeasons.forEach((esn) => (newIndexedExamSeasons[esn.name] = esn.id));
      unit.otherData.examSeries.forEach((es) => (newIndexedExamSeries[es.year] = es.id));

      setIndexedDocumentTypes(newIndexedDocumentTypes);
      setIndexedExamSeasons(newIndexedExamSeasons);
      setIndexedExamSeries(newIndexedExamSeries);
    }
  }, [unit]);

  const onChooseFile = (e) => {
    let fileName = e.target.files[0].name;
    let splittedName = fileName.split("_");
    if (!splittedName[1]) return false;

    let fileSeason = splittedName[1];
    let fileSeries = splittedName[2];
    let fileType = splittedName[3].split(".")[0];

    let inputStateChange = {};

    if (fileType === "QP") {
      inputStateChange["DocumentTypeId"] = indexedDocumentTypes["Past Paper"];
    } else if (fileType === "MS") {
      inputStateChange["DocumentTypeId"] = indexedDocumentTypes["Past Paper (Mark Scheme)"];
    }
    if (fileSeason && indexedExamSeries[fileSeries]) {
      switch (fileSeason) {
        case "June":
          inputStateChange["ExamSeasonId"] = indexedExamSeasons["Summer"];
          break;

        case "Jan":
          inputStateChange["ExamSeasonId"] = indexedExamSeasons["Winter"];
          break;

        case "Oct":
          inputStateChange["ExamSeasonId"] = indexedExamSeasons["Autumn"];
          break;

        default:
          inputStateChange["ExamSeasonId"] = indexedExamSeasons[fileSeason];
          break;
      }
    }
    if (fileSeries && indexedExamSeries[fileSeries]) {
      inputStateChange["ExamSeriesId"] = indexedExamSeries[fileSeries];
    }

    setInputs({ ...inputs, file: e.target.files[0], ...inputStateChange });
  };

  return (
    <DashboardPage layoutFor="unitIndividual">
      <FindLoadingComponent modelName="Unit" model={unit}>
        {typeof unit === "object" ? (
          <Row>
            <Col md={7}>
              <h1>
                <strong>{unit.prefix}</strong>
              </h1>
              <h3>{unit.name}</h3>

              <Breadcrumb className="mt-4 mb-4">
                <Breadcrumb.Item>
                  <Link
                    to={formatRoute(Routes.examBoardsIndividual, {
                      examBoardId: unit.ExamBoard.id,
                    })}
                  >
                    {unit.ExamBoard.name}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    to={formatRoute(Routes.qualificationsIndividual, {
                      qualificationId: unit.Subject.Qualification.id,
                    })}
                  >
                    {unit.Subject.Qualification.prefix}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    to={
                      formatRoute(Routes.subjectsIndividual, {
                        subjectId: unit.Subject.id,
                      }) + `?ebId=${unit.ExamBoardId}`
                    }
                  >
                    {unit.Subject.name}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    to={formatRoute(Routes.unitsIndividual, {
                      unitId: unit.id,
                    })}
                  >
                    {unit.prefix}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Upload Document</Breadcrumb.Item>
              </Breadcrumb>

              <hr />
            </Col>
            <Col md={5}></Col>

            <Col md={7}>
              <AdvancedForm
                formName="Upload Document"
                values={{
                  UnitId: unit.id,
                  ExamSeriesId: inputs.ExamSeriesId,
                  ExamSeasonId: inputs.ExamSeasonId,
                  DocumentTypeId: inputs.DocumentTypeId,
                  relatedTo: "unit",
                }}
                fields={{
                  title: {
                    content: <strong>Upload a Document</strong>,
                    header: "2",
                  },
                  p: {
                    content: (
                      <span>
                        <strong className="text-primary">Exam Board Name:</strong>{" "}
                        {unit.ExamBoard.name} |{" "}
                        <strong className="text-success">Subject Name:</strong> {unit.Subject.name}{" "}
                        | <strong className="text-info">Qualification:</strong>{" "}
                        {unit.Subject.Qualification.name} ({unit.Subject.Qualification.prefix})
                      </span>
                    ),
                    header: "p",
                    colBp: "md",
                    className: "mt-0",
                    colSize: "12",
                  },
                  file: {
                    placeholder: "Upload PDF File",
                    underText: (
                      <span>
                        <strong>Format: </strong> unitPrefix_(Jan/June/Oct)_year_(QP/MS).pdf
                      </span>
                    ),
                    label: "Document Upload",
                    colBp: "md",
                    colSize: "12",
                    as: "fileUpload",
                    onChange: (e) => onChooseFile(e),
                  },
                  ExamSeriesId: {
                    placeholder: "Select an Exam Series",
                    underText: "Select the Exam Series of the Document",
                    label: "Exam Series",
                    icon: <i className="fas fa-layer-group"></i>,
                    colBp: "md",
                    colSize: "4",
                    as: "select",
                    options: unit.otherData.examSeries.map((examSeries, index) => {
                      return { key: index, value: examSeries.id, text: examSeries.year };
                    }),
                  },
                  ExamSeasonId: {
                    placeholder: "Select an Exam Season",
                    underText: "Select the Exam Season of the Document",
                    label: "Exam Season",
                    icon: <i className="fas fa-layer-group"></i>,
                    colBp: "md",
                    colSize: "4",
                    as: "select",
                    options: unit.otherData.examSeasons.map((es, index) => {
                      return { key: index, value: es.id, text: es.name };
                    }),
                  },
                  DocumentTypeId: {
                    placeholder: "Select an Document Type",
                    underText: "Select the Document Type of the Document",
                    label: "Document Type",
                    icon: <i className="fas fa-layer-group"></i>,
                    colBp: "md",
                    colSize: "4",
                    as: "select",
                    options: unit.otherData.documentTypes.map((dt, index) => {
                      return { key: index, value: dt.id, text: dt.name };
                    }),
                  },
                  details: {
                    placeholder: "Document Details",
                    label: ["Details ", <small>(Optional)</small>],
                    icon: <i className="fas fa-info-circle"></i>,
                    colBp: "md",
                    colSize: "12",
                    as: "textarea",
                  },
                }}
                button={{
                  action: Core.documents.upload,
                  text: "Upload Document",
                }}
              />
            </Col>
          </Row>
        ) : (
          ""
        )}
      </FindLoadingComponent>
    </DashboardPage>
  );
};

export default UploadUnitDocument;
