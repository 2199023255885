import React, { useEffect, useState } from "react";
import DashboardPage from "Components/Templates/DashboardPage";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import LoadingComponent from "Components/LoadingComponent";

import Core from "Core";
import Pulse from "pulse-framework";
import Spinner from "react-bootstrap/Spinner";

import { Link } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import Routes from "Routes";
import PaginationTable from "Components/PaginationTable";

const Qualifications = (props) => {
  let { qualifications } = props;

  const [errorQualifications, setErrorQualifications] = useState(false);
  const [loadingQualifications, setLoadingQualifications] = useState(false);

  const getQualifications = () => {
    setLoadingQualifications(true);
    setErrorQualifications(false);
    Core.qualifications.getQualifications().then((res) => {
      if (res === "error") setErrorQualifications(true);
      setLoadingQualifications(false);
    });
  };

  useEffect(() => {
    getQualifications();
  }, []);

  return (
    <DashboardPage layoutFor="qualifications">
      <Row>
        <Col md={7}>
          <h1>Qualifications</h1>
          <p className="text-secondary">
            List of the <strong>Qualifications</strong> registered.
          </p>
          <hr />

          <Button
            onClick={getQualifications.bind(this)}
            variant="outline-primary"
            size="sm"
            disabled={loadingQualifications}
          >
            {loadingQualifications ? (
              <Spinner className="mx-3" animation="border" size="sm" />
            ) : (
              <>
                <i className="fas fa-sync pr-1"></i> Reload
              </>
            )}
          </Button>

          {errorQualifications ? (
            <Alert variant="danger" className="my-3">
              <Alert.Heading>Couldn't receive Qualifications</Alert.Heading>
              <hr />
              There was an error receiving the Qualifications.
            </Alert>
          ) : (
            ""
          )}
          {loadingQualifications ? (
            <LoadingComponent />
          ) : !errorQualifications ? (
            <PaginationTable
              prefix="qualifications"
              className="mt-3"
              itemsPerPage={5}
              borderless
              striped
              thead={
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Qualification</th>
                    <th>Actions</th>
                  </tr>
                </thead>
              }
              tbody={qualifications.map((qualification, index) => (
                <tr key={index}>
                  <td className="align-middle">{qualification.id}</td>
                  <td className="align-middle">{qualification.name}</td>
                  <td className="align-middle">
                    <Link
                      to={formatRoute(Routes.qualificationsIndividual, {
                        qualificationId: qualification.id,
                      })}
                    >
                      <Button size="sm" variant="primary" className="rounded-0">
                        <i className="fas fa-eye"></i> View
                      </Button>
                    </Link>
                  </td>
                </tr>
              ))}
            />
          ) : (
            ""
          )}
        </Col>
      </Row>
    </DashboardPage>
  );
};

export default Pulse.React(Qualifications, (core) => {
  return {
    qualifications: core.qualifications.all,
  };
});
