import React, { useState, useEffect } from "react";
import DashboardPage from "Components/Templates/DashboardPage";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Pulse from "pulse-framework";
import Core from "Core";
import LoadingComponent from "Components/LoadingComponent";
import AdvancedForm from "Components/Form/AdvancedForm";

const DifficultiesCreate = (props) => {
  const [errorDifficulties, setErrorDifficulties] = useState(false);
  const [loadingDifficulties, setLoadingDifficulties] = useState(false);

  let { difficulties } = props;

  const getDifficulties = () => {
    setLoadingDifficulties(true);
    setErrorDifficulties(false);
    Core.difficulties.getDifficulties().then((res) => {
      if (res === "error") setErrorDifficulties(true);
      setLoadingDifficulties(false);
    });
  };

  const compareAlphabetically = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const bandA = a.difficultyLevel;
    const bandB = b.difficultyLevel;

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  };

  useEffect(() => {
    getDifficulties();
  }, []);

  return (
    <DashboardPage layoutFor="difficulties">
      <Row>
        <Col md={7}>
          <h1>Create a Difficulty</h1>
          <p className="text-secondary">
            Use this page to create a <strong className="bg-info text-light">Difficulty</strong>
          </p>
          <hr />

          {loadingDifficulties ? (
            <LoadingComponent variant="secondary" />
          ) : !errorDifficulties ? (
            <>
              <AdvancedForm
                formName="Exam Season"
                fields={{
                  generalInformationTitle: {
                    content: "General Information",
                    header: "2",
                  },
                  p1: {
                    content: "Enter general information regarding the difficulty you are creating.",
                    header: "p",
                    colBp: "md",
                    className: "mt-0",
                    colSize: "12",
                  },
                  difficultyName: {
                    placeholder: "Enter Name",
                    underText: "Enter an Difficulty name that doesn't exist",
                    label: "Difficulty Name",
                    icon: <i className="fas fa-hand-paper"></i>,
                    colBp: "md",
                    colSize: "12",
                  },
                  difficultyDetails: {
                    placeholder: "Difficulty Details",
                    label: ["Details ", <small>(Optional)</small>],
                    icon: <i className="fas fa-info-circle"></i>,
                    colBp: "md",
                    colSize: "12",
                    as: "textarea",
                  },
                  difficultyDetailsTitle: {
                    content: "Difficulty Details",
                    header: "2",
                    className: "mt-2",
                    colBp: "md",
                    colSize: "12",
                  },
                  p2: {
                    content:
                      "Enter details below in order to properly allocate the difficulty level of the new difficulty.",
                    header: "p",
                    colBp: "md",
                    className: "mt-0",
                    colSize: "12",
                  },
                  difficultyPosition: {
                    label: "Difficulty Position",
                    icon: <i className="fas fa-layer-group"></i>,
                    as: "select",
                    placeholder: "Select a Position",
                    options: [
                      { value: 1, text: "Easier Than" },
                      { value: 2, text: "Harder than" },
                    ],
                    colBp: "md",
                    colSize: "12",
                  },
                  difficultyDepend: {
                    label: "Dependant Difficulty",
                    icon: <i className="fas fa-layer-group"></i>,
                    as: "select",
                    placeholder: "Select a Dependant Difficulty",
                    options: difficulties.sort(compareAlphabetically).map((difficulty, index) => {
                      return { value: difficulty.id, text: difficulty.name, key: index };
                    }),
                    colBp: "md",
                    colSize: "12",
                    className: "mb-3",
                  },
                }}
                button={{
                  action: Core.difficulties.create,
                }}
              />
            </>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </DashboardPage>
  );
};

export default Pulse.React(DifficultiesCreate, (core) => {
  return {
    difficulties: core.difficulties.all,
  };
});
