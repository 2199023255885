import React, { useState, useEffect } from "react";
import DashboardPage from "Components/Templates/DashboardPage";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import LoadingComponent from "Components/LoadingComponent";
import Core from "Core";
import Pulse from "pulse-framework";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import Routes from "Routes";
import PaginationTable from "Components/PaginationTable";

const ExamSeries = (props) => {
  let { examSeries } = props;

  const [errorExamSeries, setErrorExamSeries] = useState(false);
  const [loadingExamSeries, setLoadingExamSeries] = useState(false);

  const getExamSeries = () => {
    setLoadingExamSeries(true);
    setErrorExamSeries(false);
    Core.examSeries.getExamSeries().then((res) => {
      if (res === "error") setErrorExamSeries(true);
      setLoadingExamSeries(false);
    });
  };

  const compareAlphabetically = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const bandA = a.year.toUpperCase();
    const bandB = b.year.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA > bandB) {
      comparison = -1;
    }
    return comparison;
  };

  useEffect(() => {
    getExamSeries();
  }, []);

  return (
    <DashboardPage layoutFor="examSeries">
      <Row>
        <Col md={7}>
          <h1>Exam Series</h1>
          <p className="text-secondary">
            List of the <strong>Exam Series</strong> registered.
          </p>
          <hr />

          <Button
            onClick={getExamSeries.bind(this)}
            variant="outline-primary"
            size="sm"
            disabled={loadingExamSeries}
          >
            {loadingExamSeries ? (
              <Spinner className="mx-3" animation="border" size="sm" />
            ) : (
              <>
                <i className="fas fa-sync pr-1"></i> Reload
              </>
            )}
          </Button>

          {errorExamSeries ? (
            <Alert variant="danger" className="my-3">
              <Alert.Heading>Couldn't receive Exam Series</Alert.Heading>
              <hr />
              There was an error receiving the Exam Series.
            </Alert>
          ) : (
            ""
          )}
          {loadingExamSeries ? (
            <LoadingComponent />
          ) : !errorExamSeries ? (
            <PaginationTable
              prefix="examSeries"
              itemsPerPage={5}
              className="mt-3"
              borderless
              hover
              striped
              thead={
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Exam Series</th>
                    <th>Actions</th>
                  </tr>
                </thead>
              }
              tbody={examSeries.sort(compareAlphabetically).map((es, index) => (
                <tr key={index}>
                  <td className="align-middle">{es.id}</td>
                  <td className="align-middle">{es.year}</td>
                  <td className="align-middle">
                    <Link
                      to={formatRoute(Routes.examSeriesIndividual, {
                        examSeriesId: es.id,
                      })}
                    >
                      <Button size="sm" variant="primary" className="rounded-0">
                        <i className="fas fa-eye"></i> View
                      </Button>
                    </Link>
                  </td>
                </tr>
              ))}
            />
          ) : (
            ""
          )}
        </Col>
      </Row>
    </DashboardPage>
  );
};

export default Pulse.React(ExamSeries, (core) => {
  return {
    examSeries: core.examSeries.all,
  };
});
