import Pulse from "pulse-framework";
import React from "react";

import AuthCollection from "Core/auth.collection";
import ExamBoardsCollection from "Core/examBoards.collection";
import QualificationsCollection from "Core/qualifications.collection";
import SubjectsCollection from "Core/subjects.collection";
import ExamSeasonsCollection from "Core/examSeasons.collection";
import ExamSeriesCollection from "Core/examSeries.collection";
import DocumentTypesCollection from "Core/documentTypes.collection";
import DifficultiesCollection from "Core/difficulties.collection";
import ToastsCollection from "Core/toast.collection";
import UnitsCollection from "Core/units.collection";
import DocumentsCollection from "Core/documents.collection";
import ChaptersCollection from "Core/chapters.collection";
import ExercisesCollection from "Core/exercises.collection";
import LoggerCollection from "Core/logger.collection";

Pulse.use(React);

export default new Pulse({
  request: {
    // baseURL: "http://127.0.0.1:8000/api",
    baseURL: "https://api.mystudybro.com/api",

    requestIntercept({ request, auth }) {
      if (typeof auth.token !== "undefined" && auth.token !== null && auth.isAuthenticated) {
        request.headers["Authorization"] = `Bearer ${auth.token}`;
      } else {
        request.headers["Authorization"] = "";
        auth.clearData();
      }
      if (!auth.isAuthenticated) {
        request.headers["Authorization"] = "";
        auth.clearData();
      }
    },

    // do something after each request
    responseIntercept({ auth, toasts }, response) {
      if (response.status === 401) {
        auth.isAuthenticated = false;
      }
    },
  },

  actions: {
    handleError: ({ toasts }, e) => {
      let errorMessage;

      if (e && e.status && e.status.message) {
        errorMessage = e.status.message;
      }

      if (e && e.error && e.error.message) {
        errorMessage = e.error.message;
      }
      toasts.create({
        type: "danger",
        title: "MyStudyBro",
        body: errorMessage || "Unknown error occurred!",
      });
      return "error";
    },

    makeQueryParams: (context, data = {}) => {
      if (typeof data !== "object") return false;

      let queryParams = "?";

      let dataArray = Object.keys(data);
      dataArray.forEach((param, index) => {
        if (index === dataArray.length - 1) {
          queryParams = `${queryParams}${param}=${data[param]}`;
        } else {
          queryParams = `${queryParams}${param}=${data[param]}&`;
        }
      });

      return queryParams;
    },
  },

  collections: {
    auth: AuthCollection,
    examBoards: ExamBoardsCollection,
    qualifications: QualificationsCollection,
    subjects: SubjectsCollection,
    examSeasons: ExamSeasonsCollection,
    examSeries: ExamSeriesCollection,
    documentTypes: DocumentTypesCollection,
    difficulties: DifficultiesCollection,
    toasts: ToastsCollection,
    units: UnitsCollection,
    documents: DocumentsCollection,
    chapters: ChaptersCollection,
    exercises: ExercisesCollection,
    logger: LoggerCollection,
  },
});
