export default {
  groups: ["all", "selectedExamBoard"],
  data: {},
  persist: [],
  routes: {
    // Receive all subjects
    subjects: (request) => request.get("subjects"),
    // Create a subject
    create: (request, data) => request.post("subjects", data),
    // Get individual Subject
    find: (request, query) => request.get("subjects/get" + query),
    // Edit Data
    editData: (request, data) => request.post("subjects/edit", data),
  },
  actions: {
    // Receive all subjects
    getSubjects: ({ routes, subjects, base }) => {
      return routes
        .subjects()
        .then((res) => {
          subjects.deleteGroup("all");
          subjects.collect(res.subjects, "all");
          return res;
        })
        .catch((e) => base.handleError(e));
    },

    // create a subject
    create: ({ routes }, data) => {
      return routes
        .create(data)
        .then((res) => res)
        .catch((e) => e);
    },

    // Find an Subject
    find: ({ routes, base, units }, data) => {
      let queryParams = base.makeQueryParams(data);
      return routes
        .find(queryParams)
        .then((res) => {
          units.deleteGroup("selectedExamBoardUnits");
          units.collect(res.subject.Units, "selectedExamBoardUnits");
          return { status: res.status, result: res.subject };
        })
        .catch((e) => e);
    },

    // edit fields
    edit: ({ routes }, data) => {
      return routes
        .editData(data)
        .then((res) => {
          return res;
        })
        .catch((e) => e);
    },
  },
};
