export default {
  groups: ["all", "documentExercises"],
  data: {},
  persist: [],
  routes: {
    // Create an exercise
    create: (request, body) => request.post("exercises", body),

    // Get Exercise Information for Individual Page
    getInfo: (request, query) => request.get("exercises/getInfo" + query),
  },
  actions: {
    // Create an exercise
    create: ({ routes, exercises }, data) => {
      return routes
        .create(data)
        .then((res) => {
          exercises.collect(res.exercise, "documentExercises");
          return res;
        })
        .catch((e) => e);
    },

    // Generate exercise link
    generateExerciseLink: ({ request }, exercise, download = false, include = "qa") => {
      let { baseURL } = request;
      let exerciseLink = `${baseURL}/exercises/get?id=${exercise.id}${
        download ? "&download=true" : ""
      } &include=${include}`;
      return exerciseLink;
    },

    // Get Exercise Information
    // Find an Exam Series
    find: ({ routes, base }, data) => {
      let queryParams = base.makeQueryParams(data);
      return routes
        .getInfo(queryParams)
        .then((res) => {
          return { status: res.status, result: res.exercise };
        })
        .catch((e) => e);
    },
  },
};
