import React, { useState, useEffect } from "react";

import { withRouter } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import qs from "qs";

const PaginationTable = withRouter((props) => {
  let { prefix } = props;
  let query = qs.parse(props.location.search, { ignoreQueryPrefix: true });

  const [tbody, setTbody] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [items, setItems] = useState([]);

  const [customItemsPerPage, setCustomItemsPerPage] = useState(false);

  let numberOfItems = props.tbody.length;

  let itemsPerPage = customItemsPerPage || props.itemsPerPage;
  itemsPerPage = parseInt(itemsPerPage);
  let maxPage = Math.ceil(numberOfItems / itemsPerPage);

  let updateItems = (_) => {
    let newTbody = tbody;
    let index = (currentPage - 1) * itemsPerPage;
    index = parseInt(index);
    newTbody = newTbody.slice(index, index + itemsPerPage);
    setItems([...newTbody]);
  };

  useEffect(() => {
    setTbody(props.tbody);
    updateItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tbody]);

  useEffect(() => {
    updateItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, tbody]);

  useEffect(() => {
    setCurrentPage(1);
    updateItems();

    if (customItemsPerPage) {
      let newQuery = query;
      newQuery[`${prefix}PerPage`] = customItemsPerPage;
      newQuery = qs.stringify(newQuery);
      newQuery = `?${newQuery}`;

      props.history.push({ pathname: props.location.pathname, search: newQuery });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customItemsPerPage]);

  useEffect(() => {
    if (query && query[`${prefix}CurrentPage`]) {
      setCurrentPage(parseInt(query[`${prefix}CurrentPage`]));
    }

    if (query && query[`${prefix}PerPage`]) {
      setCustomItemsPerPage(parseInt(query[`${prefix}PerPage`]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentPage) {
      let newQuery = query;
      newQuery[`${prefix}CurrentPage`] = currentPage;
      newQuery = qs.stringify(newQuery);
      newQuery = `?${newQuery}`;

      props.history.push({ pathname: props.location.pathname, search: newQuery });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <>
      <Table
        className={props.className}
        striped={props.striped}
        hover={props.hover}
        borderless={props.borderless}
      >
        {props.thead}
        <tbody>{items}</tbody>
        {props.tfoot ? props.thead : ""}
      </Table>

      <Row className="mt-3">
        <Col md={6}>
          <Pagination hidden={maxPage === 1}>
            <Pagination.First onClick={(e) => setCurrentPage(1)} disabled={currentPage === 1} />
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={(e) => setCurrentPage(currentPage - 1)}
            />
            {numberOfItems - itemsPerPage * currentPage > 0 ? (
              <>
                <Pagination.Next onClick={(e) => setCurrentPage(currentPage + 1)} />
                <Pagination.Last onClick={(e) => setCurrentPage(maxPage)} />
              </>
            ) : (
              ""
            )}
          </Pagination>
        </Col>
        <Col md={3}></Col>
        <Col md={3}>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Control
              size="sm"
              as="select"
              onChange={(e) => setCustomItemsPerPage(e.target.value)}
            >
              <option
                value={props.itemsPerPage}
                selected={customItemsPerPage === props.itemsPerPage}
              >
                {" "}
                {props.itemsPerPage} Per Page
              </option>
              {props.itemsPerPage !== 5 ? (
                <option value={5} selected={customItemsPerPage === 5}>
                  5 Per Page
                </option>
              ) : (
                ""
              )}
              <option value={10} selected={customItemsPerPage === 10}>
                10 Per Page
              </option>
              <option value={15} selected={customItemsPerPage === 15}>
                15 Per Page
              </option>
              <option value={25} selected={customItemsPerPage === 25}>
                25 Per Page
              </option>
              <option value={50} selected={customItemsPerPage === 50}>
                50 Per Page
              </option>
              <option value={100} selected={customItemsPerPage === 100}>
                100 Per Page
              </option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
});

export default PaginationTable;
