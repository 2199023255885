import React, { useEffect, useState } from "react";
import DashboardPage from "Components/Templates/DashboardPage";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Core from "Core";
import Pulse from "pulse-framework";
import AdvancedForm from "Components/Form/AdvancedForm";
import Alert from "react-bootstrap/Alert";
import LoadingComponent from "Components/LoadingComponent";

const SubjectsCreate = (props) => {
  let { qualifications } = props;

  const [errorQualifications, setErrorQualifications] = useState(false);
  const [loadingQualifications, setLoadingQualifications] = useState(false);

  const [qualificationOptions, setQualificationOptions] = useState([]);

  const getQualifications = () => {
    setLoadingQualifications(true);
    setErrorQualifications(false);
    Core.qualifications.getQualifications().then((res) => {
      if (res === "error") setErrorQualifications(true);
      setLoadingQualifications(false);
    });
  };

  useEffect(() => {
    getQualifications();
  }, []);

  useEffect(() => {
    let newQ = qualifications.map((qualification, index) => {
      return {
        key: index,
        value: qualification.id,
        text: `${qualification.name} (${qualification.prefix})`,
      };
    });
    setQualificationOptions([...newQ]);
  }, [qualifications]);

  return (
    <DashboardPage layoutFor="subjects">
      <Row>
        <Col md={7}>
          <h1>Create Subject</h1>
          <p className="text-secondary">
            Use this page to create a <strong className="bg-info text-light">Subject</strong>
          </p>

          <hr />

          {errorQualifications ? (
            <Alert variant="danger" className="my-3">
              <Alert.Heading>Couldn't receive Qualifications</Alert.Heading>
              <hr />
              There was an error receiving the Qualifications needed in order to create a Subject.
            </Alert>
          ) : (
            ""
          )}

          {loadingQualifications && qualifications?.length > 0 ? (
            <LoadingComponent />
          ) : (
            <AdvancedForm
              formName="Subjects"
              fields={{
                title1: {
                  content: "General Information",
                  header: "3",
                },
                p1: {
                  content: "Enter general information regarding the Subject you are creating.",
                  header: "p",
                  colBp: "md",
                  className: "mt-0",
                  colSize: "12",
                },
                subjectName: {
                  placeholder: "Enter Name",
                  underText: "Enter a Subejct name that doesn't exist",
                  label: "Subject Name",
                  icon: <i className="fas fa-chalkboard"></i>,
                  colBp: "md",
                  colSize: "12",
                },
                subjectDetails: {
                  placeholder: "Subject Details",
                  label: ["Details ", <small>(Optional)</small>],
                  icon: <i className="fas fa-info-circle"></i>,
                  colBp: "md",
                  colSize: "12",
                  as: "textarea",
                },
                title2: {
                  content: "Subject Details",
                  header: "3",
                  className: "mt-2",
                  colBp: "md",
                  colSize: "12",
                },
                p2: {
                  content:
                    "Enter details below in order to properly relate the Subject to the correct Qualification.",
                  header: "p",
                  colBp: "md",
                  className: "mt-0",
                  colSize: "12",
                },
                subjectQualificationId: {
                  label: "Subject Qualification",
                  icon: <i className="fas fa-layer-group"></i>,
                  as: "select",
                  placeholder: "Select a Qualification",
                  options: qualificationOptions,
                },
              }}
              button={{
                action: Core.subjects.create,
              }}
            />
          )}
        </Col>
      </Row>
    </DashboardPage>
  );
};

export default Pulse.React(SubjectsCreate, (core) => {
  return {
    qualifications: core.qualifications.all,
  };
});
