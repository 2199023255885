import React, { useState, useEffect } from "react";
import DashboardPage from "Components/Templates/DashboardPage";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import LoadingComponent from "Components/LoadingComponent";

import Core from "Core";
import Pulse from "pulse-framework";
import Spinner from "react-bootstrap/Spinner";

import { Link } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import Routes from "Routes";
import PaginationTable from "Components/PaginationTable";

const ExamBoards = (props) => {
  let { examBoards } = props;

  const [errorExamBoards, setErrorExamBoards] = useState(false);
  const [loadingExamBoards, setLoadingExamBoards] = useState(false);

  const getExamBoards = () => {
    setLoadingExamBoards(true);
    setErrorExamBoards(false);
    Core.examBoards.getExamBoards().then((res) => {
      if (res === "error") setErrorExamBoards(true);
      setLoadingExamBoards(false);
    });
  };

  useEffect(() => {
    getExamBoards();
  }, []);

  return (
    <DashboardPage layoutFor="examBoards">
      <Row>
        <Col md={7}>
          <h1>ExamBoards</h1>
          <p className="text-secondary">
            List of the <strong>Examination Boards</strong> registered.
          </p>
          <hr />

          <Button
            onClick={getExamBoards.bind(this)}
            variant="outline-primary"
            size="sm"
            disabled={loadingExamBoards}
          >
            {loadingExamBoards ? (
              <Spinner className="mx-3" animation="border" size="sm" />
            ) : (
              <>
                <i className="fas fa-sync pr-1"></i> Reload
              </>
            )}
          </Button>

          {errorExamBoards ? (
            <Alert variant="danger" className="my-3">
              <Alert.Heading>Couldn't receive Exam Boards</Alert.Heading>
              <hr />
              There was an error receiving the Exam Boards.
            </Alert>
          ) : (
            ""
          )}
          {loadingExamBoards ? (
            <LoadingComponent />
          ) : !errorExamBoards ? (
            <PaginationTable
              prefix="examBoards"
              className="mt-3"
              itemsPerPage={5}
              borderless
              striped
              thead={
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Exam Board</th>
                    <th>Actions</th>
                  </tr>
                </thead>
              }
              tbody={examBoards.map((eb, index) => (
                <tr key={index}>
                  <td className="align-middle">{eb.id}</td>
                  <td className="align-middle">{eb.name}</td>
                  <td className="align-middle">
                    <Link
                      to={formatRoute(Routes.examBoardsIndividual, {
                        examBoardId: eb.id,
                      })}
                    >
                      <Button size="sm" variant="primary" className="rounded-0">
                        <i className="fas fa-eye"></i> View
                      </Button>
                    </Link>
                  </td>
                </tr>
              ))}
            />
          ) : (
            ""
          )}
        </Col>
      </Row>
    </DashboardPage>
  );
};

export default Pulse.React(ExamBoards, (core) => {
  return {
    examBoards: core.examBoards.all,
  };
});
