export default {
  groups: ["allToasts"],
  data: {},
  persist: [],
  routes: {},
  actions: {
    create: ({ toasts }, body) => {
      if (body && body.type && body.title && body.body) {
        toasts.collect({ ...body, active: true, id: toasts.allToasts.length + 1 }, "allToasts");
      }
    },
  },
};
