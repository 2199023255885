import React from "react";

import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

export default (props) => {
  return (
    <>
      <Button
        block={props.block}
        className={props.className || ""}
        disabled={props.isLoading || props.disabled}
        onClick={props.onClick}
        variant={props.variant}
        size={props.size}
      >
        {props.isLoading ? (
          <Spinner className={props.size !== "sm" ? "mx-3" : ""} animation="border" size="sm" />
        ) : (
          props.text
        )}
      </Button>
    </>
  );
};
