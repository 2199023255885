import { useState, useEffect } from "react";

export const useIsAvailable = (e) => {
  const [response, setResponse] = useState(false);

  useEffect(() => {
    let { find, findQueryKey, findQueryValue } = e;

    let data = { [findQueryKey]: findQueryValue };

    if (e.secondaryFindQueryValue > 0 || e.secondaryFindQueryValue) {
      data[e.secondaryFindQueryKey] = e.secondaryFindQueryValue;
    }

    setResponse(false);
    find(data).then((res) => {
      if (res && res.status) {
        if (res.status.code === 404) {
          setResponse(undefined);
        } else {
          setResponse(res.result);
        }
      } else {
        setResponse(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [e.refreshCounter]);

  return response;
};
