export default {
  groups: ["all", "selectedExamBoardUnits"],
  data: {},
  persist: [],
  routes: {
    // Get individual unit
    find: (request, query) => request.get("units/get" + query),
    // Create unit : post
    create: (request, data) => request.post("units", data),
    // Toggle Privacy Status
    togglePrivateStatus: (request, unitId) => request.post(`units/togglePrivate`, { unitId }),
    // Edit Data
    editData: (request, data) => request.post("units/edit", data),
  },
  actions: {
    // Toggle Unit Privacy
    togglePrivacy: ({ routes, base }, unitId) => {
      return routes
        .togglePrivateStatus(unitId)
        .then((res) => {
          return res;
        })
        .catch((e) => base.handleError(e));
    },

    // Find an unit
    find: ({ routes, base }, data) => {
      let queryParams = base.makeQueryParams(data);
      return routes
        .find(queryParams)
        .then((res) => {
          return { status: res.status, result: res.unit };
        })
        .catch((e) => e);
    },

    // Create a unit
    create: ({ routes, units }, data) => {
      return routes
        .create(data)
        .then((res) => {
          units.collect(res.unit, "selectedExamBoardUnits");
          return res;
        })
        .catch((e) => e);
    },

    // edit fields
    edit: ({ routes }, data) => {
      return routes
        .editData(data)
        .then((res) => {
          return res;
        })
        .catch((e) => e);
    },
  },
};
