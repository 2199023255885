export default {
  groups: ["all"],
  data: {},
  persist: [],
  routes: {
    // Receive all examSeasons
    examSeasons: (request) => request.get("examseasons"),
    // Create an Exam Season
    create: (request, body) => request.post("examseasons", body),
    // Get individual Exam Season
    find: (request, query) => request.get("examseasons/get" + query),
    // Edit Data
    editData: (request, data) => request.post("examseasons/edit", data),
  },
  actions: {
    // Receive all examSeasons
    getExamSeasons: ({ routes, examSeasons, base }) => {
      return routes
        .examSeasons()
        .then((res) => {
          examSeasons.deleteGroup("all");
          examSeasons.collect(res.examSeasons, "all");
          return res;
        })
        .catch((e) => base.handleError(e));
    },

    // Create Exam Season
    create: ({ routes }, data) => {
      return routes
        .create(data)
        .then((res) => res)
        .catch((e) => e);
    },

    // Find an Exam Season
    find: ({ routes, base }, data) => {
      let queryParams = base.makeQueryParams(data);
      return routes
        .find(queryParams)
        .then((res) => {
          return { status: res.status, result: res.examSeason };
        })
        .catch((e) => e);
    },

    // edit fields
    edit: ({ routes }, data) => {
      return routes
        .editData(data)
        .then((res) => {
          return res;
        })
        .catch((e) => e);
    },
  },
};
