import React, { useEffect, useState } from "react";
import DashboardPage from "Components/Templates/DashboardPage";
import Core from "Core";
import { useIsAvailable } from "Components/Hooks/ApiHooks";
import FindLoadingComponent from "Components/FindLoadingComponent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ModelTable from "Components/ModelTable";
import qs from "qs";
import SquarePanel from "Components/Panels/SquarePanel";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import Routes from "Routes";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import PaginationTable from "Components/PaginationTable";
import Badge from "react-bootstrap/Badge";
import AdvancedForm from "Components/Form/AdvancedForm";
import Pulse from "pulse-framework";
import EditFieldModal from "Components/Modals/EditFieldModal";

const SubjectsIndividual = (props) => {
  const [showUnitCreate, setShowUnitCreate] = useState(false);

  let { subjectId } = props.match.params;
  let { units } = props;

  let queryObject = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  let selectedExamBoardId = queryObject.ebId || false;

  const secondarySearch = {};

  if (selectedExamBoardId) {
    secondarySearch["secondaryFindQueryKey"] = "examBoardId";
    secondarySearch["secondaryFindQueryValue"] = selectedExamBoardId;
  }

  const [refresh, setRefresh] = useState(0);

  const subject = useIsAvailable({
    find: Core.subjects.find,
    findQueryKey: "id",
    findQueryValue: subjectId,
    ...secondarySearch,
    refreshCounter: refresh,
  });

  const fetchData = () => setRefresh(refresh + 1);

  useEffect(() => {
    setRefresh(refresh + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedExamBoardId]);

  const compareAlphabetically = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const bandA = a.prefix.toUpperCase();
    const bandB = b.prefix.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  };

  const toggleShowUnitCreate = (_) => setShowUnitCreate(!showUnitCreate);

  const edit = (data) => {
    return new Promise((resolve, reject) => {
      Core.subjects.edit(data).then((res) => {
        if (res?.status.type === "success") fetchData();
        return resolve(res);
      });
    });
  };

  return (
    <DashboardPage layoutFor="subjects">
      <FindLoadingComponent modelName="Subject" model={subject}>
        {typeof subject === "object" ? (
          <Row>
            <Col md={7}>
              <h1>{subject.name}</h1>
              <Link
                to={formatRoute(Routes.qualificationsIndividual, {
                  qualificationId: subject.Qualification.id,
                })}
              >
                {subject.Qualification.name} - {subject.Qualification.prefix}
              </Link>
              <hr />
              <Button className="mb-3" size="sm" variant="primary" onClick={fetchData.bind(this)}>
                <>
                  <i className="fas fa-sync pr-1"></i> Reload
                </>
              </Button>
            </Col>
            <Col md={5}></Col>

            <Col md={7}>
              <SquarePanel
                headerVariant=""
                header={
                  <strong>
                    <i className="fas fa-info-circle mr-2"></i> General Information
                  </strong>
                }
              >
                <p>
                  Basic information regarding the registration of Subject: {subject.name} (
                  {subject.Qualification.prefix})
                </p>
                <hr />
                <p>
                  <strong>Subject ID: </strong>
                  {subject.id}
                </p>
                <p>
                  <strong>Subject Name: </strong>
                  <EditFieldModal
                    fieldKey="name"
                    fieldDisplayName="Subject Name"
                    currentValue={subject.name}
                    modelId={subject.id}
                    action={edit}
                  />
                  <br />
                  {subject.name}
                </p>
                <p>
                  <strong>Subject Details: </strong>
                  <EditFieldModal
                    fieldKey="details"
                    fieldDisplayName="Subject Details"
                    currentValue={subject.details}
                    modelId={subject.id}
                    as="textarea"
                    action={edit}
                  />
                  <br />
                  {subject.details}
                </p>
                <p>
                  <strong>Created At: </strong>
                  {[
                    new Date(subject["createdAt"]).toDateString(),
                    " - ",
                    new Date(subject["createdAt"]).toLocaleTimeString(),
                  ]}
                </p>
                <p>
                  <strong>Updated At: </strong>
                  {[
                    new Date(subject["updatedAt"]).toDateString(),
                    " - ",
                    new Date(subject["updatedAt"]).toLocaleTimeString(),
                  ]}
                </p>
                {/* <ModelTable model={subject} /> */}
              </SquarePanel>
            </Col>

            <Col md={5} />

            <Col md={4}>
              <SquarePanel
                header={
                  <strong>
                    <i className="fas fa-info-circle mr-2"></i> Associated Examination Boards
                  </strong>
                }
                headerVariant="info text-white"
              >
                <p>List of Examination Boards that have linked Subject: {subject.name} to them.</p>
                <hr />
                {subject.ExamBoards.length > 0 ? (
                  <Table borderless>
                    <thead>
                      <tr>
                        <th>Exam Board Name</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subject.ExamBoards.map((eb, index) => {
                        return (
                          <tr key={index}>
                            <td>{eb.name}</td>
                            <td>
                              {parseInt(eb.id) === parseInt(selectedExamBoardId) ? (
                                <Button size="sm" variant="primary" className="rounded-0" disabled>
                                  <i className="fas fa-hand-pointer"></i>
                                </Button>
                              ) : (
                                <Link
                                  to={
                                    formatRoute(Routes.subjectsIndividual, {
                                      subjectId: subject.id,
                                    }) + `?ebId=${eb.id}`
                                  }
                                >
                                  <Button size="sm" variant="primary" className="rounded-0">
                                    <i className="fas fa-hand-pointer"></i>
                                  </Button>
                                </Link>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <p className="mt-2">
                      Use{" "}
                      <Button size="sm" variant="primary" className="rounded-0">
                        <i className="fas fa-hand-pointer"></i>
                      </Button>{" "}
                      to view the units of the corresponding Examination Board
                    </p>
                  </Table>
                ) : (
                  ""
                )}
              </SquarePanel>
            </Col>
            {selectedExamBoardId && subject.ExamBoard && subject.ExamBoard.name ? (
              <Col md={8}>
                <SquarePanel
                  header={
                    <strong>
                      <i className="fas fa-info-circle mr-2"></i> Units related to{" "}
                      {subject.ExamBoard.name}
                    </strong>
                  }
                  headerVariant="primary text-white"
                >
                  <p>
                    Here you will find all Units that are found under the{" "}
                    <strong>
                      {subject.name} (
                      <Link
                        to={formatRoute(Routes.qualificationsIndividual, {
                          qualificationId: subject.Qualification.id,
                        })}
                      >
                        {subject.Qualification.name}
                      </Link>
                      ){" "}
                    </strong>
                    of the{" "}
                    <strong>
                      <Link
                        to={formatRoute(Routes.examBoardsIndividual, {
                          examBoardId: subject.ExamBoard.id,
                        })}
                      >
                        {subject.ExamBoard.name}
                      </Link>{" "}
                      Examination Board
                    </strong>
                    .
                  </p>

                  <Link
                    to={props.location.pathname + props.location.search}
                    className="is-block mb-3 d-block"
                    onClick={toggleShowUnitCreate.bind(this)}
                  >
                    <strong>
                      {showUnitCreate ? "Hide" : "Show"} unit create form{" "}
                      <i className={`fas fa-arrow-${showUnitCreate ? "up" : "down"}`}></i>
                    </strong>
                  </Link>

                  {showUnitCreate ? (
                    <div className="bg-light p-3 rounded">
                      <AdvancedForm
                        // onSuccess={(e) => setC(c + 1)}
                        formName="Link Qualifications"
                        values={{
                          unitExamBoardId: subject.ExamBoard.id,
                          unitSubjectId: subject.id,
                        }}
                        fields={{
                          title1: {
                            content: "General Information",
                            header: "3",
                          },
                          p1: {
                            content: (
                              <span>
                                Enter general information regarding the Unit you are creating.
                              </span>
                            ),
                            header: "p",
                            colBp: "md",
                            className: "mt-0",
                            colSize: "12",
                          },
                          p2: {
                            content: (
                              <span>
                                <strong className="text-primary">Exam Board Name:</strong>{" "}
                                {subject.ExamBoard.name} |{" "}
                                <strong className="text-success">Subject Name:</strong>{" "}
                                {subject.name} |{" "}
                                <strong className="text-info">Qualification:</strong>{" "}
                                {subject.Qualification.name} ({subject.Qualification.prefix})
                              </span>
                            ),
                            header: "p",
                            colBp: "md",
                            className: "mt-0",
                            colSize: "12",
                          },
                          unitName: {
                            placeholder: "Enter Name",
                            underText: "Enter a Unit name that doesn't exist",
                            label: "Unit Name",
                            icon: <i className="fas fa-keyboard"></i>,
                            colBp: "md",
                            colSize: "12",
                          },
                          unitCode: {
                            placeholder: "Enter Code",
                            underText: "Enter a Unit code that doesn't exist",
                            label: "Unit Code",
                            icon: <i className="fas fa-keyboard"></i>,
                            colBp: "md",
                            colSize: "6",
                          },
                          unitPrefix: {
                            placeholder: "Enter Prefix",
                            underText: "Enter a Unit prefix that doesn't exist",
                            label: "Unit Prefix",
                            icon: <i className="fas fa-keyboard"></i>,
                            colBp: "md",
                            colSize: "6",
                          },
                          unitDetails: {
                            placeholder: "Unit Details",
                            label: ["Details ", <small>(Optional)</small>],
                            icon: <i className="fas fa-info-circle"></i>,
                            colBp: "md",
                            colSize: "12",
                            as: "textarea",
                          },
                        }}
                        button={{
                          action: Core.units.create,
                          noMargin: true,
                          variant: "primary",
                          text: (
                            <span>
                              <i className="fas fa-plus"></i> Create Unit
                            </span>
                          ),
                          className: "rounded-0",
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  <hr />

                  {units && units.length > 0 ? (
                    <PaginationTable
                      prefix="units"
                      itemsPerPage={5}
                      borderless
                      thead={
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Unit Name</th>
                            <th>Unit Prefix</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                      }
                      tbody={units.sort(compareAlphabetically).map((unit, index) => (
                        <tr key={index}>
                          <td>{unit.id}</td>
                          <td>
                            <Link to={formatRoute(Routes.unitsIndividual, { unitId: unit.id })}>
                              {unit.name}
                            </Link>
                          </td>
                          <td>{unit.prefix}</td>
                          <td>
                            {unit.private ? (
                              <Badge variant="danger">Private</Badge>
                            ) : (
                              <Badge variant="success">Public</Badge>
                            )}
                          </td>
                          <td></td>
                        </tr>
                      ))}
                    />
                  ) : (
                    <Alert variant="warning">No Units found.</Alert>
                  )}
                </SquarePanel>
              </Col>
            ) : (
              ""
            )}
          </Row>
        ) : (
          ""
        )}
      </FindLoadingComponent>
    </DashboardPage>
  );
};

export default Pulse.React(SubjectsIndividual, (core) => {
  return {
    units: core.units.selectedExamBoardUnits,
  };
});
