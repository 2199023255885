import React from "react";
import Spinner from "react-bootstrap/Spinner";

export default (props) => {
  return (
    <div className="py-5" style={{ width: "100%", height: "100", display: "table" }}>
      <span
        style={{
          display: "table-cell",
          verticalAlign: "middle",
          width: "100%",
          textAlign: "center",
        }}
      >
        <Spinner
          style={{ width: "3rem", height: "3rem" }}
          animation="grow"
          role="status"
          variant={props.variant || "primary"}
        >
          <span className="sr-only">Loading...</span>
        </Spinner>
        {/* Loading... */}
      </span>
    </div>
  );
};
