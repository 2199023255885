import React, { useState } from "react";
import DashboardPage from "Components/Templates/DashboardPage";
import Core from "Core";
import { useIsAvailable } from "Components/Hooks/ApiHooks";
import FindLoadingComponent from "Components/FindLoadingComponent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ModelTable from "Components/ModelTable";
import SquarePanel from "Components/Panels/SquarePanel";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

import { Link } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import Routes from "Routes";
import EditFieldModal from "Components/Modals/EditFieldModal";

const QualificationsIndividual = (props) => {
  let { qualificationId } = props.match.params;

  const [qualificationReload, setQualificationReload] = useState(0);
  const fetchData = (_) => setQualificationReload(qualificationReload + 1);
  const qualification = useIsAvailable({
    find: Core.qualifications.find,
    findQueryKey: "id",
    findQueryValue: qualificationId,
    refreshCounter: qualificationReload,
  });

  const edit = (data) => {
    return new Promise((resolve, reject) => {
      Core.qualifications.edit(data).then((res) => {
        if (res?.status.type === "success") fetchData();
        return resolve(res);
      });
    });
  };

  return (
    <DashboardPage layoutFor="qualifications">
      <FindLoadingComponent modelName="Qualification" model={qualification}>
        {typeof qualification === "object" ? (
          <Row>
            <Col md={7}>
              <h1>{qualification.name}</h1>
              <hr />
              <Button className="mb-3" size="sm" variant="primary" onClick={fetchData.bind(this)}>
                <>
                  <i className="fas fa-sync pr-1"></i> Reload
                </>
              </Button>
            </Col>
            <Col md={5}></Col>

            <Col md={7}>
              <SquarePanel
                header={
                  <strong>
                    <i className="fas fa-info-circle mr-2"></i> General Information
                  </strong>
                }
              >
                <p>Basic Information regaring the registration of {qualification.name}.</p>
                <hr />
                <p>
                  <strong>Qualification ID: </strong>
                  {qualification.id}
                </p>
                <p>
                  <strong>Qualification Name: </strong>
                  <EditFieldModal
                    fieldKey="name"
                    fieldDisplayName="Qualification Name"
                    currentValue={qualification.name}
                    modelId={qualification.id}
                    action={edit}
                  />
                  <br />
                  {qualification.name}
                </p>
                <p>
                  <strong>Qualification Prefix: </strong>
                  <EditFieldModal
                    fieldKey="prefix"
                    fieldDisplayName="Qualification Prefix"
                    currentValue={qualification.prefix}
                    modelId={qualification.id}
                    action={edit}
                  />
                  <br />
                  {qualification.prefix}
                </p>
                <p>
                  <strong>Qualification Details: </strong>
                  <EditFieldModal
                    fieldKey="details"
                    fieldDisplayName="Qualification Details"
                    currentValue={qualification.details}
                    modelId={qualification.id}
                    as="textarea"
                    action={edit}
                  />
                  <br />
                  {qualification.details}
                </p>
                <p>
                  <strong>Created At: </strong>
                  {[
                    new Date(qualification["createdAt"]).toDateString(),
                    " - ",
                    new Date(qualification["createdAt"]).toLocaleTimeString(),
                  ]}
                </p>
                <p>
                  <strong>Updated At: </strong>
                  {[
                    new Date(qualification["updatedAt"]).toDateString(),
                    " - ",
                    new Date(qualification["updatedAt"]).toLocaleTimeString(),
                  ]}
                </p>
                {/* <ModelTable model={qualification} /> */}
              </SquarePanel>
            </Col>
            <Col md={5}>
              <SquarePanel
                headerVariant="primary text-white"
                header={
                  <strong>
                    <i className="fas fa-info-circle mr-2"></i> Related Exam Boards
                  </strong>
                }
              >
                <p>
                  Listed below is the list of Examination Boards that have linked{" "}
                  <strong>{qualification.name}</strong> to them.
                </p>

                <hr />

                <Table
                  borderless
                  hidden={qualification.ExamBoars && qualification.ExamBoars.length < 1}
                >
                  <thead>
                    <tr>
                      <th>Exam Board Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {qualification.ExamBoards.map((eb, index) => (
                      <tr key={index}>
                        <td>{eb.name}</td>
                        <td>
                          <Link
                            to={formatRoute(Routes.examBoardsIndividual, {
                              examBoardId: eb.id,
                            })}
                          >
                            <Button size="sm" variant="primary" className="rounded-0">
                              <i className="fas fa-eye"></i>
                            </Button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </SquarePanel>

              <SquarePanel
                headerVariant="info text-white"
                header={
                  <strong>
                    <i className="fas fa-info-circle mr-2"></i> Related Subjects
                  </strong>
                }
              >
                <p>
                  Listed below is the list of Subjects that have linked{" "}
                  <strong>{qualification.name}</strong> to them.
                </p>

                <hr />

                <Table
                  borderless
                  hidden={qualification.Subjects && qualification.Subjects.length < 1}
                >
                  <thead>
                    <tr>
                      <th>Subject Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {qualification.Subjects.map((subject, index) => (
                      <tr key={index}>
                        <td>{subject.name}</td>
                        <td>
                          <Link
                            to={formatRoute(Routes.subjectsIndividual, {
                              subjectId: subject.id,
                            })}
                          >
                            <Button size="sm" variant="primary" className="rounded-0">
                              <i className="fas fa-eye"></i>
                            </Button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </SquarePanel>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </FindLoadingComponent>
    </DashboardPage>
  );
};

export default QualificationsIndividual;
