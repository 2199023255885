import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import LoadingButton from "Components/Buttons/LoadingButton";
import Alert from "react-bootstrap/Alert";

const AdvancedForm = (props) => {
  const [inputs, setInputs] = useState({});

  const [loadingCreate, setLoadingCreate] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [validationErrors, setValidationErrors] = useState({});
  const [validationErrorMessageContent, setValidationErrorMessageContent] = useState([]);

  const { fields, button, formName } = props;

  useEffect(() => {
    setInputs({ ...inputs, ...props.values });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values]);

  if (!fields || !button || Object.keys(fields).length < 1 || !formName) return <div></div>;
  const onInputChange = (e, type) => {
    if (typeof fields[e.target.name]?.onChange === "function") {
      fields[e.target.name].onChange(e);
    }

    let newInputs = {};

    if (type === "isFile") {
      newInputs = { ...inputs, [e.target.name]: e.target.files[0] };
      setInputs(newInputs);
    } else {
      newInputs = { ...inputs, [e.target.name]: e.target.value };
      setInputs(newInputs);
    }

    let fieldName = e.target.name;
    if (
      typeof props.inputsToListenTo === "object" &&
      props.inputsToListenTo.list &&
      props.inputsToListenTo.list[0] &&
      props.inputsToListenTo.action &&
      typeof props.inputsToListenTo.action === "function"
    ) {
      console.log(props.inputsToListenTo.list.indexOf(fieldName));
      if (props.inputsToListenTo.list.indexOf(fieldName) > -1) {
        props.inputsToListenTo.action(newInputs);
      }
    }
  };

  const create = (_) => {
    setLoadingCreate(true);
    setSuccess(false);
    setError(false);
    setValidationErrors({});
    setValidationErrorMessageContent([]);

    button.action({ ...props.values, ...inputs }).then((res) => {
      if (res.status?.type === "error" && !res.error?.validationFailed) {
        setError(res.error?.message || "Unknown Error");
      }

      if (
        res.status?.type === "error" &&
        res.error?.validationFailed &&
        res.error?.validationErrors.length > 0
      ) {
        let newValidationErrors = {};
        let newValidationErrorMessageContent = [];

        res.error.validationErrors.forEach((ve, index) => {
          newValidationErrors[ve.fieldName] = true;
          newValidationErrorMessageContent.push(ve.message);
          if (index === res.error.validationErrors.length - 1) {
            setValidationErrors({ ...newValidationErrors });
            setValidationErrorMessageContent([...newValidationErrorMessageContent]);
          }
        });
      }
      if (res?.status?.type === "success") {
        setSuccess(res.status?.message || "Success");
        setInputs({});
        if (props.onSuccess && typeof props.onSuccess === "function") {
          props.onSuccess();
        }
      }
      setLoadingCreate(false);
    });
  };

  return (
    <div>
      <Row>
        <Col md={12}>
          {error ? (
            <Alert variant="danger">
              <Alert.Heading>Error Creating {formName}</Alert.Heading>
              <hr />
              {error}
            </Alert>
          ) : (
            ""
          )}

          {validationErrorMessageContent.length > 0 ? (
            <Alert variant="danger">
              <Alert.Heading>Entered information is invalid.</Alert.Heading>
              <hr />
              <ul>
                {validationErrorMessageContent.map((e, index) => (
                  <li key={index}>{e}</li>
                ))}
              </ul>
            </Alert>
          ) : (
            ""
          )}

          {success ? (
            <Alert variant="success">
              <Alert.Heading>Created {formName}</Alert.Heading>
              <hr />
              {success}
            </Alert>
          ) : (
            ""
          )}
        </Col>

        {Object.keys(fields).map((field, index) => {
          let fieldName = field;
          field = fields[fieldName];

          let props = {
            className: field.className,
          };
          props[field.colBp] = field.colSize;

          let formControlProps = {};

          if (field.as === "select") {
            formControlProps.children = [<option value="">{field.placeholder}</option>];

            let dropdownOptions = [...field.options];

            dropdownOptions.forEach((e) =>
              !e.group
                ? formControlProps.children.push(
                    <option selected={inputs[fieldName] === e.value} value={e.value}>
                      {e.text}
                    </option>
                  )
                : ""
            );
          }

          let header;
          if (field.header && typeof field.header !== "undefined") {
            let headerProps = { className: field.className };

            switch (field.header) {
              case "1":
                header = <h1 {...headerProps}>{field.content}</h1>;
                break;

              case "2":
                header = <h2 {...headerProps}>{field.content}</h2>;
                break;

              case "3":
                header = <h3 {...headerProps}>{field.content}</h3>;
                break;

              case "p":
                header = <p {...headerProps}>{field.content}</p>;
                break;

              default:
                header = <h1 {...headerProps}>{field.content}</h1>;
                break;
            }
          }

          return (
            <Col key={index} {...props}>
              {!(field.header && typeof field.header !== "undefined") ? (
                // If input
                <Form.Group hidden={field.hidden}>
                  {field.label ? <Form.Label>{field.label}</Form.Label> : ""}
                  <InputGroup>
                    {field.icon ? (
                      <InputGroup.Prepend>
                        <InputGroup.Text>{field.icon}</InputGroup.Text>
                      </InputGroup.Prepend>
                    ) : (
                      ""
                    )}

                    {field.as === "fileUpload" ? (
                      <Form.File
                        onChange={(e) => onInputChange(e, "isFile")}
                        name={fieldName}
                        placeholder={inputs[fieldName]?.name || field.placeholder || "Upload"}
                        as={field.as || "input"}
                        // value={inputs[fieldName] || ""}
                        isInvalid={validationErrors[fieldName]}
                        {...formControlProps}
                      />
                    ) : (
                      <Form.Control
                        onChange={onInputChange.bind(this)}
                        name={fieldName}
                        type={field.type || "text"}
                        placeholder={field.placeholder || "Enter..."}
                        as={field.as || "input"}
                        value={inputs[fieldName] || ""}
                        isInvalid={validationErrors[fieldName]}
                        {...formControlProps}
                      />
                    )}
                  </InputGroup>
                  {field.underText ? (
                    <Form.Text className="text-muted">{field.underText}</Form.Text>
                  ) : (
                    ""
                  )}
                </Form.Group>
              ) : (
                // This is the title
                header
              )}
            </Col>
          );
        })}

        <Col md={12} className={!button.noMargin ? "mb-5 mt-3" : ""}>
          {button ? (
            <LoadingButton
              block={button.block}
              disabled={button.disabled}
              className={button.className || ""}
              text={
                button.text || (
                  <span>
                    <i className="fas fa-plus"></i> Create
                  </span>
                )
              }
              onClick={create.bind(this)}
              variant={button.variant || "info"}
              isLoading={loadingCreate}
            />
          ) : (
            ""
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AdvancedForm;
