import React, { useState, useEffect } from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import LoadingButton from "Components/Buttons/LoadingButton";

const GeneralModal = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (props.forceClose) {
      handleClose();
    }
  }, [props.forceClose]);

  return (
    <>
      {props.button ? (
        <span onClick={handleShow}>{props.button}</span>
      ) : (
        <Button variant="primary" onClick={handleShow}>
          Launch demo modal
        </Button>
      )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title || "Modal heading"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.children || "Woohoo, you're reading this text in a modal!"}</Modal.Body>
        {!props.noFooter ? (
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {!props.primaryBtn ? (
              <LoadingButton
                variant={props.primaryButtonVariant || "primary"}
                text={props.primaryButtonText || "Button"}
                isLoading={props.primaryButtonIsLoading || false}
                onClick={props.primaryButtonOnClick.bind(this)}
              />
            ) : (
              props.primaryBtn
            )}
          </Modal.Footer>
        ) : (
          ""
        )}
      </Modal>
    </>
  );
};

export default GeneralModal;
