export default {
  groups: ["all", "assQual", "assSub"],
  data: {
    selectedExamBoardId: 0,
  },
  persist: [],
  routes: {
    // Receive all examBoards
    examBoards: (request) => request.get("examboards"),
    create: (request, body) => request.post("examboards", body),
    // Get individual Exam Board
    find: (request, query) => request.get("examboards/get" + query),
    // Assign and Un-Assign Qualifications
    assignQualification: (request, body) => request.post("examboards/assignQualification", body),
    unAssignQualification: (request, body) =>
      request.post("examboards/unAssignQualification", body),
    // Assign and Un-Assign Subjects
    assignSubject: (request, body) => request.post("examboards/assignSubject", body),
    unAssignSubject: (request, body) => request.post("examboards/unAssignSubject", body),
    // Edit Data
    editData: (request, data) => request.post("examboards/edit", data),
  },
  actions: {
    setSelected: ({ examBoards }, id) => (examBoards.selectedExamBoardId = id),
    // Receive all examBoards
    getExamBoards: ({ routes, examBoards, base }) => {
      return routes
        .examBoards()
        .then((res) => {
          examBoards.deleteGroup("all");
          examBoards.collect(res.examBoards, "all");
          return res;
        })
        .catch((e) => base.handleError(e));
    },

    // Create Exam Board
    create: ({ routes }, data) => {
      return routes
        .create(data)
        .then((res) => res)
        .catch((e) => e);
    },

    // Find an Exam Board
    find: ({ routes, base, qualifications, subjects }, data) => {
      let queryParams = base.makeQueryParams(data);
      return routes
        .find(queryParams)
        .then((res) => {
          qualifications.deleteGroup(`selectedExamBoard`);
          subjects.deleteGroup(`selectedExamBoard`);
          qualifications.collect(res.examBoard.Qualifications, `selectedExamBoard`);
          subjects.collect(res.examBoard.Subjects, `selectedExamBoard`);
          return { status: res.status, result: res.examBoard };
        })
        .catch((e) => e);
    },

    // Assign Qualfications
    assignQualification: ({ routes, examBoards, qualifications }, data) => {
      return routes
        .assignQualification(data)
        .then((res) => {
          qualifications.collect(res.Qualification, `selectedExamBoard`);
          return res;
        })
        .catch((e) => e);
    },

    // UnAssign Qualification
    unAssignQualification: ({ routes, qualifications, toasts, base }, data) => {
      return routes
        .unAssignQualification(data)
        .then((res) => {
          toasts.create({ type: "success", title: "MyStudyBro", body: res.status.message });
          qualifications.removeFromGroup(`selectedExamBoard`, data.qualificationId);
          return res;
        })
        .catch((e) => base.handleError(e));
    },

    // Assign Subject
    assignSubject: ({ routes, subjects }, data) => {
      return routes
        .assignSubject(data)
        .then((res) => {
          subjects.collect(res.subject, `selectedExamBoard`);
          return res;
        })
        .catch((e) => e);
    },

    // UnAssign Subject
    unAssignSubject: ({ routes, subjects, toasts, base }, data) => {
      return routes
        .unAssignSubject(data)
        .then((res) => {
          toasts.create({ type: "success", title: "MyStudyBro", body: res.status.message });
          subjects.removeFromGroup(`selectedExamBoard`, data.subjectId);
          return res;
        })
        .catch((e) => base.handleError(e));
    },

    // edit fields
    edit: ({ routes }, data) => {
      return routes
        .editData(data)
        .then((res) => {
          return res;
        })
        .catch((e) => e);
    },
  },

  // filters
  filters: {
    assignedQualifications: ({ qualifications, examBoards }) => {
      return qualifications.getGroup(`qualifications:${examBoards.selectedExamBoardId}`);
    },
    assignedSubjects: ({ examBoards, subjects }) => {
      return subjects.getGroup(`subjects:${examBoards.selectedExamBoardId}`);
    },
  },
};
