import AdvancedForm from "Components/Form/AdvancedForm";
import React, { useState } from "react";
import GeneralModal from "./GeneralModal";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const EditFieldModal = (props) => {
  const [closeCounter, setCloseCounter] = useState(0);
  const [inputs, setInputs] = useState({});

  const [confirmationInput, setConfirmationInput] = useState("");

  const closeModal = (_) => setCloseCounter(closeCounter + 1);

  const renameAction = (data) => {
    return new Promise((resolve, reject) => {
      props.action(data).then((res) => {
        if (res !== "error" && res?.status.type === "success") {
          setInputs({});
          setConfirmationInput("");
          closeModal();
        }
        return resolve(res);
      });
    });
  };

  let confirmationCode = `Change to ${inputs[props.fieldKey]}`;
  if (props.as === "textarea") confirmationCode = `Change ${props.fieldKey}`;

  return (
    <GeneralModal
      primaryButtonOnClick={(_) => {}}
      button={<i class="fas fa-edit text-primary px-2"></i>}
      noFooter
      forceClose={closeCounter}
      title={["Rename ", <strong>{props.fieldDisplayName}</strong>]}
    >
      <AdvancedForm
        inputsToListenTo={{ action: setInputs, list: [props.fieldKey] }}
        formName={`Rename ${props.fieldDisplayName}`}
        button={{
          block: true,
          action: renameAction,
          text: "Rename",
          variant: "success",
          noMargin: true,
          disabled: !(confirmationCode === confirmationInput),
        }}
        values={{ id: props.modelId }}
        fields={{
          detailsOfThis: {
            content: [
              "Using this modal you may change the value of ",
              <strong>{props.fieldDisplayName}</strong>,
              ". Please make sure that you are sure about what you are about to do.",
            ],
            header: "p",
          },
          [props.fieldKey || "field"]: {
            placeholder: props.currentValue,
            colBp: "md",
            colSize: "12",
            icon: <i class="fas fa-exchange-alt"></i>,
            underText: ["Change the value of ", <strong>{props.fieldDisplayName}</strong>],
            label: props.fieldDisplayName,
            as: props.as || "input",
          },

          alert1: {
            content: inputs[props.fieldKey] ? (
              <Alert className="my-3" variant="warning">
                <Alert.Heading>Are you sure?</Alert.Heading>
                <hr />
                {props.as === "textarea"
                  ? [
                      "You are about to change the ",
                      <strong>{props.fieldDisplayName}</strong>,
                      " field. Make sure that the changes are correct!",
                    ]
                  : [
                      "You are about to change the ",
                      <strong>{props.fieldDisplayName}</strong>,
                      " from ",
                      <strong>{props.currentValue}</strong>,
                      " to ",
                      <strong>{inputs[props.fieldKey]}</strong>,
                      ".",
                    ]}
                <br />
                In order to be safe, please type{" "}
                <strong>
                  <code>{confirmationCode}</code>
                </strong>{" "}
                in the input box below for us to confirm your action.
                <br />
                <Form.Group className="mt-4">
                  <Form.Label>
                    <strong>Confirmation</strong>
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <i class="fas fa-check-double"></i>
                      </InputGroup.Text>
                    </InputGroup.Prepend>

                    <Form.Control
                      onChange={(e) => setConfirmationInput(e.target.value)}
                      name="confirmation"
                      type="text"
                      placeholder="Enter..."
                      value={confirmationInput}
                      isInvalid={confirmationInput !== confirmationCode}
                      isValid={confirmationInput === confirmationCode}
                    />
                  </InputGroup>

                  <Form.Text className="text-muted">Enter the Confirmation Code here</Form.Text>
                </Form.Group>
              </Alert>
            ) : (
              ""
            ),
            header: "p",
          },
        }}
      />
    </GeneralModal>
  );
};

export default EditFieldModal;
