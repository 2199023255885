import React, { useState } from "react";
import DashboardPage from "Components/Templates/DashboardPage";
import Core from "Core";
import { useIsAvailable } from "Components/Hooks/ApiHooks";
import FindLoadingComponent from "Components/FindLoadingComponent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import SquarePanel from "Components/Panels/SquarePanel";
import EditFieldModal from "Components/Modals/EditFieldModal";

const IndividualExamSeason = (props) => {
  let { examSeasonId } = props.match.params;

  const [examSeasonReload, setExamSeasonReload] = useState(0);
  const fetchData = (_) => setExamSeasonReload(examSeasonReload + 1);
  const examSeason = useIsAvailable({
    find: Core.examSeasons.find,
    findQueryKey: "id",
    findQueryValue: examSeasonId,
    refreshCounter: examSeasonReload,
  });

  const edit = (data) => {
    return new Promise((resolve, reject) => {
      Core.examSeasons.edit(data).then((res) => {
        if (res?.status.type === "success") fetchData();
        return resolve(res);
      });
    });
  };

  return (
    <DashboardPage layoutFor="examSeasons">
      <FindLoadingComponent modelName="Exam Season" model={examSeason}>
        {typeof examSeason === "object" ? (
          <Row>
            <Col md={7}>
              <h1>{examSeason.name}</h1>

              <hr />

              <Button className="mb-3" size="sm" variant="primary" onClick={fetchData.bind(this)}>
                <>
                  <i className="fas fa-sync pr-1"></i> Reload
                </>
              </Button>
            </Col>
            <Col md={5}></Col>

            <Col md={7}>
              <SquarePanel
                header={
                  <strong>
                    <i className="fas fa-info-circle mr-2"></i> General Information
                  </strong>
                }
              >
                <p>Basic Information regaring the registration of {examSeason.name}.</p>
                <hr />
                <p>
                  <strong>ExamSeason ID: </strong>
                  {examSeason.id}
                </p>
                <p>
                  <strong>ExamSeason Name: </strong>
                  <EditFieldModal
                    fieldKey="name"
                    fieldDisplayName="ExamSeason Name"
                    currentValue={examSeason.year}
                    modelId={examSeason.id}
                    action={edit}
                  />
                  <br />
                  {examSeason.name}
                </p>
                <p>
                  <strong>ExamSeason Details: </strong>
                  <EditFieldModal
                    fieldKey="details"
                    fieldDisplayName="ExamSeason Details"
                    currentValue={examSeason.details}
                    modelId={examSeason.id}
                    action={edit}
                    as="textarea"
                  />
                  <br />
                  {examSeason.details}
                </p>
                <p>
                  <strong>Created At: </strong>
                  {[
                    new Date(examSeason["createdAt"]).toDateString(),
                    " - ",
                    new Date(examSeason["createdAt"]).toLocaleTimeString(),
                  ]}
                </p>
                <p>
                  <strong>Updated At: </strong>
                  {[
                    new Date(examSeason["updatedAt"]).toDateString(),
                    " - ",
                    new Date(examSeason["updatedAt"]).toLocaleTimeString(),
                  ]}
                </p>
              </SquarePanel>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </FindLoadingComponent>
    </DashboardPage>
  );
};

export default IndividualExamSeason;
