import LoadingButton from "Components/Buttons/LoadingButton";
import EditFieldModal from "Components/Modals/EditFieldModal";
import PaginationTable from "Components/PaginationTable";
import DashboardPage from "Components/Templates/DashboardPage";
import Core from "Core";
import Pulse from "pulse-framework";
import React, { useState } from "react";
import Badge from "react-bootstrap/Badge";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const AccountScreen = (props) => {
  Core.auth.hasReceivedUser = false;

  const [revokeIsLoading, setRevokeIsLoading] = useState(false);

  let { user, devices } = props;

  const revokeButton = (a) => {
    let deviceId = a;
    setRevokeIsLoading(deviceId);
    Core.auth.revoke({ deviceId }).then((res) => {
      setRevokeIsLoading(false);
    });
  };

  return (
    <DashboardPage>
      {user?.id ? (
        <>
          <Row>
            <Col md={7}>
              <h1>
                <i class="fas fa-desktop mr-2 text-danger"></i> <strong>Logged In Devices</strong>
              </h1>
              <p>
                {user.firstName}, here you can find a list of all devices that are authorized to
                access your account. You may revoke their access by using the corresponding action
                buttons.
              </p>
              <hr />
            </Col>
            <Col md={5}></Col>
            <Col md={12}>
              <PaginationTable
                prefix="authDevices"
                itemsPerPage={5}
                className="mt-3"
                striped
                thead={
                  <thead>
                    <th className="align-middle">
                      #<br />
                      <small>ID</small>
                    </th>
                    <th className="align-middle">IP Address</th>
                    <th className="align-middle">
                      Device Information
                      <br />
                      <small>OS / Browser</small>
                    </th>
                    <th className="align-middle">
                      Last Seen <br />
                      <small>Date and Time</small>
                    </th>
                    <th className="align-middle">Actions</th>
                  </thead>
                }
                tbody={[
                  ...devices
                    ?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
                    .map((device) => (
                      <tr>
                        <td className="align-middle">{device.id}</td>
                        <td className="align-middle">
                          {device.ipAddress}
                          {Core.auth.currentDeviceId === device.id ? (
                            <>
                              <br />
                              <Badge variant="primary" pill>
                                Current Device
                              </Badge>
                            </>
                          ) : (
                            ""
                          )}
                        </td>
                        <td className="align-middle">
                          {device.os} / {device.browser} ({device.browserVersion})
                        </td>
                        <td className="align-middle">
                          {[
                            new Date(device.updatedAt).toLocaleTimeString(),
                            " ",
                            new Date(device.updatedAt).toDateString(),
                          ]}
                        </td>
                        <td className="align-middle">
                          {Core.auth.currentDeviceId !== device.id ? (
                            <LoadingButton
                              variant="danger"
                              size="sm"
                              text="Revoke"
                              isLoading={revokeIsLoading === device.id}
                              onClick={revokeButton.bind(this, device.id)}
                              disabled={revokeIsLoading && revokeIsLoading !== device.id}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    )),
                ]}
              />
            </Col>

            {/* Personal Data */}
            <Col className="mt-5" md={7}>
              <h1>
                <i class="fas fa-user mr-2 text-primary"></i> <strong>Personal Information</strong>
              </h1>
              <p>
                {user.firstName}, here you can access your personal information, such as First Name,
                email etc.
              </p>
              <hr />

              <div className="mb-5">
                <p>
                  <strong>Email Address: </strong>
                  {user.email}
                </p>
                <p>
                  <strong>First Name: </strong>
                  <EditFieldModal
                    fieldKey="firstName"
                    fieldDisplayName="First Name"
                    currentValue={user.firstName}
                    modelId={user.id}
                    action={Core.auth.edit}
                  />
                  <br />
                  {user.firstName}
                </p>
                <p>
                  <strong>Last Name: </strong>
                  <EditFieldModal
                    fieldKey="lastName"
                    fieldDisplayName="Last Name"
                    currentValue={user.lastName}
                    modelId={user.id}
                    action={Core.auth.edit}
                  />
                  <br />
                  {user.lastName}
                </p>
                <p>
                  <strong>Username: </strong>
                  <EditFieldModal
                    fieldKey="username"
                    fieldDisplayName="Username"
                    currentValue={user.username}
                    modelId={user.id}
                    action={Core.auth.edit}
                  />
                  <br />
                  {user.username}
                </p>
                <p>
                  <strong>Password: </strong>
                  *********
                </p>
              </div>
            </Col>
          </Row>
        </>
      ) : (
        ""
      )}
    </DashboardPage>
  );
};

export default Pulse.React(AccountScreen, (core) => {
  return {
    user: core.auth.authUser[0],
    devices: core.auth.authUserDevices,
  };
});
