import React, { useState, useEffect } from "react";
import DashboardPage from "Components/Templates/DashboardPage";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import LoadingComponent from "Components/LoadingComponent";
import Core from "Core";
import Pulse from "pulse-framework";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import Routes from "Routes";
import PaginationTable from "Components/PaginationTable";

const DocumentTypes = (props) => {
  let { documentTypes } = props;

  const [errorDocumentTypes, setErrorDocumentTypes] = useState(false);
  const [loadingDocumentTypes, setLoadingDocumentTypes] = useState(false);

  const getDocumentTypes = () => {
    setLoadingDocumentTypes(true);
    setErrorDocumentTypes(false);
    Core.documentTypes.getDocumentTypes().then((res) => {
      if (res === "error") setErrorDocumentTypes(true);
      setLoadingDocumentTypes(false);
    });
  };

  const compareAlphabetically = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const bandA = a.name.toUpperCase();
    const bandB = b.name.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  };

  useEffect(() => {
    getDocumentTypes();
  }, []);

  return (
    <DashboardPage layoutFor="documentTypes">
      <Row>
        <Col md={7}>
          <h1>Document Types</h1>
          <p className="text-secondary">
            List of the <strong>Document Types</strong> registered.
          </p>
          <hr />

          <Button
            onClick={getDocumentTypes.bind(this)}
            variant="outline-primary"
            size="sm"
            disabled={loadingDocumentTypes}
          >
            {loadingDocumentTypes ? (
              <Spinner className="mx-3" animation="border" size="sm" />
            ) : (
              <>
                <i className="fas fa-sync pr-1"></i> Reload
              </>
            )}
          </Button>

          {errorDocumentTypes ? (
            <Alert variant="danger" className="my-3">
              <Alert.Heading>Couldn't receive Document Types</Alert.Heading>
              <hr />
              There was an error receiving the Document Types.
            </Alert>
          ) : (
            ""
          )}
          {loadingDocumentTypes ? (
            <LoadingComponent />
          ) : !errorDocumentTypes ? (
            <PaginationTable
              prefix="documentTypes"
              className="mt-3"
              itemsPerPage={5}
              borderless
              striped
              thead={
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Document Type</th>
                    <th>Actions</th>
                  </tr>
                </thead>
              }
              tbody={documentTypes.sort(compareAlphabetically).map((dt, index) => (
                <tr key={index}>
                  <td className="align-middle">{dt.id}</td>
                  <td className="align-middle">{dt.name}</td>
                  <td className="align-middle">
                    <Link
                      to={formatRoute(Routes.documentTypesIndividual, {
                        documentTypeId: dt.id,
                      })}
                    >
                      <Button size="sm" variant="primary" className="rounded-0">
                        <i className="fas fa-eye"></i> View
                      </Button>
                    </Link>
                  </td>
                </tr>
              ))}
            />
          ) : (
            ""
          )}
        </Col>
      </Row>
    </DashboardPage>
  );
};

export default Pulse.React(DocumentTypes, (core) => {
  return {
    documentTypes: core.documentTypes.all,
  };
});
