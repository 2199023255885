import React from "react";
import DashboardPage from "Components/Templates/DashboardPage";
import { useIsAvailable } from "Components/Hooks/ApiHooks";
import Core from "Core";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "react-router-dom/Link";
import { formatRoute } from "react-router-named-routes";
import Routes from "Routes";
import FindLoadingComponent from "Components/FindLoadingComponent";
import AdvancedForm from "Components/Form/AdvancedForm";

const CreateExercise = (props) => {
  let { unitId } = props.match.params;

  const unit = useIsAvailable({
    find: Core.units.find,
    findQueryKey: "id",
    findQueryValue: unitId,
    secondaryFindQueryKey: "for",
    secondaryFindQueryValue: "createExercise",
  });

  return (
    <DashboardPage layoutFor="unitIndividual">
      <FindLoadingComponent modelName="Unit" model={unit}>
        {typeof unit === "object" ? (
          <Row>
            <Col md={7}>
              <h1>
                <strong>{unit.prefix}</strong>
              </h1>
              <h3>{unit.name}</h3>

              <Breadcrumb className="mt-4 mb-4">
                <Breadcrumb.Item>
                  <Link
                    to={formatRoute(Routes.examBoardsIndividual, {
                      examBoardId: unit.ExamBoard.id,
                    })}
                  >
                    {unit.ExamBoard.name}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    to={formatRoute(Routes.qualificationsIndividual, {
                      qualificationId: unit.Subject.Qualification.id,
                    })}
                  >
                    {unit.Subject.Qualification.prefix}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    to={
                      formatRoute(Routes.subjectsIndividual, {
                        subjectId: unit.Subject.id,
                      }) + `?ebId=${unit.ExamBoardId}`
                    }
                  >
                    {unit.Subject.name}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    to={formatRoute(Routes.unitsIndividual, {
                      unitId: unit.id,
                    })}
                  >
                    {unit.prefix}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Create Exercise</Breadcrumb.Item>
              </Breadcrumb>

              <hr />
            </Col>
            <Col md={5}></Col>

            <Col md={7}>
              <h2>
                <strong>Create an Exercise</strong>
              </h2>

              <AdvancedForm
                formName="Exercises"
                values={{ unitId: unit.id }}
                fields={{
                  p: {
                    content: (
                      <span>
                        <strong className="text-primary">Exam Board Name:</strong>{" "}
                        {unit.ExamBoard.name} |{" "}
                        <strong className="text-success">Subject Name:</strong> {unit.Subject.name}{" "}
                        | <strong className="text-info">Qualification:</strong>{" "}
                        {unit.Subject.Qualification.name} ({unit.Subject.Qualification.prefix})
                      </span>
                    ),
                    header: "p",
                    colBp: "md",
                    className: "mt-0",
                    colSize: "12",
                  },
                  title1: {
                    content: "Exam Information",
                    header: "3",
                  },
                  p1: {
                    content: (
                      <span>
                        Enter the Information of the Past Paper you wish to create an Exercise for.
                        Make sure that you have uploaded both the Question Paper and the Mark Scheme
                        for the Past Paper related.
                      </span>
                    ),
                    header: "p",
                    colBp: "md",
                    className: "mt-0",
                    colSize: "12",
                  },
                  examSeriesId: {
                    placeholder: "Select an Exam Series",
                    underText: "Select the Exam Series of the Document",
                    label: "Exam Series",
                    icon: <i className="fas fa-layer-group"></i>,
                    colBp: "md",
                    colSize: "6",
                    as: "select",
                    options: unit.otherData.examSeries.map((examSeries, index) => {
                      return { key: index, value: examSeries.id, text: examSeries.year };
                    }),
                  },
                  examSeasonId: {
                    placeholder: "Select an Exam Season",
                    underText: "Select the Exam Season of the Document",
                    label: "Exam Season",
                    icon: <i className="fas fa-layer-group"></i>,
                    colBp: "md",
                    colSize: "6",
                    as: "select",
                    options: unit.otherData.examSeasons.map((examSeason, index) => {
                      return { key: index, value: examSeason.id, text: examSeason.name };
                    }),
                  },
                  difficultyId: {
                    placeholder: "Select a Difficulty",
                    underText: "Select the Difficulty of the Exercise",
                    label: "Difficulty",
                    icon: <i className="fas fa-layer-group"></i>,
                    colBp: "md",
                    colSize: "6",
                    as: "select",
                    options: unit.otherData.difficulties.map((difficulty, index) => {
                      return { key: index, value: difficulty.id, text: difficulty.name };
                    }),
                  },
                  chapterId: {
                    placeholder: "Select a Chapter",
                    underText: "Select the Chapter associated with the Exercise",
                    label: "Chapter",
                    icon: <i className="fas fa-layer-group"></i>,
                    colBp: "md",
                    colSize: "6",
                    as: "select",
                    options: unit.Chapters.map((chapter, index) => {
                      return { key: index, value: chapter.id, text: chapter.name };
                    }),
                  },
                  p1a: {
                    content: <hr />,
                    header: "p",
                    colBp: "md",
                    className: "mt-0",
                    colSize: "12",
                  },
                  title2: {
                    content: "Question Details",
                    header: "3",
                    className: "mt-2",
                    colBp: "md",
                    colSize: "12",
                  },
                  p2: {
                    content: (
                      <span>
                        Specify the starting <strong>page number</strong> and ending{" "}
                        <strong>page number</strong> of the exercise.
                      </span>
                    ),
                    header: "p",
                    colBp: "md",
                    className: "mt-0",
                    colSize: "12",
                  },
                  qStartingPage: {
                    placeholder: "E.g. 6",
                    underText: "Enter the starting page of the question",
                    label: "Starting Page",
                    icon: <i className="fas fa-keyboard"></i>,
                    type: "number",
                    colBp: "md",
                    colSize: "6",
                  },
                  qEndingPage: {
                    placeholder: "E.g. 9",
                    underText: "Enter the ending page of the question",
                    label: "Ending Page",
                    icon: <i className="fas fa-keyboard"></i>,
                    type: "number",
                    colBp: "md",
                    colSize: "6",
                  },
                  p3: {
                    content: (
                      <span>
                        Specify any additional pages for the start and the end. You may specify as
                        many as you want.
                        <br />
                        <strong>NOTE:</strong> Additional pages must be separated with a comma. E.g.
                        Page 1 and 3 would be entered as 1,3
                      </span>
                    ),
                    header: "p",
                    colBp: "md",
                    className: "mt-0",
                    colSize: "12",
                  },
                  qAddPagesBeg: {
                    placeholder: "E.g. 2,4",
                    underText:
                      "Enter the additional pages to be added to the begining of the question",
                    label: "Additional Pages (Begining)",
                    icon: <i className="fas fa-keyboard"></i>,
                    colBp: "md",
                    colSize: "6",
                  },
                  qAddPagesEnd: {
                    placeholder: "E.g. 11,13",
                    underText: "Enter the additional pages to be added to the end of the question",
                    label: "Additional Pages (End)",
                    icon: <i className="fas fa-keyboard"></i>,
                    colBp: "md",
                    colSize: "6",
                  },
                  p2a: {
                    content: <hr />,
                    header: "p",
                    colBp: "md",
                    className: "mt-0",
                    colSize: "12",
                  },
                  title3: {
                    content: "Answer Details",
                    header: "3",
                    className: "mt-2",
                    colBp: "md",
                    colSize: "12",
                  },
                  p4: {
                    content: (
                      <span>
                        Specify the starting <strong>page number</strong> and ending{" "}
                        <strong>page number</strong> of the exercise.
                      </span>
                    ),
                    header: "p",
                    colBp: "md",
                    className: "mt-0",
                    colSize: "12",
                  },
                  aStartingPage: {
                    placeholder: "E.g. 6",
                    underText: "Enter the starting page of the answer",
                    label: "Starting Page",
                    icon: <i className="fas fa-keyboard"></i>,
                    type: "number",
                    colBp: "md",
                    colSize: "6",
                  },
                  aEndingPage: {
                    placeholder: "E.g. 9",
                    underText: "Enter the ending page of the answer",
                    label: "Ending Page",
                    icon: <i className="fas fa-keyboard"></i>,
                    type: "number",
                    colBp: "md",
                    colSize: "6",
                  },
                  p5: {
                    content: (
                      <span>
                        Specify any additional pages for the start and the end. You may specify as
                        many as you want.
                        <br />
                        <strong>NOTE:</strong> Additional pages must be separated with a comma. E.g.
                        Page 1 and 3 would be entered as 1,3
                      </span>
                    ),
                    header: "p",
                    colBp: "md",
                    className: "mt-0",
                    colSize: "12",
                  },
                  aAddPagesBeg: {
                    placeholder: "E.g. 2,4",
                    underText:
                      "Enter the additional pages to be added to the begining of the answer",
                    label: "Additional Pages (Begining)",
                    icon: <i className="fas fa-keyboard"></i>,
                    colBp: "md",
                    colSize: "6",
                  },
                  aAddPagesEnd: {
                    placeholder: "E.g. 11,13",
                    underText: "Enter the additional pages to be added to the end of the answer",
                    label: "Additional Pages (End)",
                    icon: <i className="fas fa-keyboard"></i>,
                    colBp: "md",
                    colSize: "6",
                  },
                }}
                button={{
                  action: Core.exercises.create,
                }}
              />
            </Col>
          </Row>
        ) : (
          ""
        )}
      </FindLoadingComponent>
    </DashboardPage>
  );
};

export default CreateExercise;
