import React from "react";
import Pulse from "pulse-framework";
import logo from "Assets/img/logo2.svg";
import DashboardPage from "Components/Templates/DashboardPage";

const DashboardHome = (props) => {
  let { user } = props;

  return (
    <DashboardPage layoutFor="home">
      <img src={logo} alt="MyStudyBro Logo" width={100} />
      <h1>Welcome back {user?.firstName},</h1>
      <p>
        We are happy to have you back {user?.firstName} {user?.lastName}{" "}
        <small>({user?.email})</small>!
      </p>
    </DashboardPage>
  );
};

export default Pulse.React(DashboardHome, (core) => {
  return {
    user: core.auth.authUser[0],
    toasts: core.toasts.allToasts,
  };
});
