import React from "react";
import DashboardPage from "Components/Templates/DashboardPage";
import { useIsAvailable } from "Components/Hooks/ApiHooks";
import Core from "Core";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "react-router-dom/Link";
import { formatRoute } from "react-router-named-routes";
import Routes from "Routes";
import AdvancedForm from "Components/Form/AdvancedForm";
import FindLoadingComponent from "Components/FindLoadingComponent";

const CreateChapter = (props) => {
  let { unitId } = props.match.params;

  const unit = useIsAvailable({
    find: Core.units.find,
    findQueryKey: "id",
    findQueryValue: unitId,
  });

  return (
    <DashboardPage layoutFor="unitIndividual">
      <FindLoadingComponent modelName="Unit" model={unit}>
        {typeof unit === "object" ? (
          <Row>
            <Col md={7}>
              <h1>
                <strong>{unit.prefix}</strong>
              </h1>
              <h3>{unit.name}</h3>

              <Breadcrumb className="mt-4 mb-4">
                <Breadcrumb.Item>
                  <Link
                    to={formatRoute(Routes.examBoardsIndividual, {
                      examBoardId: unit.ExamBoard.id,
                    })}
                  >
                    {unit.ExamBoard.name}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    to={formatRoute(Routes.qualificationsIndividual, {
                      qualificationId: unit.Subject.Qualification.id,
                    })}
                  >
                    {unit.Subject.Qualification.prefix}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    to={
                      formatRoute(Routes.subjectsIndividual, {
                        subjectId: unit.Subject.id,
                      }) + `?ebId=${unit.ExamBoardId}`
                    }
                  >
                    {unit.Subject.name}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    to={formatRoute(Routes.unitsIndividual, {
                      unitId: unit.id,
                    })}
                  >
                    {unit.prefix}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Create Chapter</Breadcrumb.Item>
              </Breadcrumb>

              <hr />
            </Col>
            <Col md={5}></Col>

            <Col md={7}>
              <h2>
                <strong>Create Chapter - General Information</strong>
              </h2>
              <p>Enter general information regarding the Chapter you are creating.</p>
              <AdvancedForm
                formName="Chapter"
                values={{ chapterUnitId: unit.id }}
                fields={{
                  p: {
                    content: (
                      <span>
                        <strong className="text-primary">Exam Board Name:</strong>{" "}
                        {unit.ExamBoard.name} |{" "}
                        <strong className="text-success">Subject Name:</strong> {unit.Subject.name}{" "}
                        | <strong className="text-info">Qualification:</strong>{" "}
                        {unit.Subject.Qualification.name} ({unit.Subject.Qualification.prefix})
                      </span>
                    ),
                    header: "p",
                    colBp: "md",
                    className: "mt-0",
                    colSize: "12",
                  },
                  // title1: {
                  //   content: <strong>Create Chapter - General Information</strong>,
                  //   header: "2",
                  // },
                  // p1: {
                  //   content: "Enter general information regarding the Chapter you are creating.",
                  //   header: "p",
                  //   colBp: "md",
                  //   className: "mt-0",
                  //   colSize: "12",
                  // },
                  chapterName: {
                    placeholder: "Enter Name",
                    underText: "Enter a Chapter name that doesn't exist",
                    label: "Chapter Name",
                    icon: <i className="fas fa-chalkboard"></i>,
                    colBp: "md",
                    colSize: "12",
                  },
                  chapterDetails: {
                    placeholder: "Chapter Details",
                    label: ["Details ", <small>(Optional)</small>],
                    icon: <i className="fas fa-info-circle"></i>,
                    colBp: "md",
                    colSize: "12",
                    as: "textarea",
                  },
                }}
                button={{
                  action: Core.chapters.create,
                }}
              />
            </Col>
          </Row>
        ) : (
          ""
        )}
      </FindLoadingComponent>
    </DashboardPage>
  );
};

export default CreateChapter;
