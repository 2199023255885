import React, { useState } from "react";
import DashboardPage from "Components/Templates/DashboardPage";
import { useIsAvailable } from "Components/Hooks/ApiHooks";
import Core from "Core";
import FindLoadingComponent from "Components/FindLoadingComponent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SquarePanel from "Components/Panels/SquarePanel";
import ModelTable from "Components/ModelTable";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import Routes from "Routes";
import PaginationTable from "Components/PaginationTable";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import LoadingButton from "Components/Buttons/LoadingButton";
import EditFieldModal from "Components/Modals/EditFieldModal";

const IndividualUnit = (props) => {
  let { unitId } = props.match.params;

  const [refreshCounter, setRefreshCounter] = useState(1);

  const [loadingTogglePrivacy, setLoadingTogglePrivacy] = useState(false);
  const fetchData = (_) => setRefreshCounter(refreshCounter + 1);
  const unit = useIsAvailable({
    find: Core.units.find,
    findQueryKey: "id",
    findQueryValue: unitId,
    refreshCounter,
  });

  const togglePrivacy = (_) => {
    setLoadingTogglePrivacy(true);
    Core.units.togglePrivacy(unitId).then((res) => {
      setLoadingTogglePrivacy(false);
      if (res !== "error") setRefreshCounter(refreshCounter + 1);
    });
  };

  const compareAlphabetically = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const bandA = a.name.toUpperCase();
    const bandB = b.name.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  };

  const edit = (data) => {
    return new Promise((resolve, reject) => {
      Core.units.edit(data).then((res) => {
        if (res?.status.type === "success") fetchData();
        return resolve(res);
      });
    });
  };

  return (
    <DashboardPage layoutFor="unitIndividual">
      <FindLoadingComponent modelName="Unit" model={unit}>
        {typeof unit === "object" ? (
          <Row>
            <Col md={7}>
              <h1>
                <strong>{unit.prefix}</strong>
              </h1>
              <h3>{unit.name}</h3>

              <Breadcrumb className="mt-4 mb-4">
                <Breadcrumb.Item>
                  <Link
                    to={formatRoute(Routes.examBoardsIndividual, {
                      examBoardId: unit.ExamBoard.id,
                    })}
                  >
                    {unit.ExamBoard.name}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    to={formatRoute(Routes.qualificationsIndividual, {
                      qualificationId: unit.Subject.Qualification.id,
                    })}
                  >
                    {unit.Subject.Qualification.prefix}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    to={
                      formatRoute(Routes.subjectsIndividual, {
                        subjectId: unit.Subject.id,
                      }) + `?ebId=${unit.ExamBoardId}`
                    }
                  >
                    {unit.Subject.name}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{unit.prefix}</Breadcrumb.Item>
              </Breadcrumb>

              <hr />

              <Button className="mb-3" size="sm" variant="primary" onClick={fetchData.bind(this)}>
                <>
                  <i className="fas fa-sync pr-1"></i> Reload
                </>
              </Button>
            </Col>
            <Col md={5}></Col>

            <Col lg={7}>
              <SquarePanel
                header={
                  <strong>
                    <i className="fas fa-info-circle mr-2"></i> General Information
                  </strong>
                }
              >
                <p>Basic Information regaring the registration of {unit.name}.</p>
                <hr />
                <p>
                  <strong>Unit ID: </strong>
                  {unit.id}
                </p>
                <p>
                  <strong>Unit Name: </strong>

                  <EditFieldModal
                    fieldKey="name"
                    fieldDisplayName="Unit Name"
                    currentValue={unit.name}
                    modelId={unit.id}
                    action={edit}
                  />
                  <br />
                  {unit.name}
                </p>
                <p>
                  <strong>Unit Prefix: </strong>

                  <EditFieldModal
                    fieldKey="prefix"
                    fieldDisplayName="Unit Prefix"
                    currentValue={unit.prefix}
                    modelId={unit.id}
                    action={edit}
                  />
                  <br />
                  {unit.prefix}
                </p>
                <p>
                  <strong>Unit Code: </strong>

                  <EditFieldModal
                    fieldKey="code"
                    fieldDisplayName="Unit Code"
                    currentValue={unit.code}
                    modelId={unit.id}
                    action={edit}
                  />
                  <br />
                  {unit.code}
                </p>
                <p>
                  <strong>Unit Details: </strong>
                  <EditFieldModal
                    fieldKey="details"
                    fieldDisplayName="Unit Details"
                    currentValue={unit.details}
                    modelId={unit.id}
                    action={edit}
                    as="textarea"
                  />
                  <br />
                  {unit.details}
                </p>
                <p>
                  <strong>Unit Private: </strong>

                  {unit.private.toString()}
                </p>
                <p>
                  <strong>Created At: </strong>
                  {[
                    new Date(unit["createdAt"]).toDateString(),
                    " - ",
                    new Date(unit["createdAt"]).toLocaleTimeString(),
                  ]}
                </p>
                <p>
                  <strong>Updated At: </strong>
                  {[
                    new Date(unit["updatedAt"]).toDateString(),
                    " - ",
                    new Date(unit["updatedAt"]).toLocaleTimeString(),
                  ]}
                </p>
              </SquarePanel>
            </Col>
            {/* <Col md={5}></Col> */}

            <Col lg={5}>
              <SquarePanel
                headerVariant="primary text-white"
                header={
                  <strong>
                    <i className="fas fa-info-circle mr-2"></i> List of Chapters related to{" "}
                    {unit.prefix}
                  </strong>
                }
              >
                <p>
                  Here you will find a list of chapters related to:{" "}
                  <strong>
                    {unit.name} ({unit.prefix})
                  </strong>
                  .
                </p>

                <hr />

                {unit.Chapters.length > 0 ? (
                  <PaginationTable
                    prefix="chapters"
                    itemsPerPage={5}
                    className="mt-3"
                    borderless
                    striped
                    thead={
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Chapter</th>
                        </tr>
                      </thead>
                    }
                    tbody={unit.Chapters?.sort(compareAlphabetically).map((chapter, index) => (
                      <tr key={index}>
                        <td style={{ width: "4rem" }}>{chapter.id}</td>
                        <td>
                          {
                            <Link
                              to={formatRoute(Routes.chaptersIndividual, { chapterId: chapter.id })}
                            >
                              {chapter.name}
                            </Link>
                          }
                        </td>
                      </tr>
                    ))}
                  />
                ) : (
                  <Alert variant="warning">No chapters found.</Alert>
                )}
              </SquarePanel>

              <SquarePanel
                headerVariant="warning text-dark"
                header={
                  <strong>
                    <i class="fas fa-user-secret mr-2"></i> {unit.prefix} Privacy Settings
                  </strong>
                }
              >
                <p>
                  Here you will toggle some privacy settings related to:{" "}
                  <strong>
                    {unit.name} ({unit.prefix})
                  </strong>
                  , such as whether the unit should be available to the public or not.
                </p>

                <hr />

                <p>
                  Currently, <strong>{unit.prefix}</strong>{" "}
                  {unit.private ? (
                    <>
                      is <strong className="text-danger">not available</strong> to the public.
                    </>
                  ) : (
                    <>
                      is <strong className="text-success">available</strong> to the public.
                    </>
                  )}
                </p>

                <LoadingButton
                  onClick={togglePrivacy.bind(this)}
                  className=" my-0"
                  size="sm"
                  variant={unit.private ? "success" : "danger"}
                  text={
                    unit.private ? (
                      <span>
                        <i class="fas fa-lock-open pr-1"></i> Make available to public
                      </span>
                    ) : (
                      <span>
                        <i class="fas fa-lock pr-1"></i> Disable availability to public
                      </span>
                    )
                  }
                  isLoading={loadingTogglePrivacy}
                />
              </SquarePanel>
            </Col>

            <Col md={12}>
              <SquarePanel
                headerVariant="danger text-white"
                header={
                  <strong>
                    <i className="fas fa-info-circle mr-2"></i> List of Documents related to{" "}
                    {unit.prefix}
                  </strong>
                }
              >
                <p>
                  Here you will find a list of documents related to:{" "}
                  <strong>
                    {unit.name} ({unit.prefix})
                  </strong>
                  .
                </p>

                <hr />

                {unit.Documents.length > 0 ? (
                  <PaginationTable
                    prefix="documents"
                    itemsPerPage={5}
                    className="mt-3"
                    borderless
                    striped
                    thead={
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Exam Season</th>
                          <th>Exam Series</th>
                          <th>Document Type</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                    }
                    tbody={unit.Documents.map((document, index) => (
                      <tr key={index}>
                        <td className="align-middle" style={{ width: "6rem" }}>
                          {document.id}
                        </td>
                        <td className="align-middle">
                          <Link
                            to={formatRoute(Routes.examSeasonsIndividual, {
                              examSeasonId: document.ExamSeasonId,
                            })}
                          >
                            {document.ExamSeason.name}
                          </Link>
                        </td>
                        <td className="align-middle">
                          <Link
                            to={formatRoute(Routes.examSeriesIndividual, {
                              examSeriesId: document.ExamSery.id,
                            })}
                          >
                            {document.ExamSery.year}
                          </Link>
                        </td>
                        <td className="align-middle">
                          <Link
                            to={formatRoute(Routes.documentTypesIndividual, {
                              documentTypeId: document.DocumentType.id,
                            })}
                          >
                            {document.DocumentType.name}
                          </Link>
                        </td>
                        <td className="align-middle">
                          <Link
                            to={formatRoute(Routes.documentIndividual, { documentId: document.id })}
                          >
                            <Button size="sm" variant="primary" className="rounded-0 mr-1">
                              <i className="fas fa-eye"></i>
                            </Button>
                          </Link>

                          <form
                            action={Core.documents.generateDownloadLink(document, true)}
                            target="_blank"
                            method="post"
                            rel="noopener noreferrer"
                            className="form-check-inline mr-0"
                          >
                            <input
                              hidden
                              name="authorization"
                              value={Core.request.headers["Authorization"]}
                            />
                            <Button
                              type="submit"
                              size="sm"
                              variant="success"
                              className="rounded-0 mr-1"
                            >
                              <i className="fas fa-download"></i>
                            </Button>
                          </form>
                          <form
                            action={Core.documents.generateDownloadLink(document, false)}
                            target="_blank"
                            method="post"
                            rel="noopener noreferrer"
                            className="form-check-inline mr-0"
                          >
                            <input
                              hidden
                              name="authorization"
                              value={Core.request.headers["Authorization"]}
                            />
                            <Button
                              type="submit"
                              size="sm"
                              variant="danger"
                              className="rounded-0 mr-0"
                            >
                              <i className="fas fa-file-pdf"></i>
                            </Button>
                          </form>
                        </td>
                      </tr>
                    ))}
                  />
                ) : (
                  <Alert variant="warning">No documents found.</Alert>
                )}
              </SquarePanel>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </FindLoadingComponent>
    </DashboardPage>
  );
};

export default IndividualUnit;
