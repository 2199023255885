import React, { useState } from "react";
import DashboardPage from "Components/Templates/DashboardPage";
import { useIsAvailable } from "Components/Hooks/ApiHooks";
import Core from "Core";
import FindLoadingComponent from "Components/FindLoadingComponent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { formatRoute } from "react-router-named-routes";
import Routes from "Routes";
import Link from "react-router-dom/Link";
import Button from "react-bootstrap/Button";
import PaginationTable from "Components/PaginationTable";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import LoadingButton from "Components/Buttons/LoadingButton";
import pulse from "pulse-framework";
import Alert from "react-bootstrap/Alert";

const IndividualDocument = (props) => {
  const [newExercises, setNewExercises] = useState({});
  const [newExerciseCount, setNewExerciseCount] = useState(1);

  const [createExerciseLoading, setCreateExerciseLoading] = useState([]);

  const [createErrors, setCreateErrors] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [validationErrorMessageContent, setValidationErrorMessageContent] = useState({});
  const [success, setSuccess] = useState({});

  const [tempExerciseIdCounter, setTempExerciseIdCounter] = useState(1);

  let { documentId } = props.match.params;

  const document = useIsAvailable({
    find: Core.documents.find,
    findQueryKey: "id",
    findQueryValue: documentId,
    secondaryFindQueryKey: "for",
    secondaryFindQueryValue: "createExercises",
  });

  const { documentExercises } = props;

  const addNewExercise = (_) => {
    setNewExercises({
      ...newExercises,
      [newExerciseCount]: {
        unitId: document.Unit?.id,
        newExercise: newExerciseCount,
        tempId: `#n${tempExerciseIdCounter}`,
      },
    });
    setNewExerciseCount(newExerciseCount + 1);
    setTempExerciseIdCounter(tempExerciseIdCounter + 1);
  };

  const newExerciseDelete = (newExerciseIndex) => {
    let newNewExercises = { ...newExercises };
    delete newNewExercises[newExerciseIndex];
    setNewExercises(newNewExercises);
  };

  const newExerciseDuplicate = (newExerciseIndex) => {
    let thisExercise = newExercises[newExerciseIndex];

    let newExercisesIndexes = Object.keys(newExercises).sort();

    let indexOfThisExercise = newExercisesIndexes.indexOf(`${newExerciseIndex}`);
    let indexOfNextExercise = Number.parseFloat(indexOfThisExercise) + 1;

    let nextExercise = newExercises[newExercisesIndexes[indexOfNextExercise]];

    if (nextExercise) {
      let newIndex =
        (parseFloat(thisExercise.newExercise) + parseFloat(nextExercise.newExercise)) / 2;

      setNewExercises({
        ...newExercises,
        [newIndex]: {
          ...thisExercise,
          newExercise: newIndex,
          tempId: `#n${tempExerciseIdCounter}`,
        },
      });
    } else {
      setNewExercises({
        ...newExercises,
        [newExerciseCount + 1]: {
          ...thisExercise,
          newExercise: newExerciseCount + 1,
          tempId: `#n${tempExerciseIdCounter}`,
        },
      });
    }

    setNewExerciseCount(newExerciseCount + 1);
    setTempExerciseIdCounter(tempExerciseIdCounter + 1);
  };

  const onChangeNewExerciseField = (e, newExerciseIndex) => {
    let { name, value } = e.target;
    let newNewExercises = { ...newExercises };
    newNewExercises[newExerciseIndex][name] = value;
    setNewExercises(newNewExercises);
  };

  const createExercise = (exerciseIndex) => {
    let newExercise = newExercises[exerciseIndex];
    setCreateExerciseLoading([...createExerciseLoading, exerciseIndex]);

    let aSuccess = success;
    let aValidationErrors = validationErrors;
    let aValidationErrorMessageContent = validationErrorMessageContent;
    let aCreateErrors = createErrors;

    delete aSuccess[exerciseIndex];
    delete aValidationErrors[exerciseIndex];
    delete aValidationErrorMessageContent[exerciseIndex];
    delete aCreateErrors[exerciseIndex];

    setSuccess(aSuccess);
    setValidationErrors(aValidationErrors);
    setValidationErrorMessageContent(aValidationErrorMessageContent);
    setCreateErrors(aCreateErrors);

    Core.exercises
      .create({
        ...newExercise,
        examSeriesId: document.ExamSeriesId,
        examSeasonId: document.ExamSeasonId,
      })
      .then((res) => {
        if (res.status?.type === "error" && !res.error?.validationFailed) {
          let errorMessage = res.error?.message || "Unknown Error";
          setCreateErrors({ ...createErrors, [exerciseIndex]: errorMessage });
        }

        if (
          res.status.type === "error" &&
          res.error?.validationFailed &&
          res.error?.validationErrors.length > 0
        ) {
          let newValidationErrors = {};
          let newValidationErrorMessageContent = [];

          res.error.validationErrors.forEach((ve, index) => {
            newValidationErrors[ve.fieldName] = true;
            newValidationErrorMessageContent.push(ve.message);
            if (index === res.error.validationErrors.length - 1) {
              let a = { ...validationErrors, [exerciseIndex]: { ...newValidationErrors } };
              setValidationErrors(a);
              setValidationErrorMessageContent({
                ...validationErrorMessageContent,
                [exerciseIndex]: [...newValidationErrorMessageContent],
              });
            }
          });
        }
        if (res?.status?.type === "success") {
          setSuccess({
            ...success,
            [exerciseIndex]: {
              message: `Successfully Created or Modified exercise: ${res.exercise.id}`,
              id: res.exercise.id,
            },
          });

          let newNewExercises = newExercises;
          delete newNewExercises[exerciseIndex];
          setNewExercises(newNewExercises);
        }

        let b = [...createExerciseLoading];
        b.splice(b.indexOf(exerciseIndex), 1);
        setCreateExerciseLoading(b);
      });
  };

  // eslint-disable-next-line no-unused-vars
  const formatChapter = (chapters) => {
    let chaptersString = "";

    if (chapters.length === 1) {
      chaptersString = chapters[0].name;
      return chaptersString;
    }

    let lastChapter = chapters[chapters.length - 1];
    let firstChapters = chapters.splice(0, chapters.length - 1);

    firstChapters.forEach((chapter, index) => {
      if (index === 0) {
        chaptersString = chapter.name;
      } else {
        chaptersString = `${chaptersString}, ${chapter.name}`;
      }
    });

    chaptersString = `${chaptersString} and ${lastChapter.name}`;
    return chaptersString;
  };

  const generateRow = (exercise) => {
    if (!exercise.deleted)
      return (
        <tr key={exercise.id}>
          <td className="align-middle">
            {exercise.newExercise ? <strong>{exercise.tempId}</strong> : exercise.id}
          </td>
          <td className="align-middle">
            {exercise.newExercise ? (
              <Form.Control
                size="sm"
                value={newExercises[exercise.newExercise].qStartingPage}
                name="qStartingPage"
                onChange={(e) => onChangeNewExerciseField(e, exercise.newExercise)}
                style={{ width: "2.5rem" }}
                disabled={createExerciseLoading.indexOf(exercise.newExercise) > -1}
                className={
                  validationErrors[exercise.newExercise]?.qStartingPage
                    ? "border border-danger"
                    : ""
                }
              />
            ) : (
              exercise.questionPages?.split(",")[0]
            )}
          </td>
          <td className="align-middle">
            {exercise.newExercise ? (
              <Form.Control
                size="sm"
                value={newExercises[exercise.newExercise].qEndingPage}
                name="qEndingPage"
                onChange={(e) => onChangeNewExerciseField(e, exercise.newExercise)}
                style={{ width: "2.5rem" }}
                disabled={createExerciseLoading.indexOf(exercise.newExercise) > -1}
                className={
                  validationErrors[exercise.newExercise]?.qEndingPage ? "border border-danger" : ""
                }
              />
            ) : (
              exercise.questionPages?.split(",")[1]
            )}
          </td>
          <td className="align-middle">
            {exercise.newExercise ? (
              <Form.Control
                size="sm"
                value={newExercises[exercise.newExercise].qAddPagesBeg}
                name="qAddPagesBeg"
                onChange={(e) => onChangeNewExerciseField(e, exercise.newExercise)}
                style={{ width: "2.5rem" }}
                disabled={createExerciseLoading.indexOf(exercise.newExercise) > -1}
                className={
                  validationErrors[exercise.newExercise]?.qAddPagesBeg ? "border border-danger" : ""
                }
              />
            ) : (
              exercise.questionAdditionalPages?.split("|")[0]
            )}
          </td>
          <td className="align-middle">
            {exercise.newExercise ? (
              <Form.Control
                size="sm"
                value={newExercises[exercise.newExercise].qAddPagesEnd}
                name="qAddPagesEnd"
                onChange={(e) => onChangeNewExerciseField(e, exercise.newExercise)}
                style={{ width: "2.5rem" }}
                disabled={createExerciseLoading.indexOf(exercise.newExercise) > -1}
                className={
                  validationErrors[exercise.newExercise]?.qAddPagesEnd ? "border border-danger" : ""
                }
              />
            ) : (
              exercise.questionPages?.split("|")[1]
            )}
          </td>

          <td className="align-middle">
            {exercise.newExercise ? (
              <Form.Control
                size="sm"
                value={newExercises[exercise.newExercise].aStartingPage}
                name="aStartingPage"
                onChange={(e) => onChangeNewExerciseField(e, exercise.newExercise)}
                style={{ width: "2.5rem" }}
                disabled={createExerciseLoading.indexOf(exercise.newExercise) > -1}
                className={
                  validationErrors[exercise.newExercise]?.aStartingPage
                    ? "border border-danger"
                    : ""
                }
              />
            ) : (
              exercise.answerPages?.split(",")[0]
            )}
          </td>
          <td className="align-middle">
            {exercise.newExercise ? (
              <Form.Control
                size="sm"
                value={newExercises[exercise.newExercise].aEndingPage}
                name="aEndingPage"
                onChange={(e) => onChangeNewExerciseField(e, exercise.newExercise)}
                style={{ width: "2.5rem" }}
                disabled={createExerciseLoading.indexOf(exercise.newExercise) > -1}
                className={
                  validationErrors[exercise.newExercise]?.aEndingPage ? "border border-danger" : ""
                }
              />
            ) : (
              exercise.answerPages?.split(",")[1]
            )}
          </td>
          <td className="align-middle">
            {exercise.newExercise ? (
              <Form.Control
                size="sm"
                value={newExercises[exercise.newExercise].aAddPagesBeg}
                name="aAddPagesBeg"
                onChange={(e) => onChangeNewExerciseField(e, exercise.newExercise)}
                style={{ width: "2.5rem" }}
                disabled={createExerciseLoading.indexOf(exercise.newExercise) > -1}
                className={
                  validationErrors[exercise.newExercise]?.aAddPagesBeg ? "border border-danger" : ""
                }
              />
            ) : (
              exercise.answerAdditionalPages?.split("|")[0]
            )}
          </td>
          <td className="align-middle">
            {exercise.newExercise ? (
              <Form.Control
                size="sm"
                value={newExercises[exercise.newExercise].aAddPagesEnd}
                name="aAddPagesEnd"
                onChange={(e) => onChangeNewExerciseField(e, exercise.newExercise)}
                style={{ width: "2.5rem" }}
                disabled={createExerciseLoading.indexOf(exercise.newExercise) > -1}
                className={
                  validationErrors[exercise.newExercise]?.aAddPagesEnd ? "border border-danger" : ""
                }
              />
            ) : (
              exercise.answerAdditionalPages?.split("|")[1]
            )}
          </td>

          <td className="align-middle">
            {/* {formatChapter(exercise.Chapters)} */}
            {exercise.Chapters?.length > 0 ? (
              exercise.Chapters?.map((chapter) => (
                <Badge className="mr-1" variant="primary">
                  {chapter.name}
                </Badge>
              ))
            ) : (
              // <select>
              <Form.Control
                name="chapterId"
                onChange={(e) => onChangeNewExerciseField(e, exercise.newExercise)}
                size="sm"
                as="select"
                disabled={createExerciseLoading.indexOf(exercise.newExercise) > -1}
                className={
                  validationErrors[exercise.newExercise]?.chapterId ? "border border-danger" : ""
                }
              >
                <option value={0}>Select a Chapter</option>
                {document.Unit?.Chapters?.map((chapter, index) => (
                  <option
                    selected={parseInt(newExercises[exercise.newExercise].chapterId) === chapter.id}
                    key={index}
                    value={chapter.id}
                  >
                    {chapter.name}
                  </option>
                ))}
              </Form.Control>
              //  </select>
            )}
          </td>
          <td className="align-middle">
            {
              exercise.Difficulty?.name || (
                // <select>
                <Form.Control
                  name="difficultyId"
                  onChange={(e) => onChangeNewExerciseField(e, exercise.newExercise)}
                  size="sm"
                  as="select"
                  disabled={createExerciseLoading.indexOf(exercise.newExercise) > -1}
                  className={
                    validationErrors[exercise.newExercise]?.difficultyId
                      ? "border border-danger"
                      : ""
                  }
                >
                  <option value={0}>Select a Difficulty</option>
                  {document.newExerciseDifficulties?.map((difficulty, index) => (
                    <option
                      selected={
                        parseInt(newExercises[exercise.newExercise].difficultyId) === difficulty.id
                      }
                      key={index}
                      value={difficulty.id}
                    >
                      {difficulty.name}
                    </option>
                  ))}
                </Form.Control>
              )
              //  </select>
            }
          </td>
          <td style={{ width: "10rem !important" }}>
            {exercise.newExercise ? (
              <>
                <ButtonGroup>
                  <Button
                    disabled={createExerciseLoading.indexOf(exercise.newExercise) > -1}
                    onClick={(e) => newExerciseDelete(exercise.newExercise)}
                    size="sm"
                    variant="danger"
                    className="rounded-0 mr-1"
                  >
                    <i className="fas fa-trash"></i>
                  </Button>
                  <Button
                    disabled={createExerciseLoading.indexOf(exercise.newExercise) > -1}
                    onClick={(e) => newExerciseDuplicate(exercise.newExercise)}
                    size="sm"
                    variant="primary"
                    className="rounded-0 mr-1"
                  >
                    <i className="fas fa-copy"></i>
                  </Button>

                  <LoadingButton
                    text={<i className="fas fa-check"></i>}
                    variant="success"
                    className="rounded-0"
                    size="sm"
                    // disabled={
                    //   createExerciseLoading !== exercise.newExercise && createExerciseLoading
                    // }
                    onClick={(e) => createExercise(exercise.newExercise)}
                    isLoading={createExerciseLoading.indexOf(exercise.newExercise) > -1}
                  />
                </ButtonGroup>
              </>
            ) : (
              <>
                <ButtonGroup>
                  <Link to={formatRoute(Routes.exerciseIndividual, { exerciseId: exercise.id })}>
                    <Button size="sm" variant="primary" className="rounded-0 mr-1">
                      <i className="fas fa-eye"></i>
                    </Button>
                  </Link>

                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={Core.exercises.generateExerciseLink(exercise, false)}
                  >
                    <Button size="sm" variant="danger" className="rounded-0 mr-1">
                      <i className="fas fa-file-pdf"></i>
                    </Button>
                  </a>

                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={Core.exercises.generateExerciseLink(exercise, true)}
                  >
                    <Button size="sm" variant="success" className="rounded-0">
                      <i className="fas fa-download"></i>
                    </Button>
                  </a>
                </ButtonGroup>
              </>
            )}
          </td>
        </tr>
      );
  };

  return (
    <DashboardPage>
      <FindLoadingComponent modelName="Document" model={document}>
        {typeof document === "object" ? (
          <>
            <Row className="mb-5">
              <Col md={7}>
                <h1>
                  <strong>{document.DocumentType.name}</strong>{" "}
                  <small>
                    <Badge variant="secondary">{document.id}</Badge>
                  </small>
                </h1>
                <h5>
                  <strong>{document.Unit.prefix}</strong>: {document.Unit.name}
                </h5>

                <Breadcrumb className="mt-4 mb-4">
                  <Breadcrumb.Item>
                    <Link
                      to={formatRoute(Routes.examBoardsIndividual, {
                        examBoardId: document.Unit.ExamBoard.id,
                      })}
                    >
                      {document.Unit.ExamBoard.name}
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link
                      to={formatRoute(Routes.qualificationsIndividual, {
                        qualificationId: document.Unit.Subject.Qualification.id,
                      })}
                    >
                      {document.Unit.Subject.Qualification.prefix}
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link
                      to={
                        formatRoute(Routes.subjectsIndividual, {
                          subjectId: document.Unit.Subject.id,
                        }) + `?ebId=${document.Unit.ExamBoardId}`
                      }
                    >
                      {document.Unit.Subject.name}
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link
                      to={formatRoute(Routes.unitsIndividual, {
                        unitId: document.Unit.id,
                      })}
                    >
                      {document.Unit.prefix}
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Document {document.id}</Breadcrumb.Item>
                </Breadcrumb>

                <Row>
                  <Col xs={6}>
                    <form
                      framename="asd"
                      action={Core.documents.generateDownloadLink(document, true)}
                      target="_blank"
                      method="post"
                      rel="noopener noreferrer"
                    >
                      <input
                        hidden
                        name="authorization"
                        value={Core.request.headers["Authorization"]}
                      />
                      <Button type="submit" block variant="success" className="rounded-0 mr-1">
                        <i className="fas fa-download mr-1"></i> Download
                      </Button>
                    </form>
                  </Col>
                  <Col xs={6}>
                    <form
                      framename="asd"
                      action={Core.documents.generateDownloadLink(document)}
                      target="_blank"
                      method="post"
                      rel="noopener noreferrer"
                    >
                      <input
                        hidden
                        name="authorization"
                        value={Core.request.headers["Authorization"]}
                      />
                      <Button type="submit" block variant="danger" className="rounded-0 mr-1">
                        <i className="fas fa-file-pdf"></i> View in PDF
                      </Button>
                    </form>
                  </Col>
                </Row>

                <hr />

                {Object.keys(validationErrorMessageContent).length > 0
                  ? Object.keys(validationErrorMessageContent).map((vemc) => {
                      let messages = validationErrorMessageContent[vemc].map((message) => {
                        return <li>{message}</li>;
                      });
                      return (
                        <Alert variant="danger">
                          <Alert.Heading>
                            Entered information is invalid.{" "}
                            <small>(Exercise: {newExercises[vemc]?.tempId})</small>
                          </Alert.Heading>
                          <hr />
                          <ul>{messages}</ul>
                        </Alert>
                      );
                    })
                  : ""}

                {Object.keys(createErrors).length > 0
                  ? Object.keys(createErrors).map((ce) => (
                      <Alert variant="danger">
                        <Alert.Heading>
                          Error Creating Exercise{" "}
                          <small>(Exercise: {newExercises[ce]?.tempId})</small>
                        </Alert.Heading>
                        <hr />
                        {createErrors[ce]}
                      </Alert>
                    ))
                  : ""}

                {Object.keys(success).length > 0
                  ? Object.keys(success).map((ce) => {
                      return (
                        <Alert variant="success">
                          <Alert.Heading>
                            Created Exercise <small>(Exercise: {success[ce]?.id})</small>
                          </Alert.Heading>
                          <hr />
                          {success[ce].message}
                        </Alert>
                      );
                    })
                  : ""}
              </Col>
              <Col md={5}></Col>

              <Col md={12}>
                <PaginationTable
                  prefix="exercises"
                  itemsPerPage={10}
                  className="mt-3"
                  striped
                  thead={
                    <thead>
                      <th>#</th>
                      <th>
                        <abbr title="Question Starting Page">QSP</abbr>
                      </th>
                      <th>
                        <abbr title="Question Ending Page">QEP</abbr>
                      </th>
                      <th>
                        <abbr title="Additional Question Pages (Begining)">QSaP</abbr>
                      </th>
                      <th>
                        <abbr title="Additional Question Pages (End)">QSeP</abbr>
                      </th>

                      <th>
                        <abbr title="Answer Starting Page">ASP</abbr>
                      </th>
                      <th>
                        <abbr title="Answer Ending Page">AEP</abbr>
                      </th>
                      <th>
                        <abbr title="Additional Answer Pages (Begining)">ASaP</abbr>
                      </th>
                      <th>
                        <abbr title="Additional Answer Pages (End)">AEaP</abbr>
                      </th>

                      <th>Chapter</th>
                      <th>Difficulty</th>

                      <th />
                    </thead>
                  }
                  tbody={[
                    ...documentExercises
                      .sort((a, b) => parseFloat(a.questionPages) - parseFloat(b.questionPages))
                      .map((exercise) => generateRow(exercise)),
                    ...Object.keys(newExercises)
                      .sort()
                      .map((exercise) => {
                        exercise = newExercises[exercise];
                        return generateRow(exercise);
                      }),
                  ]}
                  tfoot
                />

                <Button
                  onClick={addNewExercise.bind(this)}
                  className="rounded-0"
                  variant="success"
                  size="sm"
                >
                  <i className="fas fa-plus mr-1"></i> Add New Exercise
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}
      </FindLoadingComponent>
    </DashboardPage>
  );
};

export default pulse.React(IndividualDocument, (core) => {
  return {
    documentExercises: core.exercises.documentExercises,
  };
});
