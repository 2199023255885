export default {
  groups: ["all"],
  data: {},
  persist: [],
  routes: {
    // Receive all difficulties
    list: (request) => request.get("difficulties"),
    // Create difficulties : post
    create: (request, data) => request.post("difficulties", data),
    // Get individual difficulty
    find: (request, query) => request.get("difficulties/get" + query),
    // Edit Data
    editData: (request, data) => request.post("difficulties/edit", data),
  },
  actions: {
    // Receive all difficulties
    getDifficulties: ({ routes, difficulties, base }) => {
      return routes
        .list()
        .then((res) => {
          difficulties.deleteGroup("all");
          difficulties.collect(res.difficulties, "all");
          return res;
        })
        .catch((e) => base.handleError(e));
    },

    // Create a difficulty
    create: ({ routes }, data) => {
      return routes
        .create(data)
        .then((res) => {
          return res;
        })
        .catch((e) => e);
    },

    // Find a Difficulties
    find: ({ routes, base }, data) => {
      let queryParams = base.makeQueryParams(data);
      return routes
        .find(queryParams)
        .then((res) => {
          return { status: res.status, result: res.difficulty };
        })
        .catch((e) => e);
    },

    // edit fields
    edit: ({ routes }, data) => {
      return routes
        .editData(data)
        .then((res) => {
          return res;
        })
        .catch((e) => e);
    },
  },
};
