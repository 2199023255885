import React, { useState } from "react";
import DashboardPage from "Components/Templates/DashboardPage";
import Core from "Core";
import { useIsAvailable } from "Components/Hooks/ApiHooks";
import FindLoadingComponent from "Components/FindLoadingComponent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ModelTable from "Components/ModelTable";
import SquarePanel from "Components/Panels/SquarePanel";
import EditFieldModal from "Components/Modals/EditFieldModal";
import Button from "react-bootstrap/Button";

const IndividualDifficulty = (props) => {
  let { difficultyId } = props.match.params;

  const [difficultiesReload, setDifficultiesReload] = useState(0);
  const fetchData = (_) => setDifficultiesReload(difficultiesReload + 1);
  const difficulty = useIsAvailable({
    find: Core.difficulties.find,
    findQueryKey: "id",
    findQueryValue: difficultyId,
    refreshCounter: difficultiesReload,
  });

  const edit = (data) => {
    return new Promise((resolve, reject) => {
      Core.difficulties.edit(data).then((res) => {
        if (res?.status.type === "success") fetchData();
        return resolve(res);
      });
    });
  };

  return (
    <DashboardPage layoutFor="difficulties">
      <FindLoadingComponent modelName="Difficulty" model={difficulty}>
        {typeof difficulty === "object" ? (
          <Row>
            <Col md={7}>
              <h1>{difficulty.name}</h1>

              <hr />

              <Button className="mb-3" size="sm" variant="primary" onClick={fetchData.bind(this)}>
                <>
                  <i className="fas fa-sync pr-1"></i> Reload
                </>
              </Button>
            </Col>
            {/* <Col md={7}>
              <h3>General Information</h3>
              <p>Enter general information regarding the difficulty you are viewing.</p>

              <ModelTable model={difficulty} />
            </Col> */}

            <Col md={5}></Col>

            <Col md={7}>
              <SquarePanel
                header={
                  <strong>
                    <i className="fas fa-info-circle mr-2"></i> General Information
                  </strong>
                }
              >
                <p>Basic Information regaring the registration of {difficulty.name}.</p>
                <hr />
                <p>
                  <strong>Difficulty ID: </strong>
                  {difficulty.id}
                </p>
                <p>
                  <strong>Difficulty Name: </strong>
                  <EditFieldModal
                    fieldKey="name"
                    fieldDisplayName="Difficulty Name"
                    currentValue={difficulty.name}
                    modelId={difficulty.id}
                    action={edit}
                  />
                  <br />
                  {difficulty.name}
                </p>
                <p>
                  <strong>Difficulty Details: </strong>
                  <EditFieldModal
                    fieldKey="details"
                    fieldDisplayName="Difficulty Details"
                    currentValue={difficulty.details}
                    modelId={difficulty.id}
                    action={edit}
                    as="textarea"
                  />
                  <br />
                  {difficulty.details}
                </p>
                <p>
                  <strong>Difficulty Level: </strong>
                  <EditFieldModal
                    fieldKey="difficultyLevel"
                    fieldDisplayName="Difficulty Level"
                    currentValue={difficulty.difficultyLevel}
                    modelId={difficulty.id}
                    action={edit}
                  />
                  <br />
                  {difficulty.difficultyLevel}
                </p>
                <p>
                  <strong>Created At: </strong>
                  {[
                    new Date(difficulty["createdAt"]).toDateString(),
                    " - ",
                    new Date(difficulty["createdAt"]).toLocaleTimeString(),
                  ]}
                </p>
                <p>
                  <strong>Updated At: </strong>
                  {[
                    new Date(difficulty["updatedAt"]).toDateString(),
                    " - ",
                    new Date(difficulty["updatedAt"]).toLocaleTimeString(),
                  ]}
                </p>
              </SquarePanel>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </FindLoadingComponent>
    </DashboardPage>
  );
};

export default IndividualDifficulty;
