import React from "react";

import core from "Core";
import routes from "Routes";
import { Route, Redirect } from "react-router-dom";

export const AuthRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (core.auth.isAuthenticated) {
          return <Component {...props} />;
        } else {
          return <Redirect to={routes.login} />;
        }
      }}
    />
  );
};

export const GuestRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!core.auth.isAuthenticated) {
          return <Component {...props} />;
        } else {
          return <Redirect to={routes.dashboard} />;
        }
      }}
    />
  );
};
