import React, { useState, useEffect } from "react";
import DashboardPage from "Components/Templates/DashboardPage";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import LoadingComponent from "Components/LoadingComponent";

import Core from "Core";
import Pulse from "pulse-framework";
import Spinner from "react-bootstrap/Spinner";

import { Link } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import Routes from "Routes";
import PaginationTable from "Components/PaginationTable";

const Difficulties = (props) => {
  let { difficulties } = props;

  const [errorDifficulties, setErrorDifficulties] = useState(false);
  const [loadingDifficulties, setLoadingDifficulties] = useState(false);

  const getDifficulties = () => {
    setLoadingDifficulties(true);
    setErrorDifficulties(false);
    Core.difficulties.getDifficulties().then((res) => {
      if (res === "error") setErrorDifficulties(true);
      setLoadingDifficulties(false);
    });
  };

  const compareAlphabetically = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const bandA = a.difficultyLevel;
    const bandB = b.difficultyLevel;

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  };

  useEffect(() => {
    getDifficulties();
  }, []);

  return (
    <DashboardPage layoutFor="difficulties">
      <Row>
        <Col md={7}>
          <h1>Difficulties</h1>
          <p className="text-secondary">
            List of the <strong>Difficulties</strong> registered.
          </p>
          <hr />

          <Button
            onClick={getDifficulties.bind(this)}
            variant="outline-primary"
            size="sm"
            disabled={loadingDifficulties}
          >
            {loadingDifficulties ? (
              <Spinner className="mx-3" animation="border" size="sm" />
            ) : (
              <>
                <i className="fas fa-sync pr-1"></i> Reload
              </>
            )}
          </Button>

          {errorDifficulties ? (
            <Alert variant="danger" className="my-3">
              <Alert.Heading>Couldn't receive Difficulties</Alert.Heading>
              <hr />
              There was an error receiving the Difficulties.
            </Alert>
          ) : (
            ""
          )}
          {loadingDifficulties ? (
            <LoadingComponent />
          ) : !errorDifficulties ? (
            <PaginationTable
              prefix="difficulties"
              className="mt-3"
              itemsPerPage={5}
              borderless
              striped
              thead={
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Difficulty</th>
                    <th>Actions</th>
                  </tr>
                </thead>
              }
              tbody={difficulties.sort(compareAlphabetically).map((d, index) => (
                <tr key={index}>
                  <td className="align-middle">{d.id}</td>
                  <td className="align-middle">{d.name}</td>
                  <td className="align-middle">
                    <Link
                      to={formatRoute(Routes.difficultiesIndividual, {
                        difficultyId: d.id,
                      })}
                    >
                      <Button size="sm" variant="primary" className="rounded-0">
                        <i className="fas fa-eye"></i> View
                      </Button>
                    </Link>
                  </td>
                </tr>
              ))}
            />
          ) : (
            ""
          )}
        </Col>
      </Row>
    </DashboardPage>
  );
};

export default Pulse.React(Difficulties, (core) => {
  return {
    difficulties: core.difficulties.all,
  };
});
