export default {
  index: "/",

  // Add any other route names
  examBoards: "/examBoards",
  examBoardsCreate: "/examBoards/create",
  examBoardsIndividual: "/examBoards/:examBoardId",

  qualifications: "/qualifications",
  qualificationsCreate: "/qualifications/create",
  qualificationsIndividual: "/qualifications/:qualificationId",

  subjects: "/subjects",
  subjectsCreate: "/subjects/create",
  subjectsIndividual: "/subjects/:subjectId",

  examSeasons: "/examSeasons",
  examSeasonsCreate: "/examSeasons/create",
  examSeasonsIndividual: "/examSeasons/:examSeasonId",

  examSeries: "/examSeries",
  examSeriesCreate: "/examSeries/create",
  examSeriesIndividual: "/examSeries/:examSeriesId",

  documentTypes: "/documentTypes",
  documentTypesCreate: "/documentTypes/create",
  documentTypesIndividual: "/documentTypes/:documentTypeId",

  difficulties: "/difficulties",
  difficultiesCreate: "/difficulties/create",
  difficultiesIndividual: "/difficulties/:difficultyId",

  unitsIndividual: "/units/:unitId",
  chapterCreate: "/units/:unitId/createChapter",
  exerciseCreate: "/units/:unitId/createExercise",
  unitDocumentUpload: "/units/:unitId/uploadDocument",

  chaptersIndividual: "/chapters/:chapterId",

  documentIndividual: "/documents/:documentId",

  exerciseIndividual: "/exercises/:exerciseId",

  accountScreen: "/me",

  loggerScreen: "/logger",

  // Authentication Routes
  login: "/auth/login",
};
