import React from "react";
import LoadingComponent from "./LoadingComponent";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default (props) => {
  let { modelName, model, children } = props;

  console.log(model);

  return (
    <>
      {model === false ? <LoadingComponent variant="info" /> : ""}

      {typeof model === "undefined" ? (
        <Row>
          <Col md={7}>
            <Alert variant="danger">
              <Alert.Heading>Couldn't find {modelName}</Alert.Heading>
              <hr />
              We were unable to find the {modelName} you are looking for.
            </Alert>
          </Col>
        </Row>
      ) : (
        ""
      )}

      {typeof model !== "undefined" && model ? <>{children}</> : ""}
    </>
  );
};
