import React, { useState } from "react";
import DashboardPage from "Components/Templates/DashboardPage";
import Core from "Core";
import { useIsAvailable } from "Components/Hooks/ApiHooks";
import FindLoadingComponent from "Components/FindLoadingComponent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import ModelTable from "Components/ModelTable";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import AdvancedForm from "Components/Form/AdvancedForm";
import { Link } from "react-router-dom";
import LoadingComponent from "Components/LoadingComponent";
import Pulse from "pulse-framework";
import UnAssignSubjectModal from "Components/Modals/UnAssignSubjectModal";
import UnAssignQualificationModal from "Components/Modals/UnAssignQualificationModal";
import SquarePanel from "Components/Panels/SquarePanel";
import { formatRoute } from "react-router-named-routes";
import Routes from "Routes";
import EditFieldModal from "Components/Modals/EditFieldModal";

const IndividualExamBoard = (props) => {
  const { qualifications, subjects, assignedQualifications, assignedSubjects } = props;

  const [showQualifications, setShowQualifications] = useState(false);
  const [showSubjects, setShowSubjects] = useState(false);

  const [loadingQualifications, setLoadingQualifications] = useState(false);
  const [loadingSubjects, setLoadingSubjects] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [errorQualifications, setErrorQualifications] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [errorSubjects, setErrorSubjects] = useState(false);

  let { examBoardId } = props.match.params;

  Core.examBoards.setSelected(examBoardId);

  const toggleShowQualifications = (_) => {
    setLoadingQualifications(true);

    if (showQualifications) {
      setLoadingQualifications(false);
      return setShowQualifications(false);
    }

    setShowQualifications(true);
    Core.qualifications.getQualifications().then((res) => {
      setLoadingQualifications(false);

      if (res.status.code !== 200) {
        setErrorQualifications("Error receiving qualifications.");
      }
    });
  };

  const toggleShowSubjects = (_) => {
    setLoadingSubjects(true);

    if (showSubjects) {
      setLoadingSubjects(false);
      return setShowSubjects(false);
    }

    setShowSubjects(true);
    Core.subjects.getSubjects().then((res) => {
      setLoadingSubjects(false);

      if (res.status.code !== 200) {
        setErrorSubjects("Error receiving subjects.");
      }
    });
  };

  const [c, setC] = useState(0);

  const [examBoardReload, setExamBoardReload] = useState(0);
  const examBoard = useIsAvailable({
    find: Core.examBoards.find,
    findQueryKey: "id",
    findQueryValue: examBoardId,
    refreshCounter: examBoardReload,
  });

  const fetchData = (_) => {
    // set
    setExamBoardReload(examBoardReload + 1);
  };

  const compareAlphabetically = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const bandA = a.name.toUpperCase();
    const bandB = b.name.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  };

  const edit = (data) => {
    return new Promise((resolve, reject) => {
      Core.examBoards.edit(data).then((res) => {
        if (res?.status.type === "success") fetchData();
        return resolve(res);
      });
    });
  };

  return (
    <DashboardPage layoutFor="examBoards">
      <FindLoadingComponent modelName="Exam Board" model={examBoard}>
        {typeof examBoard === "object" ? (
          <Row>
            <Col md={7}>
              <h1>{examBoard.name}</h1> <hr />
              <Button className="mb-3" size="sm" variant="primary" onClick={fetchData.bind(this)}>
                <>
                  <i className="fas fa-sync pr-1"></i> Reload
                </>
              </Button>
            </Col>
            <Col md={5}></Col>

            <Col lg={6}>
              <Card className="mb-5 shadow rounded-0">
                <Card.Header>
                  <strong>
                    <i className="fas fa-info-circle mr-2"></i> Basic Information
                  </strong>
                </Card.Header>
                <Card.Body>
                  <p>Basic Information regaring the registration of {examBoard.name}.</p>
                  <hr />
                  <p>
                    <strong>ExamBoard ID: </strong>
                    {examBoard.id}
                  </p>
                  <p>
                    <strong>ExamBoard Name: </strong>
                    <EditFieldModal
                      fieldKey="name"
                      fieldDisplayName="ExamBoard Name"
                      currentValue={examBoard.name}
                      modelId={examBoard.id}
                      action={edit}
                    />
                    <br />
                    {examBoard.name}
                  </p>
                  <p>
                    <strong>ExamBoard Details: </strong>
                    <EditFieldModal
                      fieldKey="details"
                      fieldDisplayName="ExamBoard Details"
                      currentValue={examBoard.details}
                      modelId={examBoard.id}
                      action={edit}
                      as="textarea"
                    />
                    <br />
                    {examBoard.details}
                  </p>
                  <p>
                    <strong>Created At: </strong>
                    {[
                      new Date(examBoard["createdAt"]).toDateString(),
                      " - ",
                      new Date(examBoard["createdAt"]).toLocaleTimeString(),
                    ]}
                  </p>
                  <p>
                    <strong>Updated At: </strong>
                    {[
                      new Date(examBoard["updatedAt"]).toDateString(),
                      " - ",
                      new Date(examBoard["updatedAt"]).toLocaleTimeString(),
                    ]}
                  </p>
                  {/* <ModelTable className="mb-0" model={examBoard} /> */}
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6}></Col>

            <Col lg={5}>
              <SquarePanel
                headerVariant="primary text-white"
                header={
                  <strong>
                    <i className="fas fa-info-circle mr-2"></i> Linked Qualifications
                  </strong>
                }
              >
                <p>
                  Here you can find all the Qualifications linked to the {examBoard.name}{" "}
                  Examination Board.
                </p>

                <Link
                  className="is-block mb-3 d-block"
                  onClick={toggleShowQualifications.bind(this)}
                >
                  <strong>
                    {showQualifications ? "Hide" : "Show"} qualifications link form{" "}
                    <i className={`fas fa-arrow-${showQualifications ? "up" : "down"}`}></i>
                  </strong>
                </Link>

                {loadingQualifications ? (
                  <div className="bg-light p-3 rounded">
                    <LoadingComponent variant="primary" />
                  </div>
                ) : (
                  ""
                )}

                {showQualifications ? (
                  <div className="bg-light p-3 rounded">
                    <AdvancedForm
                      onSuccess={(e) => setC(c + 1)}
                      formName="Link Qualifications"
                      values={{ examBoardId }}
                      fields={{
                        qualificationId: {
                          placeholder: "Link a Qualification",
                          underText: "Select a qualification from the list",
                          label: "Qualification to Link",
                          as: "select",
                          options: qualifications.map((q, index) => {
                            return { value: q.id, text: q.name, key: index };
                          }),
                          icon: <i className="fas fa-layer-group"></i>,
                          colBp: "md",
                          colSize: "12",
                        },
                      }}
                      button={{
                        action: Core.examBoards.assignQualification,
                        noMargin: true,
                        variant: "primary",
                        text: (
                          <span>
                            <i className="fas fa-plus"></i> Assign
                          </span>
                        ),
                        className: "rounded-0",
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}

                <hr />

                <Table
                  borderless
                  hidden={assignedQualifications && assignedQualifications.length < 1}
                >
                  <thead>
                    <tr>
                      <th>Qualification</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {assignedQualifications.map((qualification) => (
                      <tr key={qualification.id}>
                        <td className="align-middle">
                          {qualification.name}{" "}
                          <Badge variant="primary">
                            <strong>{qualification.prefix}</strong>
                          </Badge>
                        </td>
                        <td className="align-middle">
                          <Link
                            to={formatRoute(Routes.qualificationsIndividual, {
                              qualificationId: qualification.id,
                            })}
                          >
                            <Button size="sm" variant="primary" className="rounded-0 mr-1">
                              <i className="fas fa-eye"></i>
                            </Button>
                          </Link>

                          <UnAssignQualificationModal
                            qualification={qualification}
                            examBoard={examBoard}
                            button={
                              <Button size="sm" variant="danger" className="rounded-0">
                                <i className="fas fa-trash"></i>
                              </Button>
                            }
                            title={
                              <span>
                                Un-Assign {qualification.name}{" "}
                                <small>({qualification.prefix})</small>
                              </span>
                            }
                            body={
                              <span>
                                Are you sure you would like to un-assign {qualification.name} from
                                the {examBoard.name} Examination Board?
                              </span>
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </SquarePanel>
            </Col>
            <Col lg={7}>
              <SquarePanel
                header={
                  <strong>
                    <i className="fas fa-info-circle mr-2"></i> Linked Subjects
                  </strong>
                }
                headerVariant="warning"
              >
                <p>
                  Here you can find all the Subjects linked to the {examBoard.name} Examination
                  Board.
                </p>

                <Link className="is-block mb-3 d-block" onClick={toggleShowSubjects.bind(this)}>
                  <strong>
                    {showSubjects ? "Hide" : "Show"} subject link form{" "}
                    <i className={`fas fa-arrow-${showSubjects ? "up" : "down"}`}></i>
                  </strong>
                </Link>

                {loadingSubjects ? (
                  <div className="bg-light p-3 rounded">
                    <LoadingComponent variant="warning" />
                  </div>
                ) : (
                  ""
                )}

                {showSubjects ? (
                  <div className="bg-light p-3 rounded">
                    <AdvancedForm
                      values={{ examBoardId: examBoardId }}
                      formName="Link Subject"
                      fields={{
                        subjectId: {
                          placeholder: "Link a Subject",
                          underText: "Select a subject from the list",
                          label: "Subject to Link",
                          as: "select",
                          options: subjects.sort(compareAlphabetically).map((s, index) => {
                            return {
                              value: s.id,
                              text: s.name + ` - ${s.Qualification.prefix}`,
                              key: index,
                            };
                          }),
                          icon: <i className="fas fa-layer-group"></i>,
                          colBp: "md",
                          colSize: "12",
                        },
                      }}
                      button={{
                        action: Core.examBoards.assignSubject,
                        noMargin: true,
                        variant: "warning",
                        text: (
                          <span>
                            <i className="fas fa-plus"></i> Assign
                          </span>
                        ),
                        className: "rounded-0",
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}

                <hr />

                <Table borderless hidden={assignedSubjects && assignedSubjects.length < 1}>
                  <thead>
                    <tr>
                      <th>Subject</th>
                      <th>Qualification</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {assignedSubjects.sort(compareAlphabetically).map((subject, index) => (
                      <tr key={index}>
                        <td className="align-middle">{subject.name}</td>
                        <td className="align-middle">
                          {subject.Qualification.name} {/* <small> */}
                          <Badge variant="warning">
                            <strong>{subject.Qualification.prefix}</strong>
                          </Badge>
                          {/* </small> */}
                        </td>
                        <td className="align-middle">
                          <Link
                            to={
                              formatRoute(Routes.subjectsIndividual, {
                                subjectId: subject.id,
                              }) + `?ebId=${examBoardId}`
                            }
                          >
                            <Button size="sm" variant="primary" className="rounded-0 mr-1">
                              <i className="fas fa-eye"></i>
                            </Button>
                          </Link>

                          <UnAssignSubjectModal
                            subject={subject}
                            examBoard={examBoard}
                            button={
                              <Button size="sm" variant="danger" className="rounded-0">
                                <i className="fas fa-trash"></i>
                              </Button>
                            }
                            title={
                              <span>
                                Un-Assign {subject.name}{" "}
                                <small>({subject.Qualification.prefix})</small>
                              </span>
                            }
                            body={
                              <span>
                                Are you sure you would like to un-assign {subject.name} of{" "}
                                {subject.Qualification.name} from the {examBoard.name} Examination
                                Board?
                              </span>
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </SquarePanel>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </FindLoadingComponent>
    </DashboardPage>
  );
};

export default Pulse.React(IndividualExamBoard, (core) => {
  return {
    qualifications: core.qualifications.all,
    subjects: core.subjects.all,
    assignedQualifications: core.qualifications.selectedExamBoard,
    assignedSubjects: core.subjects.selectedExamBoard,
  };
});
