import React from "react";
import Card from "react-bootstrap/Card";

const SquarePanel = (props) => {
  return (
    <Card className="mb-5 shadow rounded-0">
      <Card.Header className={`bg-${props.headerVariant || ""} rounded-0`}>
        {props.header}
      </Card.Header>

      <Card.Body>{props.children}</Card.Body>
    </Card>
  );
};

export default SquarePanel;
