import React from "react";
import DashboardPage from "Components/Templates/DashboardPage";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AdvancedForm from "Components/Form/AdvancedForm";
import Core from "Core";

const ExamSeriesCreate = (props) => {
  return (
    <DashboardPage layoutFor="examSeries">
      <Row>
        <Col md={7}>
          <h1>Exam Series Create</h1>
          <p className="text-secondary">
            Use this page to create an <strong className="bg-info text-light">Exam Series</strong>
          </p>

          <hr />

          <AdvancedForm
            formName="Exam Series"
            fields={{
              examSeriesYear: {
                placeholder: "Enter Year",
                underText: "Enter an Exam Series year that doesn't exist",
                label: "Exam Series Year",
                icon: <i className="fas fa-calendar"></i>,
                colBp: "md",
                colSize: "12",
              },
              examSeriesDetails: {
                placeholder: "Exam Series Details",
                label: ["Details ", <small>(Optional)</small>],
                icon: <i className="fas fa-info-circle"></i>,
                colBp: "md",
                colSize: "12",
                as: "textarea",
              },
            }}
            button={{
              action: Core.examSeries.create,
            }}
          />
        </Col>
      </Row>
    </DashboardPage>
  );
};

export default ExamSeriesCreate;
