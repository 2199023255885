import React from "react";
import { useIsAvailable } from "Components/Hooks/ApiHooks";
import Core from "Core";
import DashboardPage from "Components/Templates/DashboardPage";
import FindLoadingComponent from "Components/FindLoadingComponent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import Routes from "Routes";
import Button from "react-bootstrap/Button";
import SquarePanel from "Components/Panels/SquarePanel";

const IndividualExercise = (props) => {
  const { exerciseId } = props.match.params;

  const exercise = useIsAvailable({
    find: Core.exercises.find,
    findQueryKey: "id",
    findQueryValue: exerciseId,
  });

  return (
    <DashboardPage>
      <FindLoadingComponent modelName="Exercise" model={exercise}>
        {typeof exercise === "object" ? (
          <>
            <Row>
              <Col md={7}>
                <h1>
                  <strong>Exercise</strong>{" "}
                  <small>
                    <Badge variant="secondary">{exercise.id}</Badge>
                  </small>
                </h1>

                <h5>
                  <strong>{exercise.Chapters[0].Unit.prefix}</strong>:{" "}
                  {exercise.Chapters[0].Unit.name}
                </h5>

                <Breadcrumb className="mt-4 mb-4">
                  <Breadcrumb.Item>
                    <Link
                      to={formatRoute(Routes.examBoardsIndividual, {
                        examBoardId: exercise.Chapters[0].Unit.ExamBoard.id,
                      })}
                    >
                      {exercise.Chapters[0].Unit.ExamBoard.name}
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link
                      to={formatRoute(Routes.qualificationsIndividual, {
                        qualificationId: exercise.Chapters[0].Unit.Subject.Qualification.id,
                      })}
                    >
                      {exercise.Chapters[0].Unit.Subject.Qualification.prefix}
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link
                      to={
                        formatRoute(Routes.subjectsIndividual, {
                          subjectId: exercise.Chapters[0].Unit.Subject.id,
                        }) + `?ebId=${exercise.Chapters[0].Unit.ExamBoardId}`
                      }
                    >
                      {exercise.Chapters[0].Unit.Subject.name}
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link
                      to={formatRoute(Routes.unitsIndividual, {
                        unitId: exercise.Chapters[0].Unit.id,
                      })}
                    >
                      {exercise.Chapters[0].Unit.prefix}
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link
                      to={formatRoute(Routes.documentIndividual, {
                        documentId: exercise.QuestionDocument.id,
                      })}
                    >
                      Document {exercise.QuestionDocument.id}
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Exercise {exercise.id}</Breadcrumb.Item>
                </Breadcrumb>

                <Row>
                  <Col xs={6}>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={Core.exercises.generateExerciseLink(exercise, true)}
                    >
                      <Button block variant="success" className="rounded-0">
                        <i className="fas fa-download"></i> Download
                      </Button>
                    </a>
                  </Col>
                  <Col xs={6}>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={Core.exercises.generateExerciseLink(exercise, false)}
                    >
                      <Button block variant="danger" className="rounded-0 mr-1">
                        <i className="fas fa-file-pdf"></i> View in PDF
                      </Button>
                    </a>
                  </Col>
                </Row>

                <hr />
              </Col>

              <Col md={5}></Col>

              <Col md={7}>
                <div className="mb-5">
                  {/* <h2 className="mb-0">General Information</h2>
                  <p className="text-muted mb-3">
                    Here you can view general information regarding the exercise you are viewing.
                  </p> */}

                  <SquarePanel
                    headerVariant=""
                    header={
                      <strong>
                        <i className="fas fa-info-circle mr-2"></i> General Information
                      </strong>
                    }
                  >
                    <p>Basic information regarding the registration of Exercise: {exercise.id}</p>
                  </SquarePanel>
                </div>

                <div className="mb-5">
                  <h2 className="mb-0">Chapters</h2>
                  <p className="text-muted mb-3">
                    View a list of chapters that are linked to the exercise you are viewing.
                  </p>
                  <ul>
                    {exercise.Chapters.map((chapter) => (
                      <>
                        <li key={chapter.id}>
                          <strong>
                            <a className="text-danger" href="#s">
                              Remove
                            </a>
                          </strong>
                          <span className="mx-3">~</span>
                          {chapter.name}
                          {/* <Badge className="d-inline" variant="danger">
                          Remove
                        </Badge> */}
                        </li>
                      </>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}
      </FindLoadingComponent>
    </DashboardPage>
  );
};

export default IndividualExercise;
