import React, { useState } from "react";
import DashboardPage from "Components/Templates/DashboardPage";
import Core from "Core";
import { useIsAvailable } from "Components/Hooks/ApiHooks";
import FindLoadingComponent from "Components/FindLoadingComponent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ModelTable from "Components/ModelTable";
import Button from "react-bootstrap/Button";
import EditFieldModal from "Components/Modals/EditFieldModal";
import SquarePanel from "Components/Panels/SquarePanel";

const IndividualDocumentType = (props) => {
  let { documentTypeId } = props.match.params;

  const [documentTypesReload, setDocumentTypesReload] = useState(0);
  const fetchData = (_) => setDocumentTypesReload(documentTypesReload + 1);
  const documentType = useIsAvailable({
    find: Core.documentTypes.find,
    findQueryKey: "id",
    findQueryValue: documentTypeId,
    refreshCounter: documentTypesReload,
  });

  const edit = (data) => {
    return new Promise((resolve, reject) => {
      Core.documentTypes.edit(data).then((res) => {
        if (res?.status.type === "success") fetchData();
        return resolve(res);
      });
    });
  };

  return (
    <DashboardPage layoutFor="documentTypes">
      <FindLoadingComponent modelName="Document Type" model={documentType}>
        {typeof documentType === "object" ? (
          <Row>
            <Col md={7}>
              <h1>{documentType.name}</h1>

              <hr />

              <Button className="mb-3" size="sm" variant="primary" onClick={fetchData.bind(this)}>
                <>
                  <i className="fas fa-sync pr-1"></i> Reload
                </>
              </Button>

              {/* <ModelTable model={documentType} /> */}
            </Col>

            <Col md={5}></Col>

            <Col md={7}>
              <SquarePanel
                header={
                  <strong>
                    <i className="fas fa-info-circle mr-2"></i> General Information
                  </strong>
                }
              >
                <p>Basic Information regaring the registration of {documentType.name}.</p>
                <hr />
                <p>
                  <strong>DocumentType ID: </strong>
                  {documentType.id}
                </p>
                <p>
                  <strong>DocumentType Name: </strong>
                  <EditFieldModal
                    fieldKey="name"
                    fieldDisplayName="DocumentType Name"
                    currentValue={documentType.year}
                    modelId={documentType.id}
                    action={edit}
                  />
                  <br />
                  {documentType.name}
                </p>
                <p>
                  <strong>DocumentType Details: </strong>
                  <EditFieldModal
                    fieldKey="details"
                    fieldDisplayName="DocumentType Details"
                    currentValue={documentType.details}
                    modelId={documentType.id}
                    action={edit}
                    as="textarea"
                  />
                  <br />
                  {documentType.details}
                </p>
                <p>
                  <strong>DocumentType is for Chapter: </strong>
                  {documentType.isChapter.toString()}
                </p>
                <p>
                  <strong>DocumentType is for Exam: </strong>
                  {documentType.isExam.toString()}
                </p>
                <p>
                  <strong>Created At: </strong>
                  {[
                    new Date(documentType["createdAt"]).toDateString(),
                    " - ",
                    new Date(documentType["createdAt"]).toLocaleTimeString(),
                  ]}
                </p>
                <p>
                  <strong>Updated At: </strong>
                  {[
                    new Date(documentType["updatedAt"]).toDateString(),
                    " - ",
                    new Date(documentType["updatedAt"]).toLocaleTimeString(),
                  ]}
                </p>
              </SquarePanel>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </FindLoadingComponent>
    </DashboardPage>
  );
};

export default IndividualDocumentType;
