import DashboardPage from "Components/Templates/DashboardPage";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Pulse from "pulse-framework";
import Core from "Core";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import LoadingComponent from "Components/LoadingComponent";
import PaginationTable from "Components/PaginationTable";
import ModelTable from "Components/ModelTable";

const Logger = (props) => {
  let { logs } = props;

  const [errorLogs, setErrorLogs] = useState(false);
  const [loadingLogs, setLoadingLogs] = useState(false);

  const [selectedLog, setSelectedLog] = useState({});
  const [selectedLogId, setSelectedLogId] = useState(0);

  const getLogs = () => {
    setLoadingLogs(true);
    setErrorLogs(false);
    setSelectedLogId(0);
    setSelectedLog({});
    Core.logger.getLogs().then((res) => {
      if (res === "error") setErrorLogs(true);
      setLoadingLogs(false);
    });
  };

  useEffect(() => {
    getLogs();
  }, []);

  const selectLog = (log) => {
    console.log(log);
    let newLog = log;

    if (log.oldJSON) {
      newLog.oldJSON = JSON.parse(log.oldJSON);
    }

    if (log.newJSON) {
      newLog.newJSON = JSON.parse(log.newJSON);
    }

    if (log.userJSON) {
      newLog.userJSON = JSON.parse(log.userJSON);
    }

    setSelectedLog(newLog);
    setSelectedLogId(newLog.id);
  };

  return (
    <DashboardPage>
      <Row>
        <Col md={7}>
          <h1>
            <strong>Activity Logger</strong>
          </h1>
          <p className="text-secondary">
            This is the Activity Logger of Administration Actions. All actions made by
            administrators that may affect the functionality of the system will be logged here for
            safety.
          </p>
          <hr />

          <Button
            onClick={getLogs.bind(this)}
            variant="outline-primary"
            size="sm"
            disabled={loadingLogs}
          >
            {loadingLogs ? (
              <Spinner className="mx-3" animation="border" size="sm" />
            ) : (
              <>
                <i className="fas fa-sync pr-1"></i> Reload
              </>
            )}
          </Button>

          {errorLogs ? (
            <Alert variant="danger" className="my-3">
              <Alert.Heading>Couldn't receive Logs</Alert.Heading>
              <hr />
              There was an error receiving the Activity Logs.
            </Alert>
          ) : (
            ""
          )}
        </Col>
        <Col md={5}></Col>

        <Col md={12}>
          {loadingLogs ? (
            <LoadingComponent />
          ) : !errorLogs ? (
            <PaginationTable
              prefix="logs"
              className="mt-3"
              itemsPerPage={5}
              borderless
              striped
              thead={
                <thead>
                  <tr>
                    <th className="align-middle">#</th>
                    <th className="align-middle">Model</th>
                    <th className="align-middle">Action</th>
                    <th className="align-middle">Details</th>
                    <th className="align-middle">User Email</th>
                    <th className="align-middle">Actions</th>
                  </tr>
                </thead>
              }
              tbody={logs.map((log, index) => (
                <tr key={index}>
                  <td className="align-middle">{log.id}</td>
                  <td className="align-middle">
                    {log.ModelName}:{log.ModelId}
                  </td>
                  <td className="align-middle">{log.action}</td>
                  <td className="align-middle">{log.details}</td>
                  <td className="align-middle">{log.User.email}</td>
                  <td className="align-middle text-center">
                    <Button
                      onClick={selectLog.bind(this, log)}
                      variant="outline-primary"
                      size="sm"
                      disabled={log.id === selectedLogId}
                    >
                      View
                    </Button>
                  </td>
                </tr>
              ))}
            />
          ) : (
            ""
          )}
        </Col>

        {selectedLog.id > 0 ? (
          <Col md={12} className="mt-4">
            <h1>
              <strong>Log ID: {selectedLog.id}</strong>
            </h1>
            <p className="text-secondary">View the details of the Log you have selected to view.</p>
            <hr />

            <p>
              <strong>Action: </strong>
              {selectedLog.action}
            </p>

            <p>
              <strong>Affected Model: </strong>
              {selectedLog.ModelName} (ID: {selectedLog.ModelId})
            </p>

            <p>
              <strong>Details: </strong>
              {selectedLog.details}
            </p>

            {selectedLog.action === "model.rename" ? (
              <Row className="mt-4">
                <Col lg={4}>
                  <h3 className="mb-3">
                    <strong>
                      <i class="fas fa-backward mr-2 text-danger"></i> {selectedLog.ModelName}{" "}
                      Before Edit
                    </strong>
                  </h3>
                  {selectedLog.oldJSON ? <ModelTable model={selectedLog.oldJSON || {}} /> : ""}
                </Col>

                <Col lg={4}>
                  <h3 className="mb-3">
                    <strong>
                      <i class="fas fa-forward mr-2 text-success"></i> {selectedLog.ModelName} After
                      Edit
                    </strong>
                  </h3>
                  {selectedLog.newJSON ? <ModelTable model={selectedLog.newJSON || {}} /> : ""}
                </Col>

                <Col lg={4}>
                  <h3 className="mb-3">
                    <strong>
                      <i class="fas fa-user mr-2 text-primary"></i> By User
                    </strong>
                  </h3>
                  {selectedLog.userJSON ? <ModelTable model={selectedLog.userJSON || {}} /> : ""}
                </Col>
              </Row>
            ) : (
              ""
            )}
          </Col>
        ) : (
          ""
        )}
      </Row>
    </DashboardPage>
  );
};

export default Pulse.React(Logger, (core) => {
  return {
    logs: core.logger.all,
  };
});
