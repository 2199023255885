import React, { useEffect, useState } from "react";
import DashboardPage from "Components/Templates/DashboardPage";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import PaginationTable from "Components/PaginationTable";

import LoadingComponent from "Components/LoadingComponent";

import Core from "Core";
import Pulse from "pulse-framework";
import Spinner from "react-bootstrap/Spinner";

import { Link } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import Routes from "Routes";

const Subjects = (props) => {
  let { subjects } = props;

  const [errorSubjects, setErrorSubjects] = useState(false);
  const [loadingSubjects, setLoadingSubjects] = useState(false);

  const getSubjects = () => {
    setLoadingSubjects(true);
    setErrorSubjects(false);
    Core.subjects.getSubjects().then((res) => {
      if (res === "error") setErrorSubjects(true);
      setLoadingSubjects(false);
    });
  };

  const compareAlphabetically = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const bandA = a.name.toUpperCase();
    const bandB = b.name.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  };

  useEffect(() => {
    getSubjects();
  }, []);

  return (
    <DashboardPage layoutFor="subjects">
      <Row>
        <Col md={7}>
          <h1>Subjects</h1>
          <p className="text-secondary">
            List of the <strong>Subjects</strong> registered.
          </p>
          <hr />

          <Button
            onClick={getSubjects.bind(this)}
            variant="outline-primary"
            size="sm"
            disabled={loadingSubjects}
          >
            {loadingSubjects ? (
              <Spinner className="mx-3" animation="border" size="sm" />
            ) : (
              <>
                <i className="fas fa-sync pr-1"></i> Reload
              </>
            )}
          </Button>

          {errorSubjects ? (
            <Alert variant="danger" className="my-3">
              <Alert.Heading>Couldn't receive Subjects</Alert.Heading>
              <hr />
              There was an error receiving the Subjects.
            </Alert>
          ) : (
            ""
          )}
          {loadingSubjects ? (
            <LoadingComponent />
          ) : !errorSubjects ? (
            <PaginationTable
              prefix="subjects"
              itemsPerPage={5}
              className="mt-3"
              borderless
              hover
              striped
              thead={
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Subject</th>
                    <th>Actions</th>
                  </tr>
                </thead>
              }
              tbody={subjects?.sort(compareAlphabetically).map((subject, index) => (
                <tr key={index}>
                  <td className="align-middle">{subject.id}</td>
                  <td className="align-middle">
                    {subject.name} (
                    <small className="font-weight-bold">{subject?.Qualification?.prefix}</small>)
                    <br />
                    <Link
                      to={formatRoute(Routes.qualificationsIndividual, {
                        qualificationId: subject?.Qualification?.id,
                      })}
                    >
                      <small className="font-weight-bold">{subject?.Qualification?.name}</small>
                    </Link>
                  </td>
                  <td className="align-middle">
                    <Link
                      to={formatRoute(Routes.subjectsIndividual, {
                        subjectId: subject.id,
                      })}
                    >
                      <Button size="sm" variant="primary" className="rounded-0">
                        <i className="fas fa-eye"></i> View
                      </Button>
                    </Link>
                  </td>
                </tr>
              ))}
            />
          ) : (
            ""
          )}
        </Col>
      </Row>
    </DashboardPage>
  );
};

export default Pulse.React(Subjects, (core) => {
  return {
    subjects: core.subjects.all,
  };
});
