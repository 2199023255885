import React from "react";
import DashboardPage from "Components/Templates/DashboardPage";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AdvancedForm from "Components/Form/AdvancedForm";
import Core from "Core";

const DocumentTypesCreate = (props) => {
  return (
    <DashboardPage layoutFor="documentTypes">
      <Row>
        <Col md={7}>
          <h1>Document Type Create</h1>
          <p className="text-secondary">
            Use this page to create a <strong className="bg-info text-light">Document Type</strong>
          </p>

          <hr />

          <AdvancedForm
            formName="Document Type"
            fields={{
              generalInformationTitle: {
                content: "General Information",
                header: "3",
              },
              p1: {
                content: "Enter general information regarding the Document Type you are creating.",
                header: "p",
                colBp: "md",
                className: "mt-0",
                colSize: "12",
              },
              documentTypeName: {
                placeholder: "Enter Name",
                underText: "Enter an Document Type name that doesn't exist",
                label: "Document Type Name",
                icon: <i className="fas fa-hand-paper"></i>,
                colBp: "md",
                colSize: "12",
              },
              documentTypeDetails: {
                placeholder: "Document Type Details",
                label: ["Details ", <small>(Optional)</small>],
                icon: <i className="fas fa-info-circle"></i>,
                colBp: "md",
                colSize: "12",
                as: "textarea",
              },
              documentTypeDetailsTitle: {
                content: "Document Type Details",
                header: "3",
                className: "mt-2",
                colBp: "md",
                colSize: "12",
              },
              p2: {
                content:
                  "Enter details below in order to properly relate the Documents to the Document Type",
                header: "p",
                colBp: "md",
                className: "mt-0",
                colSize: "12",
              },
              documentTypeRelatedTo: {
                label: "Document is related to",
                icon: <i className="fas fa-layer-group"></i>,
                as: "select",
                placeholder: "Select a Document Relation",
                options: [
                  { value: "chapter", text: "Chapter Related" },
                  { value: "exam", text: "Exam Related" },
                ],
                colBp: "md",
                colSize: "12",
              },
            }}
            button={{ action: Core.documentTypes.create }}
          />
        </Col>
      </Row>
    </DashboardPage>
  );
};

export default DocumentTypesCreate;
