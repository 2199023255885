import React, { useState } from "react";
import { useIsAvailable } from "Components/Hooks/ApiHooks";
import Core from "Core";
import DashboardPage from "Components/Templates/DashboardPage";
import FindLoadingComponent from "Components/FindLoadingComponent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Link from "react-router-dom/Link";
import { formatRoute } from "react-router-named-routes";
import Routes from "Routes";
import PaginationTable from "Components/PaginationTable";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import SquarePanel from "Components/Panels/SquarePanel";
import EditFieldModal from "Components/Modals/EditFieldModal";

const IndividualChapterScreen = (props) => {
  let { chapterId } = props.match.params;

  const [chapterReload, setChapterReload] = useState(0);
  const fetchData = (_) => setChapterReload(chapterReload + 1);
  const chapter = useIsAvailable({
    find: Core.chapters.find,
    findQueryKey: "id",
    findQueryValue: chapterId,
    refreshCounter: chapterReload,
  });

  const edit = (data) => {
    return new Promise((resolve, reject) => {
      Core.chapters.edit(data).then((res) => {
        if (res?.status.type === "success") fetchData();
        return resolve(res);
      });
    });
  };

  return (
    <DashboardPage layoutFor="chapters">
      <FindLoadingComponent modelName="Chapter" model={chapter}>
        {typeof chapter === "object" ? (
          <Row>
            <Col md={7}>
              <h1>
                <strong>{chapter.name}</strong>
              </h1>
              <h3>
                {chapter.Unit?.prefix} : {chapter.Unit?.Subject.name}
              </h3>

              <Breadcrumb className="mt-4 mb-4">
                <Breadcrumb.Item>
                  <Link
                    to={formatRoute(Routes.examBoardsIndividual, {
                      examBoardId: chapter.Unit?.ExamBoard.id,
                    })}
                  >
                    {chapter.Unit?.ExamBoard.name}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    to={formatRoute(Routes.qualificationsIndividual, {
                      qualificationId: chapter.Unit?.Subject.Qualification.id,
                    })}
                  >
                    {chapter.Unit?.Subject.Qualification.prefix}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    to={
                      formatRoute(Routes.subjectsIndividual, {
                        subjectId: chapter.Unit?.Subject.id,
                      }) + `?ebId=${chapter.Unit?.ExamBoardId}`
                    }
                  >
                    {chapter.Unit?.Subject.name}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    to={formatRoute(Routes.unitsIndividual, {
                      unitId: chapter.Unit?.id,
                    })}
                  >
                    {chapter.Unit?.prefix}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{chapter.name}</Breadcrumb.Item>
              </Breadcrumb>

              <hr />

              <Button className="mb-3" size="sm" variant="primary" onClick={fetchData.bind(this)}>
                <>
                  <i className="fas fa-sync pr-1"></i> Reload
                </>
              </Button>
            </Col>

            <Col md={5}></Col>

            <Col md={12} className="mt-3">
              {/* <SquarePanel> */}
              <h1 className="mb-0">
                <strong>
                  <i class="fas fa-atom mr-3 text-primary"></i> Exercises Related to Chapter
                </strong>
              </h1>
              <p>
                We have found <strong>{chapter.Exercises?.length}</strong> exercises related to{" "}
                <strong>{chapter.name}</strong>.
              </p>
              <PaginationTable
                prefix="chapter_exercises"
                itemsPerPage={10}
                className="mt-3"
                striped
                thead={
                  <thead>
                    <th>#</th>
                    <th>
                      <abbr title="Question Starting Page">QSP</abbr>
                    </th>
                    <th>
                      <abbr title="Question Ending Page">QEP</abbr>
                    </th>
                    <th>
                      <abbr title="Additional Question Pages (Begining)">QSaP</abbr>
                    </th>
                    <th>
                      <abbr title="Additional Question Pages (End)">QSeP</abbr>
                    </th>

                    <th>
                      <abbr title="Answer Starting Page">ASP</abbr>
                    </th>
                    <th>
                      <abbr title="Answer Ending Page">AEP</abbr>
                    </th>
                    <th>
                      <abbr title="Additional Answer Pages (Begining)">ASaP</abbr>
                    </th>
                    <th>
                      <abbr title="Additional Answer Pages (End)">AEaP</abbr>
                    </th>

                    <th>Paper Reference</th>
                    <th>Difficulty</th>

                    <th />
                  </thead>
                }
                tbody={
                  chapter.Exercises?.sort(function (a, b) {
                    var textB =
                      a.QuestionDocument.ExamSery.year + a.QuestionDocument.ExamSeason.name;
                    var textA =
                      b.QuestionDocument.ExamSery.year + b.QuestionDocument.ExamSeason.name;
                    return textA < textB ? -1 : textA > textB ? 1 : 0;
                  }).map((exercise) => (
                    <tr>
                      <td className="align-middle">{exercise.id}</td>
                      <td className="align-middle">{exercise.questionPages.split(",")[0]}</td>
                      <td className="align-middle">{exercise.questionPages.split(",")[1]}</td>
                      <td className="align-middle">
                        {exercise.questionAdditionalPages.split("|")[0]}
                      </td>
                      <td className="align-middle">
                        {exercise.questionAdditionalPages.split("|")[1]}
                      </td>
                      <td className="align-middle">{exercise.answerPages.split(",")[0]}</td>
                      <td className="align-middle">{exercise.answerPages.split(",")[1]}</td>
                      <td className="align-middle">
                        {exercise.answerAdditionalPages.split("|")[0]}
                      </td>
                      <td className="align-middle">
                        {exercise.answerAdditionalPages.split("|")[1]}
                      </td>
                      <td className="align-middle">
                        {
                          <Link
                            to={formatRoute(Routes.documentIndividual, {
                              documentId: exercise.QuestionDocumentId,
                            })}
                          >
                            {exercise.QuestionDocument.ExamSeason.name}{" "}
                            {exercise.QuestionDocument.ExamSery.year}
                          </Link>
                        }
                      </td>
                      <td className="align-middle">{exercise.Difficulty.name}</td>
                      <td className="align-middle">
                        <ButtonGroup>
                          <Link
                            to={formatRoute(Routes.exerciseIndividual, {
                              exerciseId: exercise.id,
                            })}
                          >
                            <Button size="sm" variant="primary" className="rounded-0 mr-1">
                              <i className="fas fa-eye"></i>
                            </Button>
                          </Link>

                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={Core.exercises.generateExerciseLink(exercise, false)}
                          >
                            <Button size="sm" variant="danger" className="rounded-0 mr-1">
                              <i className="fas fa-file-pdf"></i>
                            </Button>
                          </a>

                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={Core.exercises.generateExerciseLink(exercise, true)}
                          >
                            <Button size="sm" variant="success" className="rounded-0">
                              <i className="fas fa-download"></i>
                            </Button>
                          </a>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))
                  // ...documentExercises
                  //   .sort((a, b) => parseFloat(a.questionPages) - parseFloat(b.questionPages))
                  //   .map((exercise) => generateRow(exercise)),
                }
                tfoot
              />
              {/* </SquarePanel> */}
            </Col>

            <Col md={7} className="mt-3 mb-5">
              <h1 className="mb-0">
                <strong>
                  <i class="fas fa-info-circle mr-2 text-info"></i> General Information
                </strong>
              </h1>
              <p>Information related to the Chapter: {chapter.name}</p>

              <hr />

              <p>
                <strong>Chapter Name: </strong>
                <EditFieldModal
                  fieldKey="name"
                  fieldDisplayName="Chapter Name"
                  currentValue={chapter.name}
                  modelId={chapter.id}
                  action={edit}
                />
                <br />
                {chapter.name}
              </p>
              <p>
                <strong>Unit Name:</strong> {chapter.Unit?.name}
              </p>
              <p>
                <strong>Subject Name:</strong> {chapter.Unit?.Subject.name}
              </p>
              <p>
                <strong>Qualification Name:</strong> {chapter.Unit?.Subject.Qualification.name}
              </p>
              <p>
                <strong>ExamBoard Name:</strong> {chapter.Unit?.ExamBoard.name}
              </p>
              <p>
                <strong>Chapter Details: </strong>
                <EditFieldModal
                  fieldKey="details"
                  fieldDisplayName="Chapter Details"
                  currentValue={chapter.details}
                  modelId={chapter.id}
                  action={edit}
                  as="textarea"
                />
                <br />
                {chapter.details}
              </p>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </FindLoadingComponent>
    </DashboardPage>
  );
};

export default IndividualChapterScreen;
