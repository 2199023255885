import axios from "axios";

export default {
  groups: ["all"],
  data: {},
  persist: [],
  routes: {
    // Get information to create an exercise
    getExerciseCreateInfo: (request) => request.get("documents/information?relatedTo=unit"),
    // Find an individual document
    find: (request, query) => request.get("documents/get" + query),
    // Upload a document
    upload: (request, body) => request.post("documents/upload", body),
  },
  actions: {
    // generate the download url
    generateDownloadLink: ({ request }, document, download = false) => {
      // if download = true, then download if false the view in seperate tab
      let url = `${request.baseURL}/documents/view?id=${document.id}`;
      if (download) url = `${url}&download=true`;
      return url;
    },

    // retrieve exercise examseries, examseasons, difficulties
    getExerciseCreateInfo: ({ routes }) => {},

    // Find a Exercise
    find: ({ routes, base, exercises }, data) => {
      let queryParams = base.makeQueryParams(data);
      return routes
        .find(queryParams)
        .then((res) => {
          exercises.deleteGroup("documentExercises");
          exercises.collect(res.document.Exercises, "documentExercises");
          return { status: res.status, result: res.document };
        })
        .catch((e) => e);
    },

    // Upload Document
    upload: ({ request }, data) => {
      let formData = new FormData();
      Object.keys(data).forEach((index) => {
        formData.append(index, data[index]);
      });

      let headers = {
        Authorization: request.headers["Authorization"],
      };

      return axios
        .post(`${request.baseURL}/documents/upload`, formData, { headers })
        .then((res) => res.data)
        .catch((e) => {
          return e.response.data;
        });
    },
  },
};
