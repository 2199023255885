import React, { useState, useEffect } from "react";
import DashboardPage from "Components/Templates/DashboardPage";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import LoadingComponent from "Components/LoadingComponent";
import Core from "Core";
import Pulse from "pulse-framework";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import Routes from "Routes";
import PaginationTable from "Components/PaginationTable";

const ExamSeasons = (props) => {
  let { examSeasons } = props;

  const [errorExamSeasons, setErrorExamSeasons] = useState(false);
  const [loadingExamSeasons, setLoadingExamSeasons] = useState(false);

  const getExamSeasons = () => {
    setLoadingExamSeasons(true);
    setErrorExamSeasons(false);
    Core.examSeasons.getExamSeasons().then((res) => {
      if (res === "error") setErrorExamSeasons(true);
      setLoadingExamSeasons(false);
    });
  };

  const compareAlphabetically = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const bandA = a.name.toUpperCase();
    const bandB = b.name.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  };

  useEffect(() => {
    getExamSeasons();
  }, []);

  return (
    <DashboardPage layoutFor="examSeasons">
      <Row>
        <Col md={7}>
          <h1>Exam Seasons</h1>
          <p className="text-secondary">
            List of the <strong>Exam Seasons</strong> registered.
          </p>
          <hr />

          <Button
            onClick={getExamSeasons.bind(this)}
            variant="outline-primary"
            size="sm"
            disabled={loadingExamSeasons}
          >
            {loadingExamSeasons ? (
              <Spinner className="mx-3" animation="border" size="sm" />
            ) : (
              <>
                <i className="fas fa-sync pr-1"></i> Reload
              </>
            )}
          </Button>

          {errorExamSeasons ? (
            <Alert variant="danger" className="my-3">
              <Alert.Heading>Couldn't receive Exam Seasons</Alert.Heading>
              <hr />
              There was an error receiving the Exam Seasons.
            </Alert>
          ) : (
            ""
          )}
          {loadingExamSeasons ? (
            <LoadingComponent />
          ) : !errorExamSeasons ? (
            <PaginationTable
              prefix="examSeasons"
              className="mt-3"
              itemsPerPage={5}
              borderless
              striped
              thead={
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Exam Season</th>
                    <th>Actions</th>
                  </tr>
                </thead>
              }
              tbody={examSeasons.sort(compareAlphabetically).map((es, index) => (
                <tr key={index}>
                  <td className="align-middle">{es.id}</td>
                  <td className="align-middle">{es.name}</td>
                  <td className="align-middle">
                    <Link
                      to={formatRoute(Routes.examSeasonsIndividual, {
                        examSeasonId: es.id,
                      })}
                    >
                      <Button size="sm" variant="primary" className="rounded-0">
                        <i className="fas fa-eye"></i> View
                      </Button>
                    </Link>
                  </td>
                </tr>
              ))}
            />
          ) : (
            // <Table striped borderless hover className="mt-3">

            //   <tbody>

            //   </tbody>
            // </Table>
            ""
          )}
        </Col>
      </Row>
    </DashboardPage>
  );
};

export default Pulse.React(ExamSeasons, (core) => {
  return {
    examSeasons: core.examSeasons.all,
  };
});
