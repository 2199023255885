import React, { useState } from "react";

import GeneralModal from "./GeneralModal";
import Core from "Core";

const UnAssignQualificationModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [forceClose, setForceClose] = useState(false);

  let { qualification, examBoard } = props;

  if (typeof qualification !== "object" || typeof examBoard !== "object") return <div></div>;

  let unAssign = (_) => {
    setIsLoading(true);
    Core.examBoards
      .unAssignQualification({ qualificationId: qualification.id, examBoardId: examBoard.id })
      .then((res) => {
        setIsLoading(false);
        if (res.status?.type === "success") {
          setForceClose(true);
        }
      });
  };

  return (
    <>
      <GeneralModal
        button={props.button || false}
        title={props.title || "Un-Assign Qualification"}
        primaryButtonVariant="danger"
        primaryButtonText="Un-Assign Qualification"
        primaryButtonOnClick={unAssign.bind(this)}
        primaryButtonIsLoading={isLoading}
        forceClose={forceClose}
      >
        {props.body}
      </GeneralModal>
    </>
  );
};

export default UnAssignQualificationModal;
